<template>
  <div class="kontaktAsta" id="kontaktAsta">
    <emailPopUp></emailPopUp>
    <div class="containerKontaktAsta row ">
      <div
        class="card col-xl-6"
        id="formCard"
        data-aos="flip-right"
        data-aos-duration="1500"
        data-aos-delay="700"
      >
        <h1>
          Kontakt
        </h1>
        <form onsubmit="return false">
          <div class="form-row">
            <div class="form-group col">
              <label for="fachContactSubject">Betreff</label>
              <input
                type="fachContactSubject"
                class="form-control"
                id="fachContactSubject"
                aria-describedby="subjectHelp"
                placeholder="Was ist dein Anliegen?"
              />
              <small id="emailHelp" class="form-text text-muted"></small>
            </div>
            <div class="form-group col">
              <label for="fachContactName">Name</label>
              <input
                type="fachContactName"
                class="form-control"
                id="fachContactName"
                aria-describedby="emailHelp"
                placeholder="Gib deinen Namen ein"
              />
              <small id="emailHelp" class="form-text text-muted"></small>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col">
              <label for="fachContactphone">Telefon</label>
              <input
                type="fachContactphone"
                class="form-control"
                id="fachContactphone"
                aria-describedby="subjectHelp"
                placeholder="Gib deine Telefonnummer ein"
              />
              <small id="emailHelp" class="form-text text-muted"></small>
            </div>
            <div class="form-group col">
              <label for="fachContactMail">E-Mail</label>
              <input
                type="fachContactMail"
                class="form-control"
                id="fachContactMail"
                aria-describedby="emailHelp"
                placeholder="Gib deine E-Mail ein"
              />
              <small id="emailHelp" class="form-text text-muted"></small>
            </div>
          </div>
          <div class="form-group">
            <label for="fachContactMessage">Deine Nachricht</label>
            <textarea
              type="text-area"
              class="form-control"
              id="fachContactMessage"
              aria-describedby="MessageHelp"
              placeholder="Gib deine Nachricht ein"
            ></textarea>
            <small id="MessageHelp" class="form-text text-muted"></small>
          </div>
          <button @click="sendMessage()" class="btn ">Absenden</button>
        </form>
        <div
          class="col text1"
          data-aos="fade-left"
          data-aos-duration="1500"
          data-aos-delay="700"
        >
          <h2>
            Du möchtest uns beitreten oder hast ein anderes Anliegen? Schreib
            uns eine Nachricht!
          </h2>
          <p>
            Wir antworten dir sobald wie möglich.
            <br /><br />
          </p>
          <p v-html="oeffnungszeiten"></p>
        </div>
      </div>

      <div
        class="containerKontaktMapsAsta col-xl-6 col-xl-offset-2"
        id="kontaktMapAsta1"
      >
        <div
          data-aos="fade-right"
          data-aos-duration="1500"
          data-aos-delay="700"
        >
          <h1>
            Hier findest du uns
          </h1>
        </div>

        <div data-aos="flip-left" data-aos-duration="1500" data-aos-delay="700">
          <iframe
            id="google_maps"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2623.9226344268645!2d8.713510015675126!3d48.878751379289554!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479771ba703b4489%3A0x2c9461d67ba175a1!2sTiefenbronner%20Str.%2065%2C%2075175%20Pforzheim!5e0!3m2!1sen!2sde!4v1621857730057!5m2!1sen!2sde"
            width="100%"
            height="100%"
            frameborder="0"
            style="border:0"
            data-aos="flip-left"
            data-aos-duration="1500"
            data-aos-delay="1000"
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import emailPopUp from "../../EmailPopUp.vue";
import axios from "axios";

export default {
  components: {
    emailPopUp,
  },
  data() {
    return {
      receiver: "",
      name: "",
      mail: "",
      message: "",
      oeffnungszeiten: "Ups, hier ist etwas schief gelaufen!",
    };
  },
  mounted() {
    var axios = require("axios");
    let ref = this;
    var data = JSON.stringify({
      username: "web163_2",
      pw: "Pf0rzelona",
      request: "get_data",
      Typ: "asta",
    });

    var config = {
      method: "post",
      url: "https://vs-pforzheim.de/uploadTime.php",
      headers: {
        "Content-Type": "this/json",
      },
      data: data,
    };

    axios(config)
      .then(function(response) {
        console.log(response.data[0].zeiten);
        ref.oeffnungszeiten = response.data[0].zeiten;
      })
      .catch(function(error) {
        alert("Hier ist etwas schiefgelaufen! 🫠");
      });
  },
  methods: {
    loadData() {
      // this.receiver = "julian.zoller@mail-zoller.de"
      this.receiver = "asta@hs-pforzheim.de";
      this.name = document.getElementById("fachContactName").value;
      this.mail = document.getElementById("fachContactMail").value;
      this.message = document.getElementById("fachContactMessage").value;
      this.subject = document.getElementById("fachContactSubject").value;
      this.phone = document.getElementById("fachContactphone").value;
    },
    async sendMessage() {
      await this.loadData();

      var axios = require("axios");

      var data = JSON.stringify({
        request: "kontaktformular",
        name: this.name,
        mail: this.mail,
        receiver: this.receiver,
        message: this.message,
        subject: this.subject,
        phone: this.phone,
      });

      var config = {
        method: "post",
        url: "https://vs-pforzheim.de/mailer.php",
        headers: {
          "Content-Type": "this/json",
        },
        data: data,
      };
      return axios(config)
        .then(function(response) {
          if (response.data == "Message has been sent successfully") {
            document.getElementById("emailPopUpTitle").innerText =
              "Nachricht versendet!";
            document.getElementById("emailPopUpBody").innerText =
              "Wir bearbeiten deine Anfrage und antworten dir so schnell wie möglich! Unter Umständen können E-Mails auch in deinem Spam landen!";
            document.getElementById("emailPopUpBtn").style.backgroundColor =
              "var(--asta-1)";
            document.getElementById("emailPopUpBtn").style.color = "white";
            $("#emailPopUp").modal("show");
          } else {
            document.getElementById("emailPopUpTitle").innerText =
              "Ups, hier ist etwas schief gelaufen!";
            document.getElementById("emailPopUpBody").innerText =
              "Auch unsere Technik hat den einen oder anderen Fehler! Bitte sende uns eine Mail an: test.XXX@xxx.de!";
            document.getElementById("emailPopUpBtn").style.backgroundColor =
              "var(--asta-1)";
            document.getElementById("emailPopUpBtn").style.color = "white";
            $("#emailPopUp").modal("show");
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
.kontaktAsta {
  text-align: center;
  background-image: url("../../assets/backgrounds/KontaktAstaBG.png");
  background-size: 2500px;
  background-position-x: -10px;
  background-position-y: 00px;
  background-repeat: no-repeat;
}

.card {
  padding: 20px;
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
}

.containerKontaktAsta {
  padding: 50px 100px 50px 100px;
}

.containerKontaktMapsAsta {
  /* padding-left: 50px; */
}

h2 {
  color: var(--asta-2);
}

h1 {
  color: var(--asta-2);
  text-align: center;
  padding-left: 20px;
}

p {
  font-size: 20px;

  text-align: center;
  padding-left: 20px;
}

#time {
  color: white;
}

.btn {
  background-color: var(--asta-2);
  color: white;
  transition: all 0.5s;
  border-radius: 40px;
}

iframe {
  border-radius: 10px;
  height: 500px;
  min-width: 300px;
  -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
}

.text2 {
  margin-top: 70px;
}

.text1 {
  margin-top: 40px;
}

#formCard {
  /* margin-right: 30vw; */
}

@media only screen and (max-width: 1300px) {
  #formCard {
    margin-right: -10px;
  }
}

@media only screen and (max-width: 1200px) {
  .kontaktAsta {
    background-size: 3000px;
  }
}

@media only screen and (min-width: 1101px) {
  #kontaktMapAsta2 {
    display: none;
  }
}

@media only screen and (max-width: 1100px) {
  .text2 {
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (max-width: 1000px) {
  .kontaktAsta {
    /* background-size: 2500px; */
  }
}

@media only screen and (max-width: 920px) {
  .kontaktAsta {
    /* background-position-y: 250px; */
  }
}

@media only screen and (max-width: 750px) {
  .col {
    min-width: 200px;
  }

  .containerKontaktAsta {
    padding: 150px 90px 50px 90px;
  }

  iframe {
    height: 300px;
    min-width: 200px;
  }

  /* .kontaktAsta {
			background-size: 300px;
			background-position-x: -300px;
			background-position-y: 500px;
			background-repeat: no-repeat; */
  /* } */
}

@media only screen and (max-width: 530px) {
  .kontaktAsta {
    background-size: 3200px;

    background-position-y: 50px;
  }
}

@media only screen and (max-width: 440px) {
  .kontaktAsta {
    background-size: 3200px;
    background-position-y: 250px;
  }
}
</style>
