<template>
  <div class="referate">
    <h1
      data-aos="fade-right"
      data-pause="false"
      data-aos-delay="2000"
      data-aos-duration="1000"
    >
      Referate
    </h1>
    <div class="flexbox1">
      <div
        id="carouselReferat3"
        class="carousel slide"
        data-ride="carousel"
        data-aos="flip-up"
        data-pause="false"
        data-aos-delay="2200"
        data-aos-duration="1000"
      >
        <div class="carousel-inner" id="referateCarousel3"></div>
        <a
          class="carousel-control-prev"
          href="#carouselReferat3"
          role="button"
          data-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a
          class="carousel-control-next"
          href="#carouselReferat3"
          role="button"
          data-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import PopUp from "../../PopUp.vue";
export default {
  name: "Referate",
  components: {
    PopUp,
  },
  data: {
    arraySize: 0,
    maxEntries: 0,
    referateArray: [],
    maxTextLength: 0,
  },
  mounted: async function() {
    let ref = this;
    ref.maxTextLength = 300;
    ref.maxEntries = 3;
    ref.referateArray = [];
    await this.getEntries();
    this.loadEntries();
  },
  methods: {
    getEntries() {
      var ref = this;
      ref.aushangArray = [];
      var axios = require("axios");
      var data = JSON.stringify({
        username: "web163_2",
        pw: "Pf0rzelona",
        request: "get_referate",
      });

      var config = {
        method: "post",
        url: "https://vs-pforzheim.de/getHandler.php",
        headers: {
          "Content-Type": "this/json",
        },
        data: data,
      };

      return axios(config)
        .then(function(response) {
          response.data.forEach((element, index) => {
            var tmpArray = new Map();
            tmpArray.set("id", element["id"]);
            tmpArray.set("title", element["title"]);
            tmpArray.set("name", element["name"]);
            tmpArray.set("shortText", element["shortText"]);
            tmpArray.set("longText", element["longText"]);
            tmpArray.set("email", element["email"]);
            tmpArray.set("image", element["image"]);
            ref.referateArray.push(tmpArray);
          });
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    loadEntries() {
      var entries = 0;
      var ref = this;
      var reversedArray = this.referateArray.reverse();
      reversedArray.forEach((element, index) => {
        var needDetails = false;
        var isFirstElement = false;
        if (index == 0) {
          isFirstElement = true;
        }
        ref.createEntry(element, needDetails, isFirstElement);
        entries += 1;
      });
    },
    createEntry(array, needDetails, isFirstElement) {
      let ref = this;
      var item = document.createElement("div");
      item.classList.add("carousel-item");
      if (isFirstElement) {
        item.classList.add("active");
      }

      item.id = "ReferateItem3" + array.get("id");
      document.getElementById("referateCarousel3").appendChild(item);

      var row = document.createElement("div");
      row.classList.add("row");
      row.id = "referateRow3" + array.get("id");
      document
        .getElementById("ReferateItem3" + array.get("id"))
        .appendChild(row);

      var col4 = document.createElement("div");
      col4.classList.add("col-12");
      col4.id = "referateCol43" + array.get("id");
      document
        .getElementById("referateRow3" + array.get("id"))
        .appendChild(col4);

      var col8 = document.createElement("div");
      col8.classList.add("col-12");
      col8.style.textAlign = "center";
      col8.id = "referateCol83" + array.get("id");
      document
        .getElementById("referateRow3" + array.get("id"))
        .appendChild(col8);

      var img = document.createElement("img");
      img.src = "https://vs-pforzheim.de/" + array.get("image");
      img.style.cssText = "border-radius: 20px; padding: 10px; height: 200px;";
      document
        .getElementById("referateCol43" + array.get("id"))
        .appendChild(img);

      var carouselText3 = document.createElement("div");
      carouselText3.classList.add("carouselText3");
      carouselText3.style.cssText =
        "padding: 10px; text-align: center; color: white; ";
      carouselText3.id = "carouselText3" + array.get("id");
      document
        .getElementById("referateCol83" + array.get("id"))
        .appendChild(carouselText3);

      var h1 = document.createElement("h2");
      var h1Text = document.createTextNode(array.get("title"));
      h1.appendChild(h1Text);
      document
        .getElementById("carouselText3" + array.get("id"))
        .appendChild(h1);

      var h2 = document.createElement("h3");
      h2.style.fontSize = "20px";
      var h2Text = document.createTextNode(array.get("name"));
      h2.appendChild(h2Text);
      document
        .getElementById("carouselText3" + array.get("id"))
        .appendChild(h2);

      var carouselTextSec3 = document.createElement("div");
      carouselTextSec3.classList.add("carouselText3");

      carouselTextSec3.style.cssText =
        "padding: 0px 50px 25px 50px; text-align: center; color: white; width: 100%";

      carouselTextSec3.id = "carouselTextSec3" + array.get("id");
      document
        .getElementById("referateRow3" + array.get("id"))
        .appendChild(carouselTextSec3);

      var p = document.createElement("p");
      var pText = document.createTextNode(array.get("shortText"));
      p.appendChild(pText);
      p.style.cssText = "padding-top: 10px; font-size: 20px; text-align: left";
      document
        .getElementById("carouselTextSec3" + array.get("id"))
        .appendChild(p);

      var btn = document.createElement("div");
      btn.classList.add("btn");

      btn.style.cssText =
        "background-color: var(--asta-2); color: white; transition: all 0.5s; border-radius: 40px; width: 150px;";
      btn.id = "referateMehrBtn3" + array.get("id");
      document
        .getElementById("carouselTextSec3" + array.get("id"))
        .appendChild(btn);
      document
        .getElementById("referateMehrBtn3" + array.get("id"))
        .addEventListener("click", function() {
          ref.showDetails(array.get("id"));
        });

      var span = document.createElement("span");
      var spanText = document.createTextNode("Mehr darüber!");
      span.appendChild(spanText);
      document
        .getElementById("referateMehrBtn3" + array.get("id"))
        .appendChild(span);

      var hr = document.createElement("hr");
      hr.style.cssText = "border-color: white!important";
      document
        .getElementById("carouselTextSec3" + array.get("id"))
        .appendChild(hr);

      var pmail = document.createElement("p");
      var pmailText = document.createTextNode(
        "Schreibe eine Mail an " + array.get("name")
      );
      pmail.appendChild(pmailText);
      pmail.style.cssText = "font-size: 16px";
      document
        .getElementById("carouselTextSec3" + array.get("id"))
        .appendChild(pmail);

      var mailbtn = document.createElement("a");
      mailbtn.classList.add("btn");
      mailbtn.id = "referateMailBtn3" + array.get("id");
      mailbtn.innerHTML =
        '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-envelope" viewBox="0 0 16 16"><path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2zm13 2.383-4.758 2.855L15 11.114v-5.73zm-.034 6.878L9.271 8.82 8 9.583 6.728 8.82l-5.694 3.44A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.739zM1 11.114l4.758-2.876L1 5.383v5.73z"/></svg>';
      mailbtn.style.cssText =
        "background-color: var(--asta-2); color: white; transition: all 0.5s; border-radius: 40px; width: 150px;";
      document
        .getElementById("carouselTextSec3" + array.get("id"))
        .appendChild(mailbtn);
      document
        .getElementById("referateMailBtn3" + array.get("id"))
        .addEventListener("click", function() {
          ref.startContact(array.get("id"));
        });
    },
    showDetails(id) {
      var array;
      this.referateArray.forEach((element) => {
        if (element.get("id") == id) {
          array = element;
        }
      });

      let popupTitle = array.get("title");
      let popupDate = array.get("name");
      let popupText = array.get("longText");
      let popupEmail = array.get("email");
      let popupImg = array.get("image");
      document.getElementById("popupTitle").innerHTML = "";
      document.getElementById("popupDate").innerHTML = "";
      document.getElementById("popupText").innerHTML = "";
      document.getElementById("popupImg").src = "";
      document.getElementById("popupEmail").innerHTML = "";
      document.getElementById("contactFieldAushangHome").style.display = "none";
      document.getElementById("popupFooter").style.display = "none";
      document.getElementById("popupBtn").style.display = "block";
      document.getElementById("popupButton").style.display = "var(--asta-1)";

      document.getElementById("popupTitle").style.color = "var(--asta-1)";
      document.getElementById("popupBtn").style.backgroundColor =
        "var(--asta-1)";

      document.getElementById("popupTitle").style.color = "var(--asta-1)";
      document.getElementById("submitBtnPopup").style.backgroundColor =
        "var(--asta-1)";

      document.getElementById("popupTitle").innerText = popupTitle;
      document.getElementById("popupDate").innerText = popupDate;
      document.getElementById("imageCol").style.display = "none";
      document.getElementById("popupText").innerText = popupText;
      document.getElementById("popupType").innerText = "contact";

      document.getElementById("popupImg").src =
        "https://vs-pforzheim.de/" + popupImg;
      document.getElementById("popupEmail").innerText = popupEmail;

      if (popupImg == "") {
        document.getElementById("popupImg").style.display = "none";
      }

      $("#popup").modal("show");
    },
    startContact(id) {
      var array;
      this.referateArray.forEach((element) => {
        if (element.get("id") == id) {
          array = element;
        }
      });

      let popupTitle = array.get("title");
      let popupDate = array.get("name");
      let popupText = array.get("longText");
      let popupEmail = array.get("email");
      let popupImg = array.get("image");

      document.getElementById("popupTitle").innerHTML = "";
      document.getElementById("popupDate").innerHTML = "";
      document.getElementById("popupText").innerHTML = "";
      document.getElementById("popupImg").src = "";
      document.getElementById("popupFooter").style.display = "none";
      document.getElementById("popupEmail").innerHTML = "";
      document.getElementById("popupTitel").style.display = "none";
      document.getElementById("popupName").style.display = "block";
      document.getElementById("imageCol").style.display = "none";

      document.getElementById("popupTitle").style.color = "var(--asta-1)";
      document.getElementById("submitBtnPopup").style.backgroundColor =
        "var(--asta-1)";

      document.getElementById("popupTitle").innerText = popupTitle;
      document.getElementById("popupDate").innerText = popupDate;
      document.getElementById("popupEmail").innerText = popupEmail;
      document.getElementById("popupType").innerText = "contact";

      document.getElementById("popupBtn").style.display = "none";
      document.getElementById("contactFieldAushangHome").style.display =
        "block";

      $("#popup").modal("show");
    },
  },
};
</script>

<style scoped>
.flexbox1 {
  margin: 0px 75px 0px 75px;
}

#carouselReferat3 .carousel-inner {
  border-radius: 20px;
  background-color: #333333;
  -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
}

#carouselReferatRight {
  max-width: 900px;
}

#carouselReferatRight .carousel-inner {
  overflow: hidden;
  border-radius: 20px;
}

.carousel-control-next {
  margin-right: -70px;
}

.carousel-control-prev {
  margin-left: -70px;
}

#carouselReferatRight .carousel-item {
  height: 550px;

  color: white;
  text-align: center;
}

h1 {
  color: var(--asta-1);
  font-size: 50px;
  padding-bottom: 20px;
}

.btn-mail svg {
  text-align: left;
  margin-top: -4px;
}

@media only screen and (max-width: 1300px) {
  .carousel-control-next {
    margin-right: -90px;
  }

  .carousel-control-next-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='black' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
  }

  .carousel-control-prev {
    margin-left: -90px;
  }

  .carousel-control-prev-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='black' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
  }
}

@media only screen and (max-width: 800px) {
  .carousel-control-prev {
    margin-left: -60px;
  }

  .carousel-control-next {
    margin-right: -60px;
  }
}

@media only screen and (max-width: 600px) {
  .carousel-control-prev {
    margin-left: -40px;
  }

  .carousel-control-next {
    margin-right: -40px;
  }
}
</style>
