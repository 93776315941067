<template>
	<div class="veranstaltungen">
		<Veranstaltungen1 class="Veranstaltungen1"></Veranstaltungen1>
		<Veranstaltungen2 class="Veranstaltungen2"></Veranstaltungen2>
	</div>
</template>

<script>
	import Veranstaltungen1 from '@/views/asta/Veranstaltungen1.vue'
	import Veranstaltungen2 from '@/views/asta/Veranstaltungen2.vue'
	import $ from "jquery";
	export default {
		name: 'Veranstaltungen',
		data: {

		},
		components: {
			Veranstaltungen1,
			Veranstaltungen2
		}

	}
</script>

<style scoped>
	.veranstaltungen {
		margin-top: 00px;
		text-align: center;
		background-image: url('../../assets/backgrounds/VeranstaltungenBG.png');
		min-height: 800px;
	}

	

	@media only screen and (max-width: 1200px) {
		.Veranstaltungen1 {
			display: none;
		}
	}

	@media only screen and (min-width: 1201px) {
		.Veranstaltungen2 {
			display: none;
		}
	}
</style>