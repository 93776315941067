import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vuex from 'vuex';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js'
import 'aos/dist/aos.css'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import Tabs from "vue-material-tabs";
import axios from 'axios';

import vueHeadful from 'vue-headful';

Vue.component('vue-headful', vueHeadful);

// Import the Auth0 configuration
import { domain, clientId } from "../auth_config.json";

// Import the plugin here
import { Auth0Plugin } from "./auth";

// Install the authentication plugin here
Vue.use(Auth0Plugin, {
  domain,
  clientId,
  onRedirectCallback: appState => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  }
});

Vue.use(Tabs);

// import style (>= Swiper 6.x)
import 'swiper/swiper-bundle.css'

import vuetify from './plugins/vuetify'


Vue.use(VueAwesomeSwiper, /* { default options with global component } */)

const cache = new Vuex.Store({
  state: {
    homeAushangClickedId: ''
  },
  mutations: {
    increment (state) {
      state.count++
    }
  }
})

new Vue({
  router,
  store: store,

  mounted () {
    import('aos').then(AOS => AOS.init())
  },

  vuetify,
  render: function (h) { return h(App) }
}).$mount('#app')
