<template>
	<div class="Gestaltung">
		<h2 data-aos="flip-left" data-aos-duration="1500" data-aos-delay="1000">Fachschaften</h2>
		<h1 data-aos="flip-right" data-aos-duration="1500" data-aos-delay="700">
			Gestaltung
		</h1>
		<p data-aos="flip-up" data-aos-duration="1500" data-aos-delay="1500">
			Die Fachschaften der Fakultät für Gestaltung sind eine großartige Mischung aus allen möglichen
			Designbereichen wie Industrie, Automobil oder Mode. In der Holzgartenstraße kommen alle, die Talent und
			Kreativität zu bieten haben, zusammen um zu lernen. Alle Studierenden gemeinsam bilden die Fachschaft und
			der Fachschaftsrat, als gewähltes Gremium, vertritt die Studierenden und ihre Interessen.
		</p>
	</div>
</template>

<script>
	export default {
		name: 'wirtschaft',
		mounted() {},
		data() {
			return {}
		}
	}
</script>

<style scoped>
	.Gestaltung {
		background-image: url('../../assets/backgrounds/GestaltungBG.png');
		background-repeat: no-repeat;
		height: 1000px;
		background-position-x: -2px;
		padding: 50px 170px 0 57.5vw;
		text-align: right;
	}

	h1 {
		font-family: var(--font-georgia);
		font-size: 60px;
	}

	h2 {
		font-family: var(--font4);
		font-size: 25px;
		color: var(--primary-orange);
	}

	p {
		font-size: 20px;
	}
</style>