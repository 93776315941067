<template>
  <div class="modal fade" id="emailPopUp" tabindex="1" role="dialog" aria-labelledby="exampleModalCenterTitle"
			aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" id="emailPopUpTitle"></h5>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body" id="emailPopUpBody">
						
					</div>
					<div class="modal-footer">
						<button type="button" id="emailPopUpBtn" class="btn" data-dismiss="modal">Okay</button>
					</div>
				</div>
			</div>
		</div>
</template>

<script>
export default {

}
</script>

<style scoped>

</style>