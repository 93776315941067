<template>
	<div class="kontaktFach">
		<emailPopUp></emailPopUp>
		<div id="kontakt-fach"></div>
		<div class="row containerKontaktFach">
			<div class="col card" data-aos="flip-right" data-aos-duration="1500" data-aos-delay="700">
				<form>
					<div class="form-row">
						<div class="form-group col-12">
							<label for="fachContactSubject">Betreff</label>
							<input type="fachContactSubject" class="form-control" id="fachContactSubject"
								aria-describedby="subjectHelp" placeholder="Was ist dein Anliegen?">
							<small id="emailHelp" class="form-text text-muted"></small>
						</div>
						<div class="form-group col-12">
							<label for="fachContactName">Name</label>
							<input type="fachContactName" class="form-control" id="fachContactName"
								aria-describedby="emailHelp" placeholder="Gib deinen Namen ein">
							<small id="emailHelp" class="form-text text-muted"></small>
						</div>
					</div>
					<div class="form-row">
						<div class="form-group col-12">
							<label for="fachContactphone">Telefon</label>
							<input type="fachContactphone" class="form-control" id="fachContactphone"
								aria-describedby="subjectHelp" placeholder="Gib deine Telefonnummer ein">
							<small id="emailHelp" class="form-text text-muted"></small>
						</div>
						<div class="form-group col-12">
							<label for="fachContactMail">E-Mail</label>
							<input type="fachContactMail" class="form-control" id="fachContactMail"
								aria-describedby="emailHelp" placeholder="Gib deine E-Mail ein">
							<small id="emailHelp" class="form-text text-muted"></small>
						</div>
					</div>
					<div class="form-group">
						<label for="fachContactMessage">Deine Nachricht</label>
						<textarea type="text-area" class="form-control" id="fachContactMessage"
							aria-describedby="MessageHelp" placeholder="Gib deine Nachricht ein"></textarea>
						<small id="MessageHelp" class="form-text text-muted"></small>
					</div>
					<button @click="sendMessage()" class="btn ">Absenden</button>
				</form>
			</div>
			<div class="col text1" id="KontaktTextFach1" data-aos="fade-left" data-aos-duration="1500"
				data-aos-delay="700">
				<h1>
					<strong>Du hast Fragen?</strong><br>
				</h1>
				<p id="time">
					Dann schreibe jetzt eine Nachricht an deine Fachschaft:<br>
					<a style ="font-size: 17px">Wir antworten dir sobald wie möglich.</a><br><br>
					<a
						href="mailto:fachschaftsrat-g@hs-pforzheim.de"><strong>fachschaftsrat-g@hs-pforzheim.de</strong></a><br>
					<a
						href="mailto:fachschaftsrat-t@hs-pforzheim.de"><strong>fachschaftsrat-t@hs-pforzheim.de</strong></a><br>
					<a
						href="mailto:fachschaftsrat-wur@hs-pforzheim.de"><strong>fachschaftsrat-wur@hs-pforzheim.de</strong></a>
				</p>
				<p id="time">
					<strong>Du willst auch aktiv werden und das<br>Hochschulleben mitgestalten?</strong><br><br>
					Dann nehme an den nächsten Gremienwahlen teil oder lass Dich aufstellen und helfe auch Du mit, den
					Alltag und die Bildung an der Hochschule zu verbessern. <strong>Die Wahlen finden immer im Juni
						statt.</strong>
					Spannende Aufgaben, Kontakt zu ProfessorInnen und einen Einblick hinter die Kulissen der Hochschule
					warten auf Dich!
				</p>
			</div>
		</div>
		<div class="col text1" id="KontaktTextFach2" data-aos="fade-left" data-aos-duration="1500" data-aos-delay="700">
			<h1>
					<strong>Du hast Fragen?</strong><br>
				</h1>
				<p id="time">
					Dann schreibe jetzt eine Nachricht an deine Fachschaft:<br>
					<a style ="font-size: 17px">Wir antworten dir sobald wie möglich.</a><br><br>
					<a
						href="mailto:fachschaftsrat-g@hs-pforzheim.de"><strong>fachschaftsrat-g@hs-pforzheim.de</strong></a><br>
					<a
						href="mailto:fachschaftsrat-t@hs-pforzheim.de"><strong>fachschaftsrat-t@hs-pforzheim.de</strong></a><br>
					<a
						href="mailto:fachschaftsrat-wur@hs-pforzheim.de"><strong>fachschaftsrat-wur@hs-pforzheim.de</strong></a>
				</p>
				<p id="time">
					<strong>Du willst auch aktiv werden und das<br>Hochschulleben mitgestalten?</strong><br><br>	
					Dann nehme an den nächsten Gremienwahlen teil oder lass Dich aufstellen und helfe auch Du mit, den
					Alltag und die Bildung an der Hochschule zu verbessern. <strong>Die Wahlen finden immer im Juni
						statt.</strong>
					Spannende Aufgaben, Kontakt zu ProfessorInnen und einen Einblick hinter die Kulissen der Hochschule
					warten auf Dich!
				</p>
		</div>
	</div>
</template>

<script>
	import $ from "jquery";
	import emailPopUp from '../../EmailPopUp.vue'
	export default {
		components: {
			emailPopUp
		},
		data: {
			receiver: '',
			name: '',
			mail: '',
			subject: '',
			phone: '',
			message: ''
		},
		methods: {
			loadData() {
				// this.receiver = "julian.zoller@mail-zoller.de"
				this.receiver = "Studierendenrat@hs-pforzheim.de"
				this.name = document.getElementById("fachContactName").value
				this.mail = document.getElementById("fachContactMail").value
				this.message = document.getElementById("fachContactMessage").value
				this.subject = "Kontakt an Fachschaften: " + document.getElementById("fachContactSubject").value
				this.phone = document.getElementById("fachContactphone").value
			},
			async sendMessage() {
				await this.loadData()

				var axios = require('axios');

				var data = JSON.stringify({
					"request": "kontaktformular",
					"name": this.name,
					"mail": this.mail,
					"receiver": this.receiver,
					"message": this.message,
					"subject": this.subject,
					"phone": this.phone

				});

				var config = {
					method: 'post',
					url: 'https://vs-pforzheim.de/mailer.php',
					headers: {
						'Content-Type': 'this/json'
					},
					data: data
				};

				return axios(config)
					.then(function (response) {
						if (response.data == "Message has been sent successfully") {
							document.getElementById("emailPopUpTitle").innerText = "Nachricht versendet!"
							document.getElementById("emailPopUpBody").innerText =
								"Wir bearbeiten deine Anfrage und antworten dir so schnell wie möglich! Unter Umständen können E-Mails auch in deinem Spam landen!"
							document.getElementById("emailPopUpBtn").style.backgroundColor =
								"var(--primary-orange)"
							document.getElementById("emailPopUpBtn").style.color = "white"
							$('#emailPopUp').modal('show');
						} else {
							document.getElementById("emailPopUpTitle").innerText =
								"Ups, hier ist etwas schief gelaufen!"
							document.getElementById("emailPopUpBody").innerText =
								"Auch unsere Technik hat den einen oder anderen Fehler! Bitte sende uns eine Mail an: test.XXX@xxx.de!"
							document.getElementById("emailPopUpBtn").style.backgroundColor =
								"var(--primary-orange)"
							document.getElementById("emailPopUpBtn").style.color = "white"
							$('#emailPopUp').modal('show');
						}
					})
					.catch(function (error) {
						console.log(error);
					});
			},
		}
	}
</script>

<style scoped>
	.kontaktFach {
		text-align: center;
		background-repeat: no-repeat;
		background-size: 106.1%;
		background-color: var(--secondary-pastelle);
	}

	.card {
		padding: 20px;
		border-radius: 10px;
		-webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.75);
		-moz-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.75);
		box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
	}

	.containerKontaktFach {
		padding: 150px 170px 50px 170px;
	}

	a {
		color: var(--primary-orange);
	}

	h1 {
		color: var(--primary-orange);
		text-align: center;
		padding-left: 20px;
	}

	p {
		font-size: 20px;
		color: var(--primary-orange);
		text-align: center;
		padding-left: 20px;
	}

	#time {
		font-size: 25px;
		color: var(--primary-orange);
		text-align: center;
		padding-left: 20px;
	}

	.btn {
		background-color: var(--primary-orange);
		color: white;
		transition: all 0.5s;
		border-radius: 40px;
	}


	.containerKontaktMapsFach {
		padding: 100px 140px 100px 160px;

	}

	iframe {
		border-radius: 10px;
		height: 300px;
		-webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.75);
		-moz-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.75);
		box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
	}

	.text2 {
		margin-top: 70px;
	}

	.text1 p,
	.text1 h1,
	.text1 #time {
		margin-top: 40px;
		color: var(--primary-orange)
	}



	@media only screen and (max-width: 1280px) {
		#KontaktTextFach1 {
			display: none;
		}

		.col {
			min-width: 300px;
		}
	}

	#KontaktTextFach2 {
		padding-bottom: 150px;
	}

	@media only screen and (min-width: 1281px) {
		#KontaktTextFach2 {
			display: none;
		}
	}

	@media only screen and (max-width: 600px) {
		.containerKontaktFach {
		padding: 150px 100px 50px 100px;
	}
	}

	@media only screen and (max-width: 420px) {
		.containerKontaktFach {
		padding: 150px 100px 50px 70px;
	}

	.col {
			min-width: 200px;
		}
	}

	
</style>