<template>
  <div class="kulturflat" data-aos="fade-right" data-aos-duration="300">
	<h1>Verfasste Studierendenschaft Pforzheim</h1>
    <div id="kulturflat1"></div>
    <kulturflat1 class="kulturflat1">
    </kulturflat1>
    <kulturflat2 class="kulturflat2">
    </kulturflat2>
  </div>
</template>

<script>
  import kulturflat1 from '@/views/home/Kulturflat1.vue'
  import kulturflat2 from '@/views/home/Kulturflat2.vue'
  import $ from "jquery";
  import axios from "axios";
  export default {
    name: 'Kulturflat',
    mounted() {},
    components: {
      kulturflat1,
      kulturflat2
    },
    methods: {

    }
  }
</script>

<style scoped>
  .kulturflat {
    margin-top: 100px;
    background-image: url('../../assets/backgrounds/KulturflatBG.png');
    background-repeat: no-repeat;
    background-size:70% 100%;
  }


  h1{
    font-family: var(--font-playfair);
    color: black;
  }

  

  @media only screen and (max-width: 1684px) {
    .kulturflat1 {
      display: none;
    }
    .kulturflat {
    background-size:cover;
  }
  }

  @media only screen and (min-width: 1685px) {
    .kulturflat2 {
      display: none;
    }
    
  }
</style>