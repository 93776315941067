<template>
  <div id="app">
    <vue-headful title="AStA | StuRa | Fachschaften der Hochschule Pforzheim" description="StuRa: Der Studierendenrat ist das Legislativorgan der verfassten Studierendenschaft.
AStA: Von Studenten für Studierende. Wir kümmern uns um alles was das Studium sonst noch neben den Vorlesungen bietet und sind das ausführende Organ des StuRa 😊" />
    <router-view />
    <Footer></Footer>
  </div>
</template>

<script>
  import Footer from '@/components/Footer'

  export default {
    setup() {

    },
    components: {
      Footer
    }
  }
</script>

<style>
  html,
  body {
    max-width: 100%;
    overflow-x: hidden;
  }

  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
  }

  :root {
    --primary-green: #01422C;
    --primary-orange: #FC6F32;
    --secondary-green: #E4F1E0;
    --secondary-pastelle: #F9E5BD;
    --asta: #DE0102;
    --asta-1: #238155;
    --asta-2: #EC7E81;
    --asta-3: #F3C9CB;
    --asta-4: #C61519;
    --asta-5: #CCAB23;
    --font-azo: azo-sans-web, sans-serif;
    --font-georgia: Georgia, Times, "Times New Roman", serif;
    --font-playfair: 'Playfair Display';
    --yellow: #FFC000;
    --yellow-light: #FFF2CC;
  }

  Footer {
    margin: 0;
  }

  #nav {
    padding: 30px;
  }

  @media only screen and (max-width: 580px) {
    h1 {
      font-size: 40px !important;
    }

    h2 {
      font-size: 20px !important;

    }

    p {
      font-size: 15px !important;
    }

    a {
      font-size: 15px !important;
    }
    li {
      font-size: 15px !important;
    }
  }

  #nav a.router-link-exact-active {
    color: #42b983;
  }
</style>