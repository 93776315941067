<template>
  <div class="start">
    <vue-headful
      title="AStA | StuRa | Fachschaften der Hochschule Pforzheim"
      description="StuRa: Der Studierendenrat ist das Legislativorgan der verfassten Studierendenschaft.
    AStA: Von Studenten für Studierende. Wir kümmern uns um alles was das Studium sonst noch neben den Vorlesungen bietet und sind das ausführende Organ des StuRa 😊"
    />
    <Trinitaet id="trinitaet"></Trinitaet>
    <!-- <News id="newsNav"></News> -->
    <Kulturflat id="kulturNav"></Kulturflat>
    <!-- <Aushang id="aushangNav"></Aushang> -->
    <!-- <Gallerie></Gallerie> -->
    <Organisation id="organisationNav"></Organisation>
    <AufgabenVS></AufgabenVS>
    <div
      class="sidenav"
      data-aos="fade-right"
      data-aos-duration="1500"
      data-aos-delay="700"
      id="navStart"
    >
      <a id="nav-home" v-on:click="scrollMeTo('trinitaet')">Home</a>
      <router-link class="router-link" :to="{ name: 'Stura' }"
        >StuRa</router-link
      >
      <router-link class="router-link" href="https://asta-pforzheim.de"
        >AStA</router-link
      >
      <router-link class="router-link" :to="{ name: 'Fachschaften' }"
        >Fachschaften</router-link
      >
    </div>
    <div
      class="modal fade"
      id="flatMailError"
      tabindex="1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">
              Zurzeit nicht verfügbar
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            Zurzeit sind keine Codes mehr vorhanden. Wir uns freuen sie im
            nächsten Semester wieder anbieten zu können.
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Okay
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="flatMailProfError"
      tabindex="1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">
              keine Studenten-Hochschulmail vorhanden
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            Die Kulturflat ist nur für Studenten!
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Okay
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="flatMailSubmit"
      tabindex="1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">
              Code erfolgreich generiert!
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            Der Code wurde an deine Hochschulmail versendet!
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Okay
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Trinitaet from "@/views/home/Trinitaet";
import News from "@/views/home/News";
import Gallerie from "@/views/home/Gallerie";
import Aushang from "@/views/home/Aushang";
import Kulturflat from "@/views/home/Kulturflat";
import Organisation from "@/views/home/Organisation";
import AufgabenVS from "@/views/home/AufgabenVS";
import Test from "@/views/home/test";

export default {
  name: "Startseite",
  components: {
    Trinitaet,
    News,
    Gallerie,
    Aushang,
    Kulturflat,
    Test,
    Organisation,
    AufgabenVS,
  },
  mounted() {
    const mount = document.querySelector("#menuStura");
    const trinitaet = document.querySelector("#menuStura");
    const news = document.querySelector("#news1");
    const gallerie = document.querySelector("#gallerie1Home");
    const organisation = document.querySelector("#organisation1");
    const kultur = document.querySelector("#kulturflat1");
    const aushang = document.querySelector("#aushangMark");
    const navHome = document.getElementById("nav-home");
    const navNews = document.getElementById("nav-news");
    const navGallerie = document.getElementById("nav-gallerie");
    const navOrganisation = document.getElementById("nav-organisation");
    const navAushang = document.getElementById("nav-aushang");
    const navKulturflat = document.getElementById("nav-kultur");
    const ref = this;

    const views = [trinitaet, news, kultur, aushang, gallerie, organisation];

    const navs = [
      navHome,
      navNews,
      navKulturflat,
      navAushang,
      navGallerie,
      navOrganisation,
    ];

    if (ref.isInViewport(mount)) {
      navHome.style.color = "var(--primary-orange)";
      navHome.style.textDecoration = "underline";
    }

    // document.addEventListener('scroll', function () {
    //   for (var i = 0; i < views.length; i++) {
    //     if (ref.isInViewport(views[i])) {
    //       navs[i].style.color = "var(--primary-orange)"
    //       navs[i].style.textDecoration = "underline"
    //       let cloneArray = navs.except(navs[i])
    //       for (var j = 0; j < cloneArray.length; j++) {
    //         cloneArray[j].style.color = "gray"
    //         cloneArray[j].style.textDecoration = "none"
    //         cloneArray[j].style
    //       }
    //     }
    //   }
    // });

    // Array.prototype.except = function (val) {
    //   return this.filter(function (x) {
    //     return x !== val
    //   })
    // }
  },
  methods: {
    isInViewport(el) {
      const rect = el.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
          (window.innerWidth || document.documentElement.clientWidth)
      );
    },
    scrollMeTo(refName) {
      const el = document.getElementById(refName);
      el.scrollIntoView({
        behavior: "smooth",
      });
    },
  },

  data() {
    return {};
  },
};
</script>

<style scoped>
/* The sidebar menu */
.sidenav {
  height: 100%;
  /* Full-height: remove this if you want "auto" height */
  position: fixed;
  /* Fixed Sidebar (stay in place on scroll) */
  z-index: 1;
  /* Stay on top */
  top: 20px;

  /* Stay at the top */
  left: 0;
  /* Disable horizontal scroll */
  cursor: pointer;
}

#navStart {
  color: grey;
}

/* The navigation menu links */
.sidenav a {
  padding: 26px 8px 6px 16px;
  text-decoration: none;
  font-size: 20px;
  color: gray;
  display: block;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  font-family: montserrat, sans-serif;
  font-weight: 200;
  font-style: normal;
}

/* When you mouse over the navigation links, change their color */
.sidenav a:hover {
  color: var(--primary-orange);
}

/* Style page content */
.main {
  margin-left: 160px;
  /* Same as the width of the sidebar */
  padding: 0px 10px;
}

/* On smaller screens, where height is less than 450px, change the style of the sidebar (less padding and a smaller font size) */
@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }

  .sidenav a {
    font-size: 18px;
  }
}

.btn {
  background-color: var(--primary-orange);
  color: white;
  transition: all 0.5s;
  width: 100px;
  border-radius: 40px;
  border: none;
}
</style>
