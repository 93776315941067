<template>
  <div class="trinitaet" id="trinitaet">
			
    <trin1 class="trin1"></trin1>
    <trin2 class="trin2"></trin2>
    <trin3 class="trin3"></trin3>
    <div id="menuStura"></div>

  </div>
</template>

<script>
  import $ from "jquery";
  import trin1 from '@/views/home/Trinitaet1.vue'
  import trin2 from '@/views/home/Trinitaet2.vue'
  import trin3 from '@/views/home/Trinitaet3.vue'

  export default {
    name: 'Trinitaet',
    components: {
      trin1,
      trin2,
      trin3
    },
    mounted() {},
    methods: {
      goTo(string) {}
    },
    data() {
      return {}
    }
  }
</script>

<style scoped>
  @media only screen and (max-width: 1200px) {
    .trin1 {
      display: none;
    }
  }

  @media only screen and (min-width: 1201px) {
    .trin2 {
      display: none;
    }
  }

  @media only screen and (max-width: 980px) {
    .trin2 {
      display: none;
    }
  }

  @media only screen and (min-width: 981px) {
    .trin3 {
      display: none;
    }
  }
</style>