<template>
  <div class="news">
    <div class="newsContainer">
      <br />
      <br />
      <h1>News</h1>
      <br />
      <div
        class="tabs"
        data-aos="zoom-in-down"
        data-aos-duration="1000"
        data-aos-delay="800"
      >
        <div>
          <div class="nav nav-tabs" id="nav-tab" role="tablist">
            <a
              class="nav-item nav-link active"
              id="nav-news2-tab2"
              data-toggle="tab"
              href="#nav-News2"
              role="tab"
              aria-controls="nav-News2"
              aria-selected="true"
              >News</a
            >
            <a
              class="nav-item nav-link"
              id="nav-veranstaltungen2-tab2"
              data-toggle="tab"
              href="#nav-Veranstaltungen2"
              role="tab"
              aria-controls="nav-Veranstaltungen2"
              aria-selected="false"
              >Alle Veranstaltungen</a
            >
            <a
              class="nav-item nav-link"
              id="nav-asta2-tab2"
              data-toggle="tab"
              href="#nav-AStA2"
              role="tab"
              aria-controls="nav-AStA2"
              aria-selected="false"
              >AStA</a
            >
            <a
              class="nav-item nav-link"
              id="nav-init-tab2"
              data-toggle="tab"
              href="#nav-Initiativen2"
              role="tab"
              aria-controls="nav-Initiativen2"
              aria-selected="false"
              >Initiativen</a
            >
          </div>
        </div>
        <div class="tab-content" id="nav-tabContent">
          <div
            class="tab-pane fade show active"
            id="nav-News2"
            role="tabpanel"
            aria-labelledby="nav-home-tab"
          >
            <div class="first-tab tab" id="newsHome2"></div>
          </div>
          <div
            class="tab-pane fade"
            id="nav-Veranstaltungen2"
            role="tabpanel"
            aria-labelledby="nav-Veranstaltungen2-tab2"
          >
            <div class="second-tab tab" id="veranstaltungenHome2"></div>
          </div>
          <div
            class="tab-pane fade"
            id="nav-AStA2"
            role="tabpanel"
            aria-labelledby="nav-AStA2-tab2"
          >
            <div class="third-tab tab" id="astaHome2"></div>
          </div>
          <div
            class="tab-pane fade"
            id="nav-Initiativen2"
            role="tabpanel"
            aria-labelledby="nav-Initiativen2-tab"
          >
            <div class="fourth-tab tab" id="initHome2"></div>
          </div>
          <div class="row">
            <div class="col">
              <div class="btn " @click="lastEntries()">Zurück</div>
            </div>
            <div class="col">
              <div class="btn " @click="nextEntries()">Nächste</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";

export default {
  name: "News",
  components: {},
  data: {
    index: 0,
    arraySize: 0,
    maxEntries: 0,
    newsArray: [],
    veranstaltungenArray: [],
    astaArray: [],
    initArray: [],
    activeTab: "",
  },
  mounted: async function() {
    let ref = this;
    ref.activeTab = "news";
    ref.index = 0;
    ref.maxEntries = 1;
    await this.getEntries();
    this.loadEntries();
    $("#nav-news2-tab2").click(function(e) {
      document.getElementById(ref.activeTab + "Home2").innerHTML = "";
      ref.activeTab = "news";
      document.getElementById(ref.activeTab + "Home2").innerHTML = "";
      ref.index = 0;
      ref.loadEntries();
    });
    $("#nav-veranstaltungen2-tab2").click(function(e) {
      document.getElementById(ref.activeTab + "Home2").innerHTML = "";
      ref.index = 0;
      ref.activeTab = "veranstaltungen";
      document.getElementById(ref.activeTab + "Home2").innerHTML = "";
      ref.loadEntries();
    });
    $("#nav-asta2-tab2").click(function(e) {
      document.getElementById(ref.activeTab + "Home2").innerHTML = "";
      ref.index = 0;
      ref.activeTab = "asta";
      document.getElementById(ref.activeTab + "Home2").innerHTML = "";
      ref.loadEntries();
    });
    $("#nav-init-tab2").click(function(e) {
      document.getElementById(ref.activeTab + "Home2").innerHTML = "";
      ref.index = 0;
      ref.activeTab = "init";
      document.getElementById(ref.activeTab + "Home2").innerHTML = "";
      ref.loadEntries();
    });
  },
  methods: {
    nextEntries() {
      let ref = this;
      document.getElementById(ref.activeTab + "Home2").innerHTML = "";
      this.index = this.index + this.maxEntries + 1;
      if (this.index > this.arraySize - 1) {
        this.index = 0;
      }
      this.loadEntries();
    },
    lastEntries() {
      let ref = this;
      document.getElementById(ref.activeTab + "Home2").innerHTML = "";
      this.index = this.index - (this.maxEntries + 1);
      if (this.index < 0) {
        this.index = this.arraySize - (this.maxEntries + 1);
      }
      this.loadEntries();
    },
    getEntries() {
      var ref = this;
      ref.newsArray = [];
      ref.astaArray = [];
      ref.veranstaltungenArray = [];
      ref.initArray = [];
      var axios = require("axios");
      var data = JSON.stringify({
        username: "web163_2",
        pw: "Pf0rzelona",
        request: "get_news_home",
      });

      var config = {
        method: "post",
        url: "https://vs-pforzheim.de/getHandler.php",
        headers: {
          "Content-Type": "this/json",
        },
        data: data,
      };

      return axios(config)
        .then(function(response) {
          response.data.forEach((element, index) => {
            var tmpArray = new Map();
            tmpArray.set("id", element["id"]);
            tmpArray.set("type", element["type"]);
            tmpArray.set("title", element["title"]);
            tmpArray.set("date", element["date"]);
            tmpArray.set("text", element["text"]);
            tmpArray.set("link", element["link"]);
            tmpArray.set("linkText", element["linkText"]);
            tmpArray.set("image", element["image"]);
            tmpArray.set("video", element["video"]);

            switch (element["type"]) {
              case "news":
                ref.newsArray.push(tmpArray);
                break;
              case "veranstaltungen":
                ref.veranstaltungenArray.push(tmpArray);
                break;
              case "asta":
                ref.astaArray.push(tmpArray);
                break;
              case "init":
                ref.initArray.push(tmpArray);
                break;

              default:
                break;
            }
          });
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    loadEntries() {
      var entries = 0;
      var ref = this;
      var array = [];
      switch (ref.activeTab) {
        case "news":
          array = ref.newsArray;
          break;
        case "veranstaltungen":
          array = ref.veranstaltungenArray;
          break;
        case "asta":
          array = ref.astaArray;
          break;
        case "init":
          array = ref.initArray;
          break;
        default:
          break;
      }

      ref.arraySize = array.length;
      array.forEach((element, index) => {
        if (index >= ref.index && index <= ref.index + 1) {
          var isLastElement;
          if (ref.maxEntries >= entries) {
            if (entries != ref.maxEntries) {
              isLastElement = false;
            } else {
              isLastElement = true;
            }
            ref.createNewsEntry(array[index], isLastElement);
            entries += 1;
          }
        }
      });
    },
    createNewsEntry(array, isLastElement) {
      let type = array.get("type");

      var div = document.createElement("div");
      div.classList.add("item");
      div.style.textAlign = "left";
      div.style.padding = "10px";
      div.id = "item2" + array.get("id");
      document.getElementById(type + "Home2").appendChild(div);

      var div2 = document.createElement("div");
      div2.classList.add("inhalt");
      div2.classList.add("row");
      div2.id = "inhalt2" + array.get("id");
      document.getElementById("item2" + array.get("id")).appendChild(div2);

      var div3 = document.createElement("div");
      div3.classList.add("col-12");
      div3.classList.add("text");
      div3.id = "text2" + array.get("id");
      document.getElementById("inhalt2" + array.get("id")).appendChild(div3);

      var h2 = document.createElement("h2");
      h2.id = "heading" + array.get("id");
      h2.style.margin = "20px 0px 0px 20px";
      var heading = document.createTextNode(array.get("title"));
      h2.appendChild(heading);
      document.getElementById("text2" + array.get("id")).appendChild(h2);

      var date = document.createElement("h6");
      date.id = "date2" + array.get("id");
      date.style.margin = "5px 0px 0px 20px";
      date.classList.add("text-muted");
      var dateText = document.createTextNode(array.get("date"));
      date.appendChild(dateText);
      document.getElementById("text2" + array.get("id")).appendChild(date);

      var p = document.createElement("p");
      p.id = "p2" + array.get("id");
      p.style.fontSize = "20px";
      p.style.textAlign = "justify";
      p.style.padding = "20px";
      var pText = document.createTextNode(array.get("text"));
      p.appendChild(pText);
      document.getElementById("text2" + array.get("id")).appendChild(p);

      var a = document.createElement("a");
      a.id = "a2" + array.get("id");
      a.href = array.get("link");
      a.target = "_blank";
      a.style.fontSize = "20px";
      a.style.padding = "20px";
      a.style.color = "#FC6E32";
      var aText = document.createTextNode(array.get("linkText"));
      a.appendChild(aText);
      document.getElementById("text2" + array.get("id")).appendChild(a);

      var div4 = document.createElement("div");
      div4.classList.add("col-12");
      div4.classList.add("media");
      div4.id = "media2" + array.get("id");
      document.getElementById("inhalt2" + array.get("id")).appendChild(div4);

      if (array.get("image") != "") {
        var img = document.createElement("img");
        img.style.float = "right";
        img.style.width = "400px";
        img.style.paddingLeft = "20px";

        img.src = "https://vs-pforzheim.de/" + array.get("image");
        document.getElementById("media2" + array.get("id")).appendChild(img);
      }

      if (array.get("video") != "") {
        var vid = document.createElement("iframe");
        vid.height = "315";
        vid.width = "560";
        vid.style.paddingLeft = "20px";

        vid.frameBorder = "0";
        vid.src = array.get("video");
        document.getElementById("media2" + array.get("id")).appendChild(vid);
      }

      if (!isLastElement) {
        var hr = document.createElement("hr");
        hr.style.marginTop = "50px";
        hr.style.textAlign = "center";
        document.getElementById("item2" + array.get("id")).appendChild(hr);
      }
    },
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
@import url("https://use.typekit.net/kve4mnq.css");

.news {
  /* background-image: url('../../assets/backgrounds/NewsBackground.png'); */
  min-height: 100vh;
  background-position-y: -100px;
  background-repeat: no-repeat;
  margin-top: 100px;
}

.nav-item {
  font-size: 15px !important;
}

.btn {
  background-color: var(--primary-orange);
  color: white;
  width: 300px;
  margin-top: 20px;
  border-radius: 20px;
  cursor: pointer;
}

img {
  max-width: 400px;
}

.list-group-item {
  cursor: pointer;
}

.newsContainer {
  padding: 100px 160px 50px 160px;
}

.card {
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  background-color: white;
}

.col1 {
  max-width: 20%;
}

.list-group-item.active {
  background-color: var(--primary-orange);
  border-color: var(--primary-orange);
}

.item {
  text-align: left;
  padding: 10px;
}

p {
  font-size: 20px;
  text-align: justify;
  padding: 20px;
}

a {
  color: var(--primary-orange);
}

hr {
  margin-top: 20px;
  width: 98%;
}

#orchesterImg {
  width: 100%;
}

.tabs {
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  background-color: white;
}

.tabs p {
  font-size: 20px;
  text-align: justify;
}

.nav-link {
  font-size: 20px;
}

.item h2,
.item h6 {
  margin: 20px 0px 0px 20px;
}

.nav {
  justify-content: center;
}

.nav-item {
  width: 20%;
}
</style>
