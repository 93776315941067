<template>
	<div class="service">
		<h1 id="serviceHeading">Service</h1>
					<div class="row">

			<div class="flexbox1" data-aos="fade-right" data-aos-duration="1500" data-aos-delay="200">
				<h1>
					Verleih
				</h1>
				<h2>
					Diese Gegenstände könnt ihr im AStA-Büro gegen ein Pfand ausleihen.
					Für die Reservierung besucht uns im AStA-Büro oder nutzt unser Formular!
				</h2>
				<div class="row">
					<table class="table borderless">
						<tbody>
							<tr>
								<td>
									<img class="serviceIcon" id="Box2" src="@/assets/icons/Box.png"
										@click="select('Box2')">
								</td>
								<td>
									<img class="serviceIcon" id="Strandmatte2" src="@/assets/icons/Strandmatte.png"
										@click="select('Strandmatte2')">
								</td>
								<td>
									<img class="serviceIcon" id="Ball2" src="@/assets/icons/Ball.png"
										@click="select('Ball2')">
								</td>
								<td>
									<img class="serviceIcon" id="Rechner2" src="@/assets/icons/Rechner.png"
										@click="select('Rechner2')">
								</td>
							</tr>
							<tr class="tableDescr">
								<td>
									<p>
										Ghettoblaster
									</p>
								</td>
								<td>
									<p>
										Strandmatte
									</p>
								</td>
								<td>
									<p>
										Bälle
									</p>
								</td>
								<td>
									<p>
										Taschenrechner
									</p>
								</td>
							</tr>
						</tbody>
					</table>



				</div>
				<form>
					<div class="form-row">
						<div class="form-group col-6">
							<label for="nameVerleih2">Name</label>
							<input type="nameVerleih2" class="form-control" id="nameVerleih2" aria-describedby="nameHelp"
								placeholder="Gib deinen Namen ein">
							<small id="emailHelp" class="form-text text-muted"></small>
						</div>
						<div class="form-group col-6">
							<label for="matrikelnrVerleih2">Matrikelnummer</label>
							<input type="matrikelnrVerleih2" class="form-control" id="matrikelnrVerleih2"
								aria-describedby="matrikelnrHelp" placeholder="Gib deine Matrikelnummer ein">
							<small id="emailHelp" class="form-text text-muted"></small>
						</div>
					</div>
					<div class="form-group">
						<label for="emailVerleih2">E-Mail</label>
						<input type="emailVerleih2" class="form-control" id="emailVerleih2" aria-describedby="emailHelp"
							placeholder="Gib deine E-Mail ein">
						<small id="emailHelp" class="form-text text-muted"></small>
					</div>
					<div class="form-row">
						<div class="form-group col-6">
							<label for="startDateVerleih2">Ausleihdatum</label>
							<datepicker :language="de" id="startDateVerleih2" bootstrap-styling="true"></datepicker>
						</div>
						<div class="form-group col-6">
							<label for="endDateVerleih2">Rückgabedatum</label>
							<datepicker :language="de" id="endDateVerleih2" bootstrap-styling="true"></datepicker>
						</div>
					</div>
					<div class="form-group">
						<label for="anmerkungVerleih2">Anmerkung</label>
						<textarea type="text-area" class="form-control" id="anmerkungVerleih2"
							aria-describedby="anmerkungHelp" placeholder="Gib deine Anmerkung ein"></textarea>
						<small id="anmerkungHelp" class="form-text text-muted"></small>
					</div>
					<button v-on:click="submit" type="submit" class="btn " id="submitBtnPopup">Absenden</button>
				</form>
			</div>
					</div>
				<div class="row">

			<div class="flexbox2" data-aos="fade-left" data-aos-duration="1500" data-aos-delay="500">
				<h1>
					Service im AStA-Büro
				</h1>
				<img src="@/assets/bilder/service1.jpeg">
				<ul>
					<li>
						Ring- und Thermobindungen (Bereitstellen der Geräte zum Selbst-Binden)
					</li>
					<li>
						Umschläge
					</li>
					<li>
						Laminieren
					</li>
					<li>
						Wohnungsliste
					</li>
					<li>
						Sportprogramm
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
	import $ from "jquery";
	import Datepicker from 'vuejs-datepicker';
	import {
		de
	} from 'vuejs-datepicker/dist/locale'
	export default {
		components: {
			Datepicker,
			activeItem: null
		},
		data() {
			return {
				de: de,
				name: '',
				matNr: '',
				email: '',
				startDate: '',
				endDate: '',
				anmerkung: '',
				request: '',
				receiver: ''
			}
		},
		methods: {
			select(id) {

				var selected = document.getElementById(id);
				this.resetActiveItems()
				const items2 = ["Box",  "Strandmatte",  "Ball", "Rechner"]
				const items = ["Box2",  "Strandmatte2", "Ball2", "Rechner2"]

				for (var i = 0; i < items.length; i++) {
					if (id == items[i]) {
						selected.src = require('@/assets/icons/' + items2[i] + '_active.png');
						this.activeItem = items2[i];
					}
				}

			},
			resetActiveItems() {
				const items2 = ["Box",  "Strandmatte", "Ball", "Rechner"]
				const items = ["Box2",  "Strandmatte2",  "Ball2", "Rechner2"]
				for (var i = 0; i < items.length; i++) {
					document.getElementById(items[i]).src = require('@/assets/icons/' + items2[i] + '.png')
				}
			},
			submit: function () {
				this.name = document.getElementById("nameVerleih2").value
				this.matNr = document.getElementById("matrikelnrVerleih2").value
				this.email = document.getElementById("emailVerleih2").value
				this.startDate = document.getElementById("startDateVerleih2").value
				this.endDate = document.getElementById("endDateVerleih2").value
				this.anmerkung = document.getElementById("anmerkungVerleih2").value
				this.request = "verleih"
				// this.receiver = "julian.zoller@mail-zoller.de"
				this.receiver = "asta@hs-pforzheim.de"

				this.sendMessage()
			},
			sendMessage() {

				var axios = require('axios');

				var data = JSON.stringify({
					"request": this.request,
					"name": this.name,
					"mail": this.email,
					"receiver": this.receiver,
					"matNr": this.matNr,
					"startDate": this.startDate,
					"endDate": this.endDate,
					"subject": this.activeItem,
					"message": this.anmerkung

				});
				var config = {
					method: 'post',
					url: 'https://vs-pforzheim.de/mailer.php',
					headers: {
						'Content-Type': 'this/json'
					},
					data: data
				};

				return axios(config)
					.then(function (response) {

						document.getElementById("contactFieldAushangHome2").style.display = "none"
						document.getElementById("popupDate2").innerText = ""
						document.getElementById("popupFooter2").style.display = "block"
						document.getElementById("popupButton2").style.display = "block"
						document.getElementById("popupButton2").style.color = "white"
						if (response.data == "Message has been sent successfully") {

							document.getElementById("popupTitle").innerText = "Nachricht versendet!"

							document.getElementById("popupText").innerText =
								"Wir bearbeiten deine Anfrage und antworten dir so schnell wie möglich! Unter Umständen können E-Mails auch in deinem Spam landen!"

						} else {
							document.getElementById("popupTitle").innerText =
								"Ups, hier ist etwas schief gelaufen!"
							document.getElementById("popupText").innerText =
								"Auch unsere Technik hat den einen oder anderen Fehler! Bitte sende uns eine Mail an: test.XXX@xxx.de!"

						}
					})
					.catch(function (error) {
						console.log(error);
					});
			},
		},
		mounted() {
			$(function () {
				var bindDatePicker = function () {
					$(".date").datetimepicker({
						format: 'YYYY-MM-DD',
						icons: {
							time: "fa fa-clock-o",
							date: "fa fa-calendar",
							up: "fa fa-arrow-up",
							down: "fa fa-arrow-down"
						}
					}).find('input:first').on("blur", function () {
						// check if the date is correct. We can accept dd-mm-yyyy and yyyy-mm-dd.
						// update the format if it's yyyy-mm-dd
						var date = parseDate($(this).val());

						if (!isValidDate(date)) {
							//create date based on momentjs (we have that)
							date = moment().format('YYYY-MM-DD');
						}

						$(this).val(date);
					});
				}

				var isValidDate = function (value, format) {
					format = format || false;
					// lets parse the date to the best of our knowledge
					if (format) {
						value = parseDate(value);
					}

					var timestamp = Date.parse(value);

					return isNaN(timestamp) == false;
				}

				var parseDate = function (value) {
					var m = value.match(/^(\d{1,2})(\/|-)?(\d{1,2})(\/|-)?(\d{4})$/);
					if (m)
						value = m[5] + '-' + ("00" + m[3]).slice(-2) + '-' + ("00" + m[1]).slice(-2);

					return value;
				}

				bindDatePicker();
			});
		}
	}
</script>

<style scoped>
	.service {
		margin: 100px;
		margin-top: 150px;
	}


	.flexbox1 {
		background-color: #333333;
		padding: 10px;
	}

	.flexbox1,
	.flexbox2 {
		text-align: center;
		margin: 20px;
		border-radius: 20px;
		-webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.75);
		-moz-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.75);
		box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
	}

	.flexbox2 {
				background-color: #E7E0D4;

		width: 100%;
		text-align: center;
	}

	.flexbox2 img {}

	h1 {
		padding: 20px;
		color: black;
	}

	ul {
		text-align: left;
		padding: 25px 20vw 25px 20vw;
		color: black;
	}

	li {
		font-size: 20px;
	}

	h2 {
		font-weight: 200;
		font-size: 20px;
		color: white;
	}

	label {
		color: white;
	}

	.form-row {
		text-align: center;
	}

	#serviceHeading {
		color: var(--asta-1);
		font-size: 50px;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 20px;
	}

	#startDate {
		width: 100px !important;
	}

	.btn {
		background-color: white;
		transition: all 0.5s;
		border-radius: 40px;
	}

	svg {
		width: 50px;
		height: 50px;
		color: white;
	}

	p {
		color: white;
	}

	.borderless td,
	.borderless th {
		border: none;
	}

	table {
		margin-top: 10px;
	}

	.tableDescr {
		line-height: 0px;
	}


	#Rechner {
		width: 90px;
	}

	#Ball {
		width: 90px;
	}

	#Tennis {
		width: 90px;
	}

	#Tennis:hover,
	#Ball:hover,
	#Rechner:hover {
		width: 100px;
		transition: 0.5s;
	}

	.serviceIcon {
		transition: 1s;
		width: 100px;
		cursor: pointer;
	}

	.serviceIcon:hover {
		width: 110px;
		transition: 0.5s;
	}

	@media only screen and (max-width: 1600px) {
		.service {
			margin: 75px;
			margin-top: 150px;
		}
	}

	@media only screen and (max-width: 760px) {
		ul {
		padding: 50px;
	}
	}

	@media only screen and (max-width: 670px) {
		.flexbox2 img {
			width: 60vw;
		}
	}


</style>