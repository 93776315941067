<template>
    <div class="footer">
        <footer1 class="footer1"></footer1>
        <footer2 class="footer2"></footer2>
        <footer3 class="footer3"></footer3>
        <footer4 class="footer4"></footer4>
    </div>
</template>

<script>
  import footer1 from '@/components/Footer1.vue'
  import footer2 from '@/components/Footer2.vue'
  import footer3 from '@/components/Footer3.vue'
  import footer4 from '@/components/Footer4.vue'

    export default {
        components:{
            footer1,
            footer2,
            footer4,
            footer3
        },
    
    }
</script>

<style>
   @media only screen and (max-width: 1060px) {
    .footer1 {
      display: none;
    }
  }

  @media only screen and (min-width: 1061px) {
    .footer2 {
      display: none;
    }
  }
  @media only screen and (max-width: 705px) {
    .footer2 {
      display: none;
    }
  }

  @media only screen and (min-width: 706px) {
    .footer3 {
      display: none;
    }
  }

    @media only screen and (max-width: 515px) {
    .footer3 {
      display: none;
    }
  }

  @media only screen and (min-width: 516px) {
    .footer4 {
      display: none;
    }
  }
</style>