<template>
	<div class="DBAnpassung">
		<Erstellung></Erstellung>
		<navbar></navbar>
		<div class="selectContainer">
			<SiteFormular></SiteFormular>
		</div>
	</div>
</template>

<script>
	import $ from "jquery";
	import navbar from '@/components/NavBarDashboard'
	import SiteFormular from '@/views/dashboard/SiteFormular.vue'
	import Erstellung from '@/views/dashboard/Erstellung.vue'
	import Bearbeitung from '@/views/dashboard/Bearbeitung.vue'
	export default {
		components: {
			navbar,
			SiteFormular,
			Erstellung,
			Bearbeitung
		},
		data: {
			site: '',
			siteCache: [],
			options: [],
			optionsCache: [],
			title: '',
			titleCache: '',
			btn: [],
			btnCache: []
		},
		mounted() {
			$(document).ready(function () {
				$(".active").removeClass("active");
				$("#navDBAnpassung").addClass("active");
			});
			var ref = this;
			ref.site = '';
			ref.siteCache = [];
			ref.options = [];
			ref.title = '';
			ref.optionsCache = [];
			ref.titleCache = '';
			ref.btn = [];
			ref.btnCache = [];

			this.setListener();
			this.setInitialForm();
		},
		methods: {
			setListener() {
				var ref = this;
				var protkollSelect = document.getElementById('siteSelect');
				protkollSelect.onchange = (event) => {
					ref.setCache();
					ref.site = event.target.value;
					ref.changeFormular();
				}
				var formularBackBtn = document.getElementById('formularBackBtn');
				formularBackBtn.addEventListener("click", function () {
					ref.loadCache();
				});
			},
			setInitialForm() {
				this.title = "Welche Seite möchtest du ändern?"
				this.site = "home"
				this.btn = [];
				document.getElementById("formularBackBtn").style.display = "none";
				this.options = [{
						"text": "Wähle eine Seite",
						"value": "",
						"selected": true
					},
					{
						"text": "Startseite",
						"value": "Startseite"
					},
					{
						"text": "AStA",
						"value": "AStA"
					},
					{
						"text": "StuRa",
						"value": "StuRa"
					},
					{
						"text": "Fachschaften",
						"value": "Fachschaften"
					},
				];
				this.setSiteFormular()
			},
			setSiteFormular() {
				var ref = this;
				var h1 = document.getElementById("selecth1");
				h1.innerText = this.title
				document.getElementById('siteSelect').innerHTML = ""

				let optionList = document.getElementById('siteSelect').options;
				this.options.forEach(option =>
					optionList.add(
						new Option(option.text, option.value, option.selected)
					)
				);
				$('#siteSelect').css('color', 'white');
				$('#siteSelect').css('background-color', '#343A3F');
				this.btn.forEach(btn => {
					document.getElementById(btn).style.display = "block";
				});
			},
			setCache() {
				var ref = this;
				ref.siteCache.push(ref.site);
			},
			loadCache() {
				let ref = this;
				document.getElementById('siteSelect').style.display = "initial";
				document.getElementById('btnRowFormular').style.display = "none";
				document.getElementById('timeController').style.display = "none";
				ref.site = ref.siteCache[ref.siteCache.length - 1]
				ref.siteCache.pop();
				ref.changeFormular();
			},
			hideCols() {
				var array = ["title", "text", "image", "linkText", "link", "yt", "date", "email", "shortText", "longText",
					"media", "name", "menu", "menuSelect"
				]
				array.forEach(element => {
					document.getElementById(element + "Col").style.cssText = "display: none"
				});
			},
			showCols(array) {
				this.hideCols()
				document.getElementById('mediaSelect').style.display = "block";
				array.forEach(element => {
					document.getElementById(element + "Col").style.cssText = "display: block"
				});
			},
			changeFormular() {
				var ref = this;
				switch (ref.site) {
					case "home":
						ref.setInitialForm();

						break;
					case "Startseite":
						ref.title = "Welchen Bereich möchtest du ändern?";
						ref.btn = ["formularBackBtn"]
						ref.options = [{
								"text": "Wähle einen Bereich",
								"value": "",
								"selected": true
							},
							// {
							// 	"text": "News",
							// 	"value": "News - home"
							// },
							// {
							// 	"text": "News - Alle Veranstaltungen",
							// 	"value": "News - Alle Veranstaltungen"
							// },
							// {
							// 	"text": "News - AStA",
							// 	"value": "News - AStA"
							// },
							// {
							// 	"text": "News - Initiativen",
							// 	"value": "News - Initiativen"
							// },
							
						];
						break;
					case "AStA":
						ref.title = "Welchen Bereich möchtest du ändern?";
						ref.btn = ["formularBackBtn"]
						ref.options = [{
								"text": "Wähle einen Bereich",
								"value": "",
								"selected": true
							},
							{
								"text": "Aushang",
								"value": "Aushang - home"
							},
							{
								"text": "Galerie",
								"value": "Galerie - home"
							},
							{
								"text": "Referate",
								"value": "Referate - asta"
							},
							{
								"text": "Veranstaltungen",
								"value": "Veranstaltungen - asta"
							},
							{
								"text": "Information",
								"value": "Information - asta"
							},
							{
								"text": "Protokolle",
								"value": "Protokolle - asta"
							},
							{
								"text": "Öffnungszeiten",
								"value": "zeiten - asta"
							}
						];
						break;
					case "StuRa":
						ref.title = "Welchen Bereich möchtest du ändern?";
						ref.btn = ["formularBackBtn"]
						ref.options = [{
								"text": "Wähle einen Bereich",
								"value": "",
								"selected": true
							},
							{
								"text": "Infos",
								"value": "Infos - stura"
							},
							{
								"text": "Satzungen",
								"value": "Satzungen"
							},
							{
								"text": "Protokolle",
								"value": "Protokolle"
							}
						];
						break;
					case "Fachschaften":
						ref.title = "Welchen Bereich möchtest du ändern?";
						ref.btn = ["formularBackBtn"]
						ref.options = [{
								"text": "Wähle einen Bereich",
								"value": "",
								"selected": true
							},
							{
								"text": "Technik - Kalender",
								"value": "Technik - Kalender"
							},
							{
								"text": "Technik - Galerie",
								"value": "Technik - Galerie"
							}
						];
						break;
					case "Fachschaften":
						ref.title = "Welchen Bereich möchtest du ändern?";
						ref.btn = ["formularBackBtn"]

						break;
					case "News - home":
						ref.title = "Startseite - News";
						ref.btn = ["formularBackBtn"]
						ref.setAdministration("Startseite - News")
						document.getElementById('siteSelect').style.display = "none";
						var array = ["title", "text", "linkText", "link", "date", "media"]
						this.showCols(array)

						break;
					case "News - Alle Veranstaltungen":
						ref.title = "Startseite - News - Alle Veranstaltungen";
						ref.btn = ["formularBackBtn"]
						ref.setAdministration("Startseite - News - Alle Veranstaltungen")
						document.getElementById('siteSelect').style.display = "none";
						var array = ["title", "text", "linkText", "link", "date", "media"]
						this.showCols(array)

						break;
					case "News - AStA":
						ref.title = "Startseite - News - AStA";
						ref.btn = ["formularBackBtn"]
						ref.setAdministration("Startseite - News - AStA")
						document.getElementById('siteSelect').style.display = "none";
						var array = ["title", "text", "linkText", "link", "date", "media"]
						this.showCols(array)

						break;
					case "News - Initiativen":
						ref.title = "Startseite - News - Initiativen";
						ref.btn = ["formularBackBtn"]
						ref.setAdministration("Startseite - News - Initiativen")
						document.getElementById('siteSelect').style.display = "none";
						var array = ["title", "text", "linkText", "link", "date", "media"]
						this.showCols(array)

						break;
					case "Aushang - home":
						ref.title = "Startseite - Aushang";
						ref.btn = ["formularBackBtn"]
						ref.setAdministration("Startseite - Aushang")
						document.getElementById('siteSelect').style.display = "none";
						var array = ["title", "text", "email", "date"]

						this.showCols(array)
						this.showOnlyImage();

						break;
					case "Galerie - home":
						ref.title = "Startseite - Galerie";
						ref.btn = ["formularBackBtn"]
						ref.setAdministration("Startseite - Galerie")
						document.getElementById('siteSelect').style.display = "none";
						var array = [];
						this.showCols(array)
						this.showOnlyImage();

						break;
					case "Referate - asta":
						ref.title = "Referate - AStA";
						ref.btn = ["formularBackBtn"]
						ref.setAdministration("Referate - AStA")
						document.getElementById('siteSelect').style.display = "none";
						var array = ["title", "email", "name", "shortText"]
						this.showCols(array)
						this.showOnlyImage();


						break;
					case "zeiten - asta":
						ref.title = "Öffnungszeiten - AStA";
						ref.btn = ["formularBackBtn"]
						ref.setAdministration("zeiten - asta")
						document.getElementById('siteSelect').style.display = "none";


						break;
					case "Veranstaltungen - asta":
						ref.title = "Veranstaltungen - AStA";
						ref.btn = ["formularBackBtn"]
						ref.setAdministration("Veranstaltungen - AStA")
						document.getElementById('siteSelect').style.display = "none";
						var array = ["menu", "text", "linkText", "link", "media"]
						this.showCols(array)

						break;
					case "Information - asta":
						ref.title = "Information - AStA";
						ref.btn = ["formularBackBtn"]
						ref.setAdministration("Information - AStA")
						document.getElementById('siteSelect').style.display = "none";
						var array = ["menuSelect", "title", "text", "linkText", "link", "media"]
						this.showCols(array)

						break;
					case "Protokolle - asta":
						ref.title = "Protokolle - AStA";
						ref.btn = ["formularBackBtn"]
						ref.setAdministration("Protokolle - AStA")
						document.getElementById('siteSelect').style.display = "none";
						var array = ["date", "title", "media"]
						this.showCols(array)

						break;
					case "Infos - stura":
						ref.title = "Infos - StuRa";
						ref.btn = ["formularBackBtn"]
						ref.setAdministration("Infos - StuRa")
						document.getElementById('siteSelect').style.display = "none";
						var array = ["date", "title", "text", "media"]
						this.showCols(array)
						this.showOnlyImage();

						break;

					case "Protokolle":
						ref.title = "StuRa - Protokolle";
						ref.btn = ["formularBackBtn"]
						ref.setAdministration("StuRa - Protokolle")
						document.getElementById('siteSelect').style.display = "none";
						var array = ["date", "title", "media"]

						this.showCols(array)
						this.showOnlyImage();

						break;

					case "Satzungen":
						ref.title = "Welchen Bereich möchtest du ändern?";
						ref.btn = ["formularBackBtn"]
						ref.options = [{
								"text": "Wähle einen Bereich",
								"value": "",
								"selected": true
							},
							{
								"text": "Organisationssatzung",
								"value": "Organisationssatzung"
							},
							{
								"text": "Beitragsordnung",
								"value": "Beitragsordnung"
							},
							{
								"text": "Haushaltsplan",
								"value": "Haushaltsplan"
							}, {
								"text": "Wahlordnung",
								"value": "Wahlordnung"
							},
							{
								"text": "Finanzordnung",
								"value": "Finanzordnung"
							},
							{
								"text": "Qualitätssicherungsmittel",
								"value": "Qualitätssicherungsmittel"
							}
						];

						break;

					case "Organisationssatzung":
						ref.title = "StuRa - Protokolle - Organisationssatzung";
						ref.btn = ["formularBackBtn"]
						ref.setAdministration("StuRa - Protokolle - Organisationssatzung")
						document.getElementById('siteSelect').style.display = "none";
						var array = ["media"]

						this.showCols(array)
						this.showOnlyImage();

						break;

					case "Beitragsordnung":
						ref.title = "StuRa - Protokolle - Beitragsordnung";
						ref.btn = ["formularBackBtn"]
						ref.setAdministration("StuRa - Protokolle - Beitragsordnung")
						document.getElementById('siteSelect').style.display = "none";
						var array = ["media"]

						this.showCols(array)
						this.showOnlyImage();

						break;

					case "Haushaltsplan":
						ref.title = "StuRa - Protokolle - Haushaltsplan";
						ref.btn = ["formularBackBtn"]
						ref.setAdministration("StuRa - Protokolle - Haushaltsplan")
						document.getElementById('siteSelect').style.display = "none";
						var array = ["media"]

						this.showCols(array)
						this.showOnlyImage();

						break;

					case "Wahlordnung":
						ref.title = "StuRa - Protokolle - Wahlordnung";
						ref.btn = ["formularBackBtn"]
						ref.setAdministration("StuRa - Protokolle - Wahlordnung")
						document.getElementById('siteSelect').style.display = "none";
						var array = ["media"]

						this.showCols(array)
						this.showOnlyImage();

						break;

					case "Finanzordnung":
						ref.title = "StuRa - Protokolle - Finanzordnung";
						ref.btn = ["formularBackBtn"]
						ref.setAdministration("StuRa - Protokolle - Finanzordnung")
						document.getElementById('siteSelect').style.display = "none";
						var array = ["media"]

						this.showCols(array)
						this.showOnlyImage();

						break;

					case "Qualitätssicherungsmittel":
						ref.title = "StuRa - Protokolle - Qualitätssicherungsmittel";
						ref.btn = ["formularBackBtn"]
						ref.setAdministration("StuRa - Protokolle - Qualitätssicherungsmittel")
						document.getElementById('siteSelect').style.display = "none";
						var array = ["media"]

						this.showCols(array)
						this.showOnlyImage();

						break;

					case "Technik - Kalender":
						ref.title = "Technik - Kalender";
						ref.btn = ["formularBackBtn"]
						ref.setAdministration("Technik - Kalender")
						document.getElementById('siteSelect').style.display = "none";
						var array = ["date", "title", "text", "media"]
						this.showCols(array)
						this.showOnlyImage();

						break;

					case "Technik - Galerie":
						ref.title = "Technik - Galerie";
						ref.btn = ["formularBackBtn"]
						ref.setAdministration("Technik - Galerie")
						document.getElementById('siteSelect').style.display = "none";
						var array = [];
						this.showCols(array)
						this.showOnlyImage();

					default:
						break;

				}
				this.setSiteFormular()
			},
			setAdministration(site) {
				if (site == "zeiten - asta") {
					var ref = this
					document.getElementById('timeController').style.display = "block";
				} else {
					var ref = this
					document.getElementById('btnRowFormular').style.display = "flex";
					var erstellBtn = document.getElementById("erstellenBtn")
					erstellBtn.addEventListener("click", function () {
						ref.showErstellen(site);
					});

					var bearbeitenBtn = document.getElementById("bearbeitenBtn")
					bearbeitenBtn.addEventListener("click", function () {
						ref.showBearbeiten(site);
					});
				}
			},
			showErstellen(site) {
				let popupTitle = "Eintrag erstellen - " + site;
				document.getElementById("popupTitle").innerHTML = "";
				document.getElementById("popupTitle").innerText = popupTitle;

				document.getElementById("popupDB").innerHTML = "";
				document.getElementById("ID").value = "";
				document.getElementById("titel").value = "";
				document.getElementById("menu").value = "";
				document.getElementById("datum").value = "";
				document.getElementById("email").value = "";
				document.getElementById("name").value = "";
				document.getElementById("text").value = "";
				document.getElementById("shortText").value = "";
				document.getElementById("longText").value = "";
				document.getElementById("YT").value = "";
				document.getElementById("linkText").value = "";
				document.getElementById("Link").value = "";
				document.getElementById("popupDB").innerText = site;
				document.getElementById("uploadedImage").innerHTML = ""
				document.getElementById('uploadForm').style.display = "block"
				document.getElementById('thumb').style.display = "none"
				document.getElementById('DBTable').style.display = "none"



				$('#erstellenPopup').modal('show');
			},
			showBearbeiten(site) {
				let popupTitle = "Eintrag ändern - " + site;
				document.getElementById("popupTitle").innerHTML = "";
				document.getElementById("popupTitle").innerText = popupTitle;

				document.getElementById("popupDB").innerHTML = "";
				document.getElementById("popupDB").innerText = site;
				document.getElementById("uploadedImage").innerHTML = ""
				document.getElementById('thumb').style.display = "none"
				document.getElementById('uploadForm').style.display = "none"
				document.getElementById('DBTable').style.display = "inline-table"


				$('#erstellenPopup').modal('show');
			},

			showOnlyImage() {
				document.getElementById('mediaSelect').style.display = "none";
				document.getElementById('ytCol').style.display = "none";
				document.getElementById('imageCol').style.display = "block";
			},
		}
	}
</script>

<style scoped>
	.DBAnpassung {
		height: 100vh;
		position: relative;
		background-image: url('../../assets/backgrounds/BG-2.jpg');

	}

	.modal-backdrop {
		/* display: none; */
	}

	.modal {
		z-index: 1060;
	}

	.selectContainer {
		position: absolute;

		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
</style>