<template>
  <div class="news">
    <div id="infoStura"></div>
    <PopUp></PopUp>
    <div
      id="carousel-stura-indicators"
      class="carousel slide"
      data-interval="500000"
      data-ride="carousel"
    >
      <div class="carousel-inner" id="newsSturaCarousel"></div>
      <a
        class="carousel-control-prev"
        href="#carousel-stura-indicators"
        role="button"
        data-slide="prev"
      >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      </a>
      <a
        class="carousel-control-next"
        href="#carousel-stura-indicators"
        role="button"
        data-slide="next"
      >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
      </a>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import PopUp from "../../PopUp.vue";
export default {
  name: "newsStura",
  components: {
    PopUp,
  },
  data: {
    arraySize: 0,
    maxEntries: 0,
    aushangArray: [],
    maxTextLength: 0,
    groupnumber: 0,
    maxGroupLength: 0,
    groupIndex: 0,
  },
  mounted: async function() {
    let ref = this;
    ref.maxTextLength = 100;
    ref.maxEntries = 3;
    ref.groupnumber = 0;
    ref.groupIndex = 0;
    ref.maxGroupLength = 1;
    await this.getEntries();
    this.loadEntries();
  },
  methods: {
    getEntries() {
      var ref = this;
      ref.aushangArray = [];
      var axios = require("axios");
      var data = JSON.stringify({
        username: "web163_2",
        pw: "Pf0rzelona",
        request: "get_news_stura",
      });

      var config = {
        method: "post",
        url: "https://vs-pforzheim.de/getHandler.php",
        headers: {
          "Content-Type": "this/json",
        },
        data: data,
      };

      return axios(config)
        .then(function(response) {
          response.data.forEach((element, index) => {
            var tmpArray = new Map();
            tmpArray.set("id", element["id"]);
            tmpArray.set("title", element["title"]);
            tmpArray.set("date", element["date"]);
            tmpArray.set("text", element["text"]);
            tmpArray.set("image", element["image"]);
            ref.aushangArray.push(tmpArray);
          });
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    loadEntries() {
      var entries = 0;
      var ref = this;

      ref.aushangArray.forEach((element, index) => {
        var needDetails = false;
        var isFirstElement = false;
        if (index == 0) {
          isFirstElement = true;
        }
        ref.createEntry(element, needDetails, isFirstElement);
        entries += 1;
      });
    },
    createEntry(array, needDetails, isFirstElement) {
      this.groupnumber += 1;
      let ref = this;
      if (this.groupnumber % this.maxGroupLength == 0 || isFirstElement) {
        this.groupIndex += 1;
        var item = document.createElement("div");
        item.classList.add("carousel-item");

        if (isFirstElement) {
          item.classList.add("active");
        }

        item.id = "sturaNewsItem" + this.groupIndex;
        document.getElementById("newsSturaCarousel").appendChild(item);

        var flexbox = document.createElement("div");
        flexbox.style.cssText =
          "display: flex !important; flex-direction: row; align-items: stretch; flex-wrap: wrap; gap: 10px; padding: 50px;";
        flexbox.id = "sturaNewsItemFlexbox" + this.groupIndex;
        document
          .getElementById("sturaNewsItem" + this.groupIndex)
          .appendChild(flexbox);
      }

      var div = document.createElement("div");
      div.classList.add("aushang-box");
      div.style.textAlign = "left";
      div.style.padding = "10px";
      div.id = "sturaNewsBox" + array.get("id");
      div.style.cssText =
        " height: 100%; width: 100%; min-height: 350px; flex: 1 0 25%; background-color: white; position: relative; justify-content: left; border-radius: 10px; -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.75); -moz-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.75); box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);";
      document
        .getElementById("sturaNewsItemFlexbox" + this.groupIndex)
        .appendChild(div);

      var title = document.createElement("h2");
      var heading = document.createTextNode(array.get("title"));
      title.appendChild(heading);
      title.style.cssText =
        "z-index: 2; opacity: 1; position: relative; left: 20px; text-align: left; font-weight: 500; font-style: normal; color: var(--primary-green); font-size: 40px; top: 15px;";
      document
        .getElementById("sturaNewsBox" + array.get("id"))
        .appendChild(title);

      var date = document.createElement("h6");
      date.style.margin = "5px 0px 0px 20px";
      date.classList.add("text-muted");
      date.style.cssText =
        "z-index: 2; opacity: 1; position: relative; left: 20px; text-align: left; top: 15px;";
      var dateText = document.createTextNode(array.get("date"));
      date.appendChild(dateText);
      document
        .getElementById("sturaNewsBox" + array.get("id"))
        .appendChild(date);

      var p = document.createElement("p");
      p.style.fontSize = "20px";
      p.style.textAlign = "justify";
      p.style.padding = "20px";
      var text = array.get("text");
      if (text.length > this.maxTextLength) {
        text = array.get("text").substring(0, this.maxTextLength) + "...";
        needDetails = true;
      }
      if (array.get("image") != "") {
        needDetails = true;
      }
      var pText = document.createTextNode(text);
      p.appendChild(pText);
      document.getElementById("sturaNewsBox" + array.get("id")).appendChild(p);

      if (needDetails) {
        var detailsBtn = document.createElement("div");
        detailsBtn.classList = "btn";
        detailsBtn.id = "sturaNewsDetailsBtn" + array.get("id");

        detailsBtn.style.cssText =
          "background-color: var(--primary-green); color: white; transition: all 0.5s; z-index: 2; width: 100px; float: left; margin:20px; border-radius: 40px;";

        detailsBtn.textContent = "Details";
        document
          .getElementById("sturaNewsBox" + array.get("id"))
          .appendChild(detailsBtn);
        document
          .getElementById("sturaNewsDetailsBtn" + array.get("id"))
          .addEventListener("click", function() {
            ref.showDetails(array.get("id"));
          });
      }
    },
    showDetails(id) {
      var array;
      this.aushangArray.forEach((element) => {
        if (element.get("id") == id) {
          array = element;
        }
      });
      let popupTitle = array.get("title");
      let popupDate = array.get("date");
      let popupText = array.get("text");
      let popupImg = array.get("image");

      document.getElementById("popupTitle").innerHTML = "";
      document.getElementById("popupDate").innerHTML = "";
      document.getElementById("popupText").innerHTML = "";
      document.getElementById("popupImg").src = "";
      document.getElementById("popupEmail").innerHTML = "";
      document.getElementById("contactFieldAushangHome").style.display = "none";
      document.getElementById("popupBtn").style.display = "none";

      document.getElementById("popupTitle").style.color =
        "var(--primary-green)";
      document.getElementById("popupBtn").style.backgroundColor =
        "var(--primary-green)";

      document.getElementById("popupTitle").innerText = popupTitle;
      document.getElementById("popupDate").innerText = popupDate;
      document.getElementById("popupText").innerText = popupText;
      document.getElementById("popupImg").src =
        "https://vs-pforzheim.de/" + popupImg;
      document.getElementById("popupEmail").innerText = popupEmail;

      if (popupImg == "") {
        document.getElementById("popupImg").style.display = "none";
      }

      $("#popup").modal("show");
    },
  },
};
</script>

<style scoped>
@import url("https://use.typekit.net/kve4mnq.css");

.news {
  background-image: url("../../assets/backgrounds/NewsBackground.png");
  height: 1075px;
  background-position-y: -100px;
  background-repeat: no-repeat;
  margin-top: 100px;
}

#carousel-stura-indicators {
  padding: 150px;
}

.carousel-item {
}

.news-box {
}

@media only screen and (max-width: 640px) {
  #carousel-stura-indicators {
    padding-left: 60px;
    padding-right: 60px;
    margin-top: -20px;
  }
  .carousel-control-next,
  .carousel-control-prev {
    width: 100px !important;
  }
}

h2 {
  z-index: 2;
  opacity: 1;
  position: absolute;
  left: 20px;
  text-align: left;
  font-family: var(--font2);
  font-weight: 500;
  font-style: normal;
  color: white;
  font-size: 40px;
  top: 15px;
}

p {
  z-index: 2;
  opacity: 1;
  position: absolute;
  top: 70px;
  left: 20px;
  text-align: left;
  color: white;
}

.news-image {
  height: 100%;
  width: 100%;
  min-height: 350px;
  background-image: url("../../assets/bilder/NewsElement.png");
  opacity: 0.2;
  background-size: 100%;
}

.carousel-control-next-icon {
  margin-right: 75px;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='orange' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
}

.carousel-control-prev-icon {
  margin-left: 75px;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='orange' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
}

.carousel-indicators .active {
  background-color: var(--primary-orange);
}

.carousel-indicators li {
  background-color: var(--primary-green);
}

.btn-news {
  color: black;
  transition: all 0.5s;
  z-index: 2;
  position: absolute;
  top: 15em;
  left: 20px;
  width: 100px;
  background-color: white;
  border-radius: 40px;
}

.carousel-indicators {
  bottom: 100px;
}
</style>
