<template>
	<div class="kulturflat-Stura">
		<navbar></navbar>
		<div class="modal fade" id="deleteModal" tabindex="1" role="dialog" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" id="EintragCode"></h5>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary" @click="deleteCodeDB()">Ja</button>
						<button type="button" class="btn btn-danger" data-dismiss="modal">Nein</button>
					</div>
				</div>
			</div>
		</div>
		<div class="containerCard">
			<div class="card">
				<div class="card-body">
					<h1 class="card-title" data-aos="fade-left" data-aos-duration="1500">Code-Übersicht: {{year}}</h1>
					<div class="row">
						<div class="col">
							<div id="eingeloestSumme" data-aos="fade-up" data-aos-duration="1500">
								Eingelöste Codes: 0
							</div>
						</div>
						<div class="col">
							<div id="offenSumme" data-aos="fade-up" data-aos-duration="1500">
								Offene Codes: 0
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col">
							<label for="partner_filter">Partner-Filter</label>
							<select class="btn" id="partner_filter" @change="filterCode()">
								<option selected value="0">Alle</option>
								<option value="-">Noch nicht eingelöst</option>
								<option value="Stadttheater Pforzheim">Stadttheater Pforzheim</option>
								<option value="Kulturhaus Osterfeld">Kulturhaus Osterfeld</option>
								<option value="Mottenkäfig">Mottenkäfig</option>
								<option value="Figurentheater Raphael Mürle">Figurentheater Raphael Mürle</option>
								<option value="Technisches Museum">Technisches Museum</option>
								<option value="Schmuckmuseum">Schmuckmuseum</option>
								<option value="Südwestdeutsches Kammerorchester">Südwestdeutsches Kammerorchester
								</option>
								<option value="Kommunales Kino">Kommunales Kino</option>
							</select>
						</div>
						<div class="col">
							<label for="month_filter">Monats-Filter</label>
							<select class="btn" id="month_filter" @change="filterCode()">
								<option selected value="0">Alle</option>
								<option value="01">Januar</option>
								<option value="02">Februar</option>
								<option value="03">März</option>
								<option value="04">April</option>
								<option value="05">Mai</option>
								<option value="06">Juni</option>
								<option value="07">Juli</option>
								<option value="08">August</option>
								<option value="09">Septmber</option>
								<option value="10">Oktober</option>
								<option value="11">November</option>
								<option value="12">Dezember</option>
							</select>
						</div>
						<div class="col">
							<label for="documentation_filter">Dokumentations-Filter</label>
							<select class="btn" id="documentation_filter" @change="filterCode()">
								<option selected value="alle">Alle</option>
								<option value="dokumentiert">Dokumentiert</option>
								<option value="offen">Offen</option>
							</select>
						</div>
					</div>
					<div style="height: 40vh; overflow: auto;">
						<table class="table" id="CodeTable">
							<thead data-aos="fade-up" data-aos-duration="1500" data-aos-delay="1000"
								class="thead-light">
								<tr>
									<th id="ID">ID</th>
									<th id="Code">Code</th>
									<th id="Erstellt">Erstellt</th>
									<th id="Eingeloest">Eingelöst</th>
									<th id="Partner">Partner</th>
									<th id="Dokumentiert">Dokumentiert</th>
									<th></th>
								</tr>
							</thead>
							<tbody data-aos="flip-left" data-aos-duration="1500">
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<button class="btn  bg-dark" id="download-button">Download</button>
		</div>
		<!-- <div v-if="!$auth.loading">
			<div class="btn btn-dark" v-if="this.$auth.isAuthenticated" @click="logout" data-aos="fade-down"
				data-aos-duration="500" data-aos-delay="2000">ausloggen</div>
		</div> -->
	</div>
</template>

<script>
	import $ from "jquery";
	import navbar from '@/components/NavBarDashboard'
	import axios from "axios";
	export default {
		name: 'KulturflatPartner',
		components: {
			navbar,
			deleteCode: '',

		},
		data() {
			return {
				eingeloestSumme: 0,
				year: ''
			}
		},
		mounted() {

			this.year = new Date().getFullYear()
			var table = document.getElementById("CodeTable")
			var offen = 0
			var gesamt = 0
			let ref = this
			axios.get(
					"https://vs-pforzheim.de/flatHandler.php?request=getlist"
				)
				.then(response => {
					let responseArray = this.data_to_array(response.data);

					responseArray.forEach((element, index) => {

						var id = index + 1
						gesamt = id
						var code = element[0]
						code == "" ? "-" : code
						var eingeloest = element[1]

						var partner = element[2]
						if (eingeloest == null) {
							eingeloest = "-"
							partner = "-"
						} else {
							this.eingeloestSumme += 1
						}
						const documented =
							'<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-patch-check" viewBox="0 0 16 16">  <path fill-rule="evenodd" d="M10.354 6.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708 0z"></path>  <path d="m10.273 2.513-.921-.944.715-.698.622.637.89-.011a2.89 2.89 0 0 1 2.924 2.924l-.01.89.636.622a2.89 2.89 0 0 1 0 4.134l-.637.622.011.89a2.89 2.89 0 0 1-2.924 2.924l-.89-.01-.622.636a2.89 2.89 0 0 1-4.134 0l-.622-.637-.89.011a2.89 2.89 0 0 1-2.924-2.924l.01-.89-.636-.622a2.89 2.89 0 0 1 0-4.134l.637-.622-.011-.89a2.89 2.89 0 0 1 2.924-2.924l.89.01.622-.636a2.89 2.89 0 0 1 4.134 0l-.715.698a1.89 1.89 0 0 0-2.704 0l-.92.944-1.32-.016a1.89 1.89 0 0 0-1.911 1.912l.016 1.318-.944.921a1.89 1.89 0 0 0 0 2.704l.944.92-.016 1.32a1.89 1.89 0 0 0 1.912 1.911l1.318-.016.921.944a1.89 1.89 0 0 0 2.704 0l.92-.944 1.32.016a1.89 1.89 0 0 0 1.911-1.912l-.016-1.318.944-.921a1.89 1.89 0 0 0 0-2.704l-.944-.92.016-1.32a1.89 1.89 0 0 0-1.912-1.911l-1.318.016z"></path></svg>'
						const undocumented =
							'<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-slash-circle-fill" viewBox="0 0 16 16"> <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.646-2.646a.5.5 0 0 0-.708-.708l-6 6a.5.5 0 0 0 .708.708l6-6z"></path> </svg>'
						var dokumentiert = element[3]
						if (dokumentiert == '0') {
							dokumentiert = '<button id="dokCheck' + id +
								'" class="btn btn-secondary" >' + undocumented +
								'</button>'
						} else {
							dokumentiert = '<button id="dokCheck' + id +
								'" class="btn btn-success">' + documented + '</button>'
						}

						var dltBtn = '<button id="deleteBtn' + id +
							'" class="btn btn-danger">Löschen</button>'
						const erstellt = element[4]

						var row = table.insertRow(1)
						var cell1 = row.insertCell(0)
						var cell2 = row.insertCell(1)
						var cell3 = row.insertCell(2)
						var cell4 = row.insertCell(3)
						var cell5 = row.insertCell(4)
						var cell6 = row.insertCell(5)
						var cell7 = row.insertCell(6)

						cell1.innerHTML = id;
						cell2.innerHTML = code;
						cell3.innerHTML = erstellt;
						cell4.innerHTML = eingeloest;
						cell5.innerHTML = partner;
						cell6.innerHTML = dokumentiert
						cell7.innerHTML = dltBtn

						var btn = document.getElementById("dokCheck" + id)

						btn.addEventListener("click", function () {
							if (btn.innerHTML == documented) {
								btn.innerHTML = undocumented
								btn.classList.remove("btn-success")
								btn.classList.add("btn-secondary")

								ref.sendUpdate(code, "0")
							} else {
								btn.innerHTML = documented
								btn.classList.remove("btn-secondary")
								btn.classList.add("btn-success")
								ref.sendUpdate(code, "1")

							}
						});

						var dltButton = document.getElementById("deleteBtn" + id)

						$('#deleteBtn' + id).bind("click", function () {
							$('#deleteModal').modal('toggle')
							ref.deleteCode = code
							document.getElementById("EintragCode").innerText = "Code '" + code +
								"' wirklich löschen?"
						});

					});
					offen = gesamt - this.eingeloestSumme
					document.getElementById("eingeloestSumme").innerHTML = "Eingelöst: " + this.eingeloestSumme
					document.getElementById("offenSumme").innerHTML = "Offen: " + offen

					ref.highlightRows()
				})
			document.getElementById("download-button").addEventListener("click", function () {
				var html = document.querySelector("table").outerHTML;
				ref.htmlToCSV(html, "Kulturflat-Übersicht.csv");
			})


		},
		methods: {
			htmlToCSV(html, filename) {
				var data = [];
				var rows = document.querySelectorAll('tr:not([style*="display: none;"])');
				
				for (var i = 0; i < rows.length; i++) {
					var row = [],
						cols = rows[i].querySelectorAll('td, th');

					for (var j = 0; j < cols.length - 2; j++) {
						row.push(cols[j].innerText);
					}

					data.push(row.join(","));
				}

				this.downloadCSVFile(data.join("\n"), filename);
			},
			downloadCSVFile(csv, filename) {
				var csv_file, download_link;

				csv_file = new Blob([csv], {
					type: "text/csv"
				});

				download_link = document.createElement("a");

				download_link.download = filename;

				download_link.href = window.URL.createObjectURL(csv_file);

				download_link.style.display = "none";

				document.body.appendChild(download_link);

				download_link.click();
			},
			highlightRows() {
				var table = document.getElementById("CodeTable");
				var tdIdMonth = 2;
				var tr = table.getElementsByTagName("tr");

				for (var i = 1; i < tr.length; i++) {
					var tdMonth = tr[i].getElementsByTagName("td")[tdIdMonth];
					var txtValueMonth = tdMonth.textContent || tdMonth.innerText;
					if (txtValueMonth == "-") {
						tr[i].style.backgroundColor = "lightgrey"
					}
				}
			},
			deleteCodeDB() {
				var axios = require('axios');

				var config = {
					method: 'get',
					url: 'https://vs-pforzheim.de/flatHandler.php?request=deleteCode&code=' + this.deleteCode,
					headers: {}
				};

				axios(config)
					.then(function (response) {
						location.reload()
						console.log(JSON.stringify(response.data));
					})
					.catch(function (error) {
						console.log(error);
					});
			},
			sendUpdate(code, state) {
				var axios = require('axios');

				var config = {
					method: 'get',
					url: 'https://vs-pforzheim.de/flatHandler.php?request=updateDoc&code=' + code + '&state=' +
						state,
					headers: {}
				};

				axios(config)
					.then(function (response) {
						console.log(JSON.stringify(response.data));
					})
					.catch(function (error) {
						console.log(error);
					});


			},
			filterCode() {

				var tableArray = []

				var table = document.getElementById("CodeTable");
				var sumOfRows = 0;

				var selectDoku = document.getElementById("documentation_filter");
				var tdIdDoku = 5;

				var selectPartner = document.getElementById("partner_filter");
				var tdIdPartner = 4;

				var selectMonth = document.getElementById("month_filter");
				var tdIdMonth = 3;


				var filterPartner = selectPartner.value.toUpperCase();
				var filterMonth = selectMonth.value.toUpperCase();

				var tr = table.getElementsByTagName("tr");
				for (var i = 0; i < tr.length; i++) {
					var tdID = tr[i].getElementsByTagName("td")[0];
					var tdPartner = tr[i].getElementsByTagName("td")[tdIdPartner];
					var tdMonth = tr[i].getElementsByTagName("td")[tdIdMonth];
					var tdDoku = tr[i].getElementsByTagName("td")[tdIdDoku];

					if (tdPartner) {
						var txtValuePartner = tdPartner.textContent || tdPartner.innerText;
						var txtValueMonth = tdMonth.textContent || tdMonth.innerText;
						txtValueMonth = txtValueMonth.substring(3, 5)

						var dokValue = tdDoku.innerHTML

						var tdIDValue = tdID.textContent || tdID.innerText

						if (selectPartner.value == "0" && selectMonth.value == "0") {
							tableArray.push(tdIDValue)
						} else if (filterMonth == "0" && filterPartner.value != "0") { //Alle Monate
							if (txtValuePartner.toUpperCase().indexOf(filterPartner) > -1) { //Wenn eingeloest
								tableArray.push(tdIDValue)
							}
						} else if (selectPartner.value == "0" && filterMonth.value != "0") { //Alle Partner
							if (txtValueMonth.toUpperCase().indexOf(filterMonth) > -1) { //Wenn eingeloest
								tableArray.push(tdIDValue)
							}
						} else {
							if (txtValuePartner.toUpperCase().indexOf(filterPartner) > -1 && txtValueMonth
								.toUpperCase()
								.indexOf(filterMonth) > -1) { //Hier Selektionskriterium
								tableArray.push(tdIDValue)
							}
						}

						if (selectPartner.value == "-") {
							if (txtValueMonth == "-") {
								tableArray.pop()
							}
						}

						if (selectDoku.value == "dokumentiert") {
							if (!dokValue.includes("success")) {
								tableArray.pop()
							}
						} else if (selectDoku.value == "offen") {
							if (dokValue.includes("success")) {
								tableArray.pop()
							}
						}
						if (tableArray.includes(tdIDValue)) {
							if (selectPartner.value != "-") {
								sumOfRows += 1
							}
							tr[i].style.display = "";
						} else {
							tr[i].style.display = "none";
						}
					}
				}

				if (selectPartner.value == "0" && selectMonth.value == "0") {
					document.getElementById("eingeloestSumme").innerHTML = "Eingelöst: " + this.eingeloestSumme
				} else {
					document.getElementById("eingeloestSumme").innerHTML = "Eingelöst: " + sumOfRows
				}
			},
			data_to_array(data) {
				var array = [];
				for (var i = 0; i < data.length; i++) {
					var obj = data[i];
					array[i] = new Array();
					for (var key in obj) {
						array[i].push(obj[key]);
					}
				}
				return array;
			},
			logout() {
				this.$auth.logout({
					returnTo: window.location.origin
				});
			},
		}
	}
</script>

<style scoped>
	.kulturflat-Stura {
		background-image: url('../../assets/backgrounds/BG-2.jpg');
		background-repeat: no-repeat;
		background-position-x: right;
		text-align: center;
		height: 100vh;
	}


	.containerCard {
		padding-top: 100px;
		text-align: center;
		justify-content: center;
	}

	.card {
		width: fit-content;
		margin: auto;
		border-color: transparent;
		border-radius: 10px;
		-webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.75);
		-moz-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.75);
		box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
	}

	.btn {
		border-color: gray;
		width: 100%;
		border-radius: 20px;
		text-align: center;
	}

	#user {
		margin-bottom: 20px;
	}

	th {
		background: white;
		position: sticky;
		top: -1px;
		/* Don't forget this, required for the stickiness */
		box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
	}

	table {
		/* display: block; */
		text-align: center;
	}

	#ID {
		width: 100px;
	}

	#Code {
		width: 200px;
	}

	#Eingeloest {
		width: 100px
	}

	#Partner {
		width: 200px;
	}

	.form-select {
		width: 100%;
	}

	.row {
		padding: 10px 50px 30px 50px;
	}

	#eingeloestSumme {
		background-color: var(--primary-green);
		color: white;
		font-size: 20px;
		border-radius: 20px;
	}

	#offenSumme {
		background-color: var(--primary-orange);
		color: white;
		font-size: 20px;
		border-radius: 20px;
	}

	.btn-dark {
		border: none;
		width: 400px;
		background-color: black;
	}

	#download-button{
		margin: 20px 30vw 20px 30vw;
		color: white;
	}

</style>