import Vue from "vue";
import VueRouter from "vue-router";
import Playground from "@/components/playground.vue";
import Startseite from "@/components/Startseite.vue";
import Asta from "@/components/Asta.vue";
import Stura from "@/components/Stura.vue";
import Fachschaften from "@/components/Fachschaften.vue";
import kulturflatpartner from "@/components/KulturflatPartner.vue";
import Dashboard from "@/components/Dashboard.vue";
import KulturflatStura from "@/views/dashboard/KulturflatStura.vue";
import Datenschutz from "@/views/Datenschutz.vue";
import Impressum from "@/views/Impressum.vue";
import DBAnpassung from "@/views/dashboard/DBAnpassung.vue";
import DBAushang from "@/views/dashboard/DBAushang.vue";
import DBKalender from "@/views/dashboard/DBKalender.vue";
import Login from "@/components/Login.vue";
import Footer from "@/components/Footer.vue";

import { authGuard } from "../auth/authGuard";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Startseite",
    components: {
      default: Startseite,
      footer: Footer,
    },
  },
  {
    path: "/playground",
    name: "Playground",
    components: {
      default: Playground,
    },
  },
  {
    path: "/asta",
    name: "Asta",
    components: {
      default: Asta,
      footer: Footer,
    },
  },
  {
    path: "/fachschaften",
    name: "Fachschaften",
    components: {
      default: Fachschaften,
      footer: Footer,
    },
  },
  {
    path: "/stura",
    name: "Stura",
    components: {
      default: Stura,
      footer: Footer,
    },
  },
  {
    path: "/datenschutz",
    name: "Datenschutz",
    components: {
      default: Datenschutz,
      footer: Footer,
    },
  },
  {
    path: "/impressum",
    name: "Impressum",
    components: {
      default: Impressum,
      footer: Footer,
    },
  },
  {
    path: "/kultur/partner",
    name: "kulturflatpartner",
    components: {
      default: kulturflatpartner,
    },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    components: {
      default: Dashboard,
      footer: Footer,
    },
    beforeEnter: authGuard,
  },
  {
    path: "/dashboard/kulturflat",
    name: "KulturflatStura",
    components: {
      default: KulturflatStura,
    },
    beforeEnter: authGuard,
  },
  {
    path: "/dashboard/Anpassungen",
    name: "DBAnpassung",
    components: {
      default: DBAnpassung,
    },
    beforeEnter: authGuard,
  },
  {
    path: "/dashboard/AushangAnfragen",
    name: "DBAushang",
    components: {
      default: DBAushang,
    },
    beforeEnter: authGuard,
  },
  {
    path: "/dashboard/KalenderReservierung",
    name: "DBKalender",
    components: {
      default: DBKalender,
    },
    beforeEnter: authGuard,
  },
  {
    path: "/Login",
    name: "Login",
    components: {
      default: Login,
    },
  },
];

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
  routes,
});

export default router;
