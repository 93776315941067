<template>
	<div class="trin">
		<router-link class="stura router-link" to="Stura" data-aos="fade-right" data-aos-duration="1500">
			<h1 data-aos="zoom-out-left" data-aos-delay="1000" data-aos-duration="1000">
				StuRa
			</h1>
			<h3>
				Der Studierendenrat ist das Legislativorgan der verfassten Studierendenschaft.
				<br><br>
			</h3>
			<router-link class="router-link" to="Stura"><div class="btn btn-stura">
				<span>mehr</span>
			</div></router-link>
			<div class="sturaImg">
			</div>
		</router-link>
		<a class="asta router-link" href="https://asta-pforzheim.de" data-aos="fade-up" data-aos-duration="1500">
			<h1 data-aos="zoom-out-down" data-aos-delay="1000" data-aos-duration="1000">
				AStA
			</h1>
			<h3>
				Wir sind der allgemeine Studierendenausschuss, eure Studierendenvertretung und setzen uns für eure
				Belange ein.
				<br><br>
			</h3>
			<a class="router-link" href="https://asta-pforzheim.de" ><div class="btn btn-asta">
				<span>mehr</span>
			</div></a>
			<div class="astaImg"></div>
		</a>
		<router-link class="fachschaften router-link" to="Fachschaften" data-aos="fade-left" data-aos-duration="1500">
			<h1 data-aos="zoom-out-right" data-aos-delay="1000" data-aos-duration="1000">
				Fachschaften
			</h1>
			<h3>
				Studentische Selbstverwaltung<br>Wir stehen euch bei all euren Anliegen zur Seite.
				<br><br>
			</h3>
			<router-link class="router-link" to="Fachschaften"><div class="btn btn-fachschaften">
				<span>mehr</span>
			</div></router-link>
			<div class="fachschaftenImg"></div>
		</router-link>
		<div class="arrow" data-aos="fade-up" data-aos-duration="3500">
			<span></span>
			<span></span>
			<span></span>
		</div>
	</div>

</template>

<script>
	export default {

	}
</script>

<style scoped>
	@import url("https://use.typekit.net/kve4mnq.css");

	.trin {
		display: flex;
		flex-direction: row;
		align-items: stretch;
	}

	#menuStura {
		position: absolute;
		top: 50%;
		left: 0%;
		right: 0%;
		text-align: center;
		top: 15em;
		opacity: 0;
		margin: 100px;
	}

	.stura {
		background-color: var(--primary-green);
		flex-grow: 1;
		height: 45em;
		position: relative;
		justify-content: center;
		transition: 0.75s;
	}

	.stura:hover {
		background-color: black;
	}

	.stura:hover h1 {
		color: white;
		transition: 0.75s;
	}

	.stura:hover h2 {
		opacity: 0;
		transition: 0.75s;
		transition-delay: 0s !important;
	}

	.h2::after {
		transition-delay: 0s !important;
	}

	.stura:hover h3 {
		opacity: 1;
		transition: 0.75s;
	}

	.asta:hover h3 {
		opacity: 1;
		transition: 0.75s;
	}

	.fachschaften:hover h3 {
		opacity: 1;
		transition: 0.75s;
	}

	.stura:hover .btn-stura {
		opacity: 1;
		transition: 0.75s;
	}

	.asta:hover .btn-asta {
		opacity: 1;
		transition: 0.75s;
	}

	.fachschaften:hover .btn-fachschaften {
		opacity: 1;
		transition: 0.75s;
	}

	.sturaImg {
		flex-grow: 1;
		height: 45em;
		background-image: url('../../assets/bilder/SturaHeader.jpeg');
		background-repeat: no-repeat;
		background-size: cover;
		background-position: 50% 0;
		opacity: 0.25;
		z-index: 0;
	}

	.stura h2 {
		/* background-color: white; */
		padding-top: 130px;
		padding-bottom: 30px;
		z-index: 1;
	}

	.stura h3,
	.fachschaften h3,
	.asta h3 {
		font-family: var(--font2);
		font-weight: 400;
		font-style: italic;
		color: white;
		margin: 60px;
		opacity: 0;
	}

	.btn-stura,
	.btn-asta,
	.btn-fachschaften {
		/* z-index: 2; */
		position: absolute;
		top: 0%;
		left: 0%;
		right: 0%;
		text-align: center;
		top: 22em;
		opacity: 0;
		margin: 100px;
	}

	h1 {
		z-index: 2;
		opacity: 1;
		position: absolute;
		top: 0%;
		left: 0%;
		right: 0%;
		text-align: center;
		top: 1em;
		font-weight: 400;
	}


	h2 {
		z-index: 2;
		opacity: 1;
		position: absolute;
		left: 0%;
		right: 0%;
		text-align: center;
	}

	h3 {
		z-index: 2;
		opacity: 1;
		position: absolute;
		top: 20%;
		margin-left: auto;
		margin-right: auto;
		text-align: center;
	}

	.asta {
		background-color: #CC141C;
		flex-grow: 1;
		height: 45em;
		position: relative;
		transition: 0.75s;
	}

	.asta:hover {
		background-color: black;
	}

	.astaImg {
		flex-grow: 1;
		height: 45em;
		background-image: url('../../assets/bilder/AstaHeader.jpeg');
		background-repeat: no-repeat;
		background-size: cover;
		background-position: 52.5% 0;
		opacity: 0.25;
	}

	.asta h1 {
		color: white;
	}

	.stura h1 {
		color: white;
	}

	.fachschaften {
		background-color: var(--secondary-pastelle);
		flex-grow: 1;
		height: 45em;
		position: relative;
		transition: 0.75s;
	}

	.fachschaften:hover {
		background-color: black;
	}

	.fachschaftenImg {
		flex-grow: 1;
		height: 45em;
		background-image: url('../../assets/bilder/Engineering.png');
		background-repeat: no-repeat;
		background-size: cover;
		background-position: 50% 0;
		opacity: 0.25;
		transition: 0.75s;
	}

	.asta:hover {
		background-color: black;
	}

	.fachschaften h1 {
		color: white;
	}

	.btn-stura,
	.btn-asta,
	.btn-fachschaften {
		background-color: white;
		color: black;
		transition: all 0.5s;
		z-index: 3;
		margin-left: auto;
		margin-right: auto;
		cursor: pointer !important;
		border-radius: 40px;
		width: 100px;
	}

	.router-link {
		color: black;
	}

	.arrow {
		position: absolute;
		left: 50%;
		top: 680px;
		transform: translate(-50%, -50%);
	}

	.arrow span {
		display: block;
		width: 30px;
		height: 30px;
		border-bottom: 5px solid #2c3e50;
		border-right: 5px solid #2c3e50;
		transform: rotate(45deg);
		margin: -10px;
		animation: animate 2s infinite;
	}

	.arrow span:nth-child(2) {
		animation-delay: -0.2s;
	}

	.arrow span:nth-child(3) {
		animation-delay: -0.4s;
	}

	@keyframes animate {
		0% {
			opacity: 0;
			transform: rotate(45deg) translate(-20px, -20px);
		}

		50% {
			opacity: 1;
		}

		100% {
			opacity: 0;
			transform: rotate(45deg) translate(20px, 20px);
		}
	}
</style>