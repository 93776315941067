<template>
  <div class="aushang" id="aushang1">
    <h1 data-aos="fade-right" data-aos-duration="1500" data-aos-delay="100">
      Du suchst etwas oder möchtest etwas anbieten?
    </h1>
    <div
      id="carousel-aushang-indicators"
      class="carousel slide"
      data-interval="500000"
      data-ride="carousel"
      data-aos="fade-left"
      data-aos-duration="1500"
      data-aos-delay="700"
    >
      <div class="carousel-inner" id="aushangHome"></div>
      <a
        class="carousel-control-prev"
        href="#carousel-aushang-indicators"
        role="button"
        data-slide="prev"
      >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      </a>
      <a
        class="carousel-control-next"
        href="#carousel-aushang-indicators"
        role="button"
        data-slide="next"
      >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
      </a>
    </div>
    <h2 data-aos="fade-down" data-aos-duration="1500" data-aos-delay="100">
      Für dich ist nichts dabei?
    </h2>
    <div
      @click="createAnfrage()"
      class="btn"
      data-aos="fade-down"
      data-aos-duration="1500"
      data-aos-delay="700"
    >
      Sende uns deine Anfrage!
    </div>
  </div>
</template>

<script>
import $ from "jquery";
export default {
  name: "Aushang",
  components: {},
  data: {
    arraySize: 0,
    maxEntries: 0,
    aushangArray: [],
    maxTextLength: 0,
    groupnumber: 0,
    maxGroupLength: 0,
    groupIndex: 0,
  },
  mounted: async function() {
    let ref = this;
    ref.maxTextLength = 300;
    ref.maxEntries = 3;
    ref.groupnumber = 0;
    ref.groupIndex = 0;
    ref.maxGroupLength = 5;
    await this.getEntries();
    this.loadEntries();
  },
  methods: {
    createAnfrage() {
      let popupTitle = "Gesuch/Gebot anfragen";
      var popupDate = new Date();
      let day = popupDate.getDate();
      let month = popupDate.getMonth();
      let year = popupDate.getFullYear();

      popupDate = day + "." + month + "." + year;
      document.getElementById("popupTitle").innerHTML = "";
      document.getElementById("popupDate").innerHTML = "";
      document.getElementById("popupText").innerHTML = "";
      document.getElementById("popupImg").src = "";

      document.getElementById("popupTitle").innerText = popupTitle;
      document.getElementById("popupDate").innerText = popupDate;

      document.getElementById("popupType").innerHTML = "";
      document.getElementById("popupType").innerText = "upload";

      document.getElementById("popupBtn").style.display = "none";
      document.getElementById("popupName").style.display = "initial";
      document.getElementById("popupFooter").style.display = "none";
      document.getElementById("imageCol").style.display = "initial";
      document.getElementById("contactFieldAushangHome").style.display =
        "block";

      $("#popup").modal("show");
    },
    getEntries() {
      var ref = this;
      ref.aushangArray = [];
      var axios = require("axios");
      var data = JSON.stringify({
        username: "web163_2",
        pw: "Pf0rzelona",
        request: "get_aushang_home",
      });

      var config = {
        method: "post",
        url: "https://vs-pforzheim.de/getHandler.php",
        headers: {
          "Content-Type": "this/json",
        },
        data: data,
      };

      return axios(config)
        .then(function(response) {
          response.data.forEach((element, index) => {
            if (element["type"] == "genehmigt") {
              var tmpArray = new Map();
              tmpArray.set("id", element["id"]);
              tmpArray.set("title", element["title"]);
              tmpArray.set("date", element["date"]);
              tmpArray.set("text", element["text"]);
              tmpArray.set("image", element["image"]);

              ref.aushangArray.push(tmpArray);
            }
          });
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    loadEntries() {
      var entries = 0;
      var ref = this;

      ref.aushangArray.forEach((element, index) => {
        var needDetails = false;
        var isFirstElement = false;
        if (index == 0) {
          isFirstElement = true;
        }
        ref.createEntry(element, needDetails, isFirstElement);
        entries += 1;
      });
    },
    createEntry(array, needDetails, isFirstElement) {
      this.groupnumber += 1;
      let ref = this;
      if (this.groupnumber % this.maxGroupLength == 0 || isFirstElement) {
        this.groupIndex += 1;
        var item = document.createElement("div");
        item.classList.add("carousel-item");

        if (isFirstElement) {
          item.classList.add("active");
        }

        item.id = "aushangHomeItem" + this.groupIndex;
        document.getElementById("aushangHome").appendChild(item);

        var flexbox = document.createElement("div");
        flexbox.style.cssText =
          "display: flex !important; flex-direction: row; align-items: stretch; flex-wrap: wrap; gap: 10px; padding: 50px;";
        flexbox.id = "aushangHomeItemFlexbox" + this.groupIndex;
        document
          .getElementById("aushangHomeItem" + this.groupIndex)
          .appendChild(flexbox);
      }

      var div = document.createElement("div");
      div.classList.add("aushang-box");
      div.style.textAlign = "left";
      div.style.padding = "10px";
      div.id = "aushangHomeBox" + array.get("id");
      div.style.cssText =
        "text-align:left; height: 100%; width: 100%;  flex: 1 0 33%; background-color: white; position: relative; justify-content: left; border-radius: 10px; -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.75); -moz-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.75); box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);";
      document
        .getElementById("aushangHomeItemFlexbox" + this.groupIndex)
        .appendChild(div);

      var title = document.createElement("h2");
      var heading = document.createTextNode(array.get("title"));
      title.appendChild(heading);
      title.style.cssText =
        "z-index: 2; opacity: 1; position: relative; left: 20px; text-align: left; font-weight: 500; font-style: normal; color: var(--primary-orange); font-size: 40px; top: 15px;";
      document
        .getElementById("aushangHomeBox" + array.get("id"))
        .appendChild(title);

      var date = document.createElement("h6");
      date.style.margin = "5px 0px 0px 20px";
      date.classList.add("text-muted");
      date.style.cssText =
        "z-index: 2; opacity: 1; position: relative; left: 20px; text-align: left; top: 15px;";
      var dateText = document.createTextNode(array.get("date"));
      date.appendChild(dateText);
      document
        .getElementById("aushangHomeBox" + array.get("id"))
        .appendChild(date);

      var p = document.createElement("p");
      p.style.fontSize = "20px";
      p.style.textAlign = "justify";
      p.style.padding = "20px";
      var text = array.get("text");
      if (text.length > this.maxTextLength) {
        text = array.get("text").substring(0, this.maxTextLength) + "...";
        needDetails = true;
      }
      if (array.get("image") != "") {
        needDetails = true;
      }
      var pText = document.createTextNode(text);
      p.appendChild(pText);
      document
        .getElementById("aushangHomeBox" + array.get("id"))
        .appendChild(p);

      var kontaktBtn = document.createElement("div");
      kontaktBtn.id = "homeAushangKontaktBtn" + array.get("id");
      kontaktBtn.classList = "btn";
      kontaktBtn.style.cssText =
        "background-color: var(--primary-orange); color: white; transition: all 0.5s; z-index: 2; width: 100px; margin: 10px;border-radius: 40px;";
      kontaktBtn.textContent = "Kontakt";
      document
        .getElementById("aushangHomeBox" + array.get("id"))
        .appendChild(kontaktBtn);
      document
        .getElementById("homeAushangKontaktBtn" + array.get("id"))
        .addEventListener("click", function() {
          ref.startContact(array.get("id"));
        });

      if (needDetails) {
        var detailsBtn = document.createElement("div");
        detailsBtn.classList = "btn";
        detailsBtn.id = "homeAushangDetailsBtn" + array.get("id");

        detailsBtn.style.cssText =
          "background-color: var(--primary-orange); color: white; transition: all 0.5s; z-index: 2; width: 100px; border-radius: 40px;";
        detailsBtn.textContent = "Details";
        document
          .getElementById("aushangHomeBox" + array.get("id"))
          .appendChild(detailsBtn);
        document
          .getElementById("homeAushangDetailsBtn" + array.get("id"))
          .addEventListener("click", function() {
            ref.showDetails(array.get("id"));
          });
      }
    },
    showDetails(id) {
      var array;
      this.aushangArray.forEach((element) => {
        if (element.get("id") == id) {
          array = element;
        }
      });
      let popupTitle = array.get("title");
      let popupDate = array.get("date");
      let popupText = array.get("text");
      let popupImg = array.get("image");

      document.getElementById("popupTitle").innerHTML = "";
      document.getElementById("popupDate").innerHTML = "";
      document.getElementById("popupText").innerHTML = "";
      document.getElementById("popupImg").src = "";
      document.getElementById("popupId").innerHTML = "";
      document.getElementById("popupId").innerHTML = id;
      document.getElementById("contactFieldAushangHome").style.display = "none";
      document.getElementById("popupBtn").style.display = "block";

      document.getElementById("popupTitle").innerText = popupTitle;
      document.getElementById("popupDate").innerText = popupDate;
      document.getElementById("imageCol").style.display = "none";
      document.getElementById("popupFooter").style.display = "none";

      document.getElementById("popupText").innerText = popupText;
      document.getElementById("popupImg").src =
        "https://vs-pforzheim.de/" + popupImg;

      if (popupImg == "") {
        document.getElementById("popupImg").style.display = "none";
      }

      $("#popup").modal("show");
    },
    startContact(id) {
      var array;
      this.aushangArray.forEach((element) => {
        if (element.get("id") == id) {
          array = element;
        }
      });
      let popupTitle = array.get("title");
      let popupDate = array.get("date");

      document.getElementById("popupTitle").innerHTML = "";
      document.getElementById("popupDate").innerHTML = "";
      document.getElementById("popupText").innerHTML = "";
      document.getElementById("popupImg").src = "";

      document.getElementById("popupTitle").innerText = popupTitle;
      document.getElementById("popupDate").innerText = popupDate;

      document.getElementById("popupType").innerHTML = "";
      document.getElementById("popupType").innerText = "contact";

      document.getElementById("popupId").innerHTML = "";
      document.getElementById("popupId").innerHTML = id;

      document.getElementById("popupTitel").style.display = "none";
      document.getElementById("popupName").style.display = "block";

      document.getElementById("popupBtn").style.display = "none";
      document.getElementById("imageCol").style.display = "none";
      document.getElementById("popupFooter").style.display = "none";

      document.getElementById("contactFieldAushangHome").style.display =
        "block";

      $("#popup").modal("show");
    },
  },
};
</script>

<style scoped>
@import url("https://use.typekit.net/kve4mnq.css");

.aushang {
  /* background-image: url('../../assets/backgrounds/AushangBG.png');
    background-repeat: no-repeat;
    background-position-x: -50px;
    background-size: 120%; */
  /* background-color: var(--secondary-pastelle); */
  margin-top: 200px;
}

.btn {
  transition: 0.3s !important;
  background-color: var(--primary-green);
  color: white;
  width: 300px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 20px;
  margin: 20px 0px 00px 0px;
}
.btn:hover {
  background-color: white;
  color: var(--primary-green);
}

#carousel-aushang-indicators {
  padding-left: 150px;
  padding-right: 150px;
  margin-top: -20px;
}

.carousel-indicators {
  bottom: 100px;
}

.carousel-item {
  display: flex !important;
  flex-direction: row;
  align-items: stretch;
  flex-wrap: wrap;
  gap: 10px;
  padding: 50px;
}

.aushang-box {
  height: 100%;
  width: 100%;
  min-height: 350px;
  flex: 1 0 33%;
  background-color: white;
  position: relative;
  justify-content: left;
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
}

p {
  z-index: 2;
  opacity: 1;
  position: absolute;
  top: 70px;
  left: 20px;
  text-align: left;
  font-weight: 500;
  font-style: normal;
  color: var(--primary-orange);
  font-size: 40px;
  top: 15px;
}

.aushang-image {
  height: 100%;
  width: 100%;
  min-height: 350px;
  /* background-image: url('../../assets/bilder/aushangElement.png'); */
  opacity: 0.2;
  background-size: 100%;
}

.carousel-control-prev {
  margin-left: 75px;
  width: 100px;
}

.carousel-control-next {
  margin-right: 75px;
  width: 100px;
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='orange' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='orange' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
}

.carousel-indicators .active {
  background-color: var(--primary-orange);
}

.carousel-indicators li {
  background-color: var(--primary-green);
}
</style>
