<template>
	<div class="technik">

		<div id="technik1"></div>
		<PopUp></PopUp>
		<Technik1 class="Technik1"></Technik1>
		<Technik2 class="Technik2"></Technik2>

	</div>
</template>

<script>
	import Technik1 from '@/views/fachschaften/Technik1.vue'
	import Technik2 from '@/views/fachschaften/Technik2.vue'
	import PopUp from "../../PopUp.vue"

	import $ from "jquery";

	export default {
		components: {
			Technik1,
			PopUp,
			Technik2,
		}
	}
</script>

<style scoped>
	.technik {
		/* background-image: url('../../assets/backgrounds/TechnikBG.png'); */
		background-repeat: no-repeat;
		/* background-position-y: -2px;
		padding: 50px 50vw 0 200px; */
		text-align: left;
		position: relative;
	}

	@media only screen and (max-width: 1100px) {
		.Technik1 {
			display: none;
		}
	}

	@media only screen and (min-width: 1101px) {
		.Technik2 {
			display: none;
		}
	}
</style>