<template>
  <div>
    <div
      class="heading"
      id="veranstaltungen2"
      data-aos="flip-up"
      data-aos-duration="1500"
      data-aos-delay="200"
    >
      <h2 style="color: var(--asta-1)">
        Jedes Semester organisiert der AStA altbewährte und neue Veranstaltungen
        für die Studis.
      </h2>
    </div>
    <div
      class="card"
      data-aos="flip-up"
      data-aos-duration="1500"
      data-aos-delay="500"
    >
      <div class="card-body">
        <div class="row">
          <div class="col">
            <div
              class="list-group"
              id="veranstaltungenMenu2"
              role="tablist"
            ></div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="tab-content" id="veranstaltungenContent2"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
export default {
  name: "Veranstaltungen",
  data: {
    veranstaltungenArray: [],
  },
  mounted: async function() {
    let ref = this;
    await this.getEntries();
    this.loadEntries();
  },
  methods: {
    getEntries() {
      var ref = this;
      ref.veranstaltungenArray = [];
      var axios = require("axios");
      var data = JSON.stringify({
        username: "web163_2",
        pw: "Pf0rzelona",
        request: "get_veranstaltungen_asta",
      });

      var config = {
        method: "post",
        url: "https://vs-pforzheim.de/getHandler.php",
        headers: {
          "Content-Type": "this/json",
        },
        data: data,
      };

      return axios(config)
        .then(function(response) {
          response.data.forEach((element, index) => {
            var tmpArray = new Map();
            tmpArray.set("id", element["id"]);
            tmpArray.set("menu", element["menu"]);
            tmpArray.set("text", element["text"]);
            tmpArray.set("link", element["link"]);
            tmpArray.set("linkText", element["linkText"]);
            tmpArray.set("image", element["image"]);
            tmpArray.set("video", element["video"]);
            ref.veranstaltungenArray.push(tmpArray);
          });
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    loadEntries() {
      let ref = this;
      ref.veranstaltungenArray.forEach((element, index) => {
        var isFirstElement = false;
        if (index == 0) {
          isFirstElement = true;
        }

        var menuItem = document.createElement("a");
        menuItem.classList.add("list-group-item");
        menuItem.classList.add("list-group-item-action");
        menuItem.setAttribute("data-toggle", "list");
        menuItem.setAttribute("role", "tab");
        menuItem.style.color = "var(--asta-1)";
        menuItem.style.fontSize = "10px";
        menuItem.href = "#textVeranstaltungenAsta2" + element.get("id");
        if (isFirstElement) {
          menuItem.classList.add("active");
          menuItem.style.color = "white";
          menuItem.style.backgroundColor = "var(--asta-1)";
          menuItem.style.borderColor = "var(--asta-1)";
        }
        menuItem.id = "menuVeranstaltungenAsta2" + element.get("id");
        document.getElementById("veranstaltungenMenu2").appendChild(menuItem);

        var menuText = document.createElement("h5");
        var menuTextInsert = document.createTextNode(element.get("menu"));
        menuText.appendChild(menuTextInsert);
        document
          .getElementById("menuVeranstaltungenAsta2" + element.get("id"))
          .appendChild(menuText);

        document
          .getElementById("menuVeranstaltungenAsta2" + element.get("id"))
          .addEventListener("click", function() {
            ref.veranstaltungenArray.forEach((changeElementColor, index) => {
              document.getElementById(
                "menuVeranstaltungenAsta2" + changeElementColor.get("id")
              ).style.color = "var(--asta-1)";
              document.getElementById(
                "menuVeranstaltungenAsta2" + changeElementColor.get("id")
              ).style.backgroundColor = "white";
              document.getElementById(
                "menuVeranstaltungenAsta2" + changeElementColor.get("id")
              ).style.borderColor = "lightgray";
            });
            document.getElementById(
              "menuVeranstaltungenAsta2" + element.get("id")
            ).style.color = "white";
            document.getElementById(
              "menuVeranstaltungenAsta2" + element.get("id")
            ).style.backgroundColor = "var(--asta-1)";
            document.getElementById(
              "menuVeranstaltungenAsta2" + element.get("id")
            ).style.borderColor = "var(--asta-1)";
          });

        var contentItem = document.createElement("div");
        contentItem.classList.add("tab-pane");
        contentItem.classList.add("fade");
        contentItem.classList.add("tab");
        contentItem.setAttribute("role", "tabpanel");
        contentItem.style.fontSize = "20px";
        contentItem.style.textAlign = "justify";
        if (isFirstElement) {
          contentItem.classList.add("active");
          contentItem.classList.add("show");
        }
        contentItem.id = "textVeranstaltungenAsta2" + element.get("id");
        document
          .getElementById("veranstaltungenContent2")
          .appendChild(contentItem);

        var row = document.createElement("div");
        row.classList.add("row");
        row.id = "textVeranstaltungenAsta2Row" + element.get("id");
        document
          .getElementById("textVeranstaltungenAsta2" + element.get("id"))
          .appendChild(row);

        var col8 = document.createElement("div");
        col8.classList.add("col-12");
        col8.id = "textVeranstaltungenAsta2Col8" + element.get("id");
        document
          .getElementById("textVeranstaltungenAsta2Row" + element.get("id"))
          .appendChild(col8);

        var col4 = document.createElement("div");
        col4.classList.add("col-12");
        col4.style.textAlign = "center";
        col4.id = "textVeranstaltungenAsta2Col4" + element.get("id");
        document
          .getElementById("textVeranstaltungenAsta2Row" + element.get("id"))
          .appendChild(col4);

        if (element.get("image") != "") {
          var img = document.createElement("img");
          if ($(window).width() > 550) {
            img.style.cssText = " max-height: 400px; max-width:60vw";
          } else {
            img.style.cssText = " max-height: 400px; max-width:30vw";
          }
          img.src = "https://vs-pforzheim.de/" + element.get("image");
          document
            .getElementById("textVeranstaltungenAsta2Col4" + element.get("id"))
            .appendChild(img);
        }
        if (element.get("video") != "") {
          var vid = document.createElement("iframe");
          vid.style.cssText =
            "width: 100%; margin-left: auto; margin-right: auto; height: 350px";
          vid.frameBorder = "0";
          vid.src = element.get("video");
          document
            .getElementById("textVeranstaltungenAsta2Col4" + element.get("id"))
            .appendChild(vid);
        }

        var p = document.createElement("p");
        p.id = "textVeranstaltungenAsta2p" + element.get("id");
        p.style.paddingRight = "20px";
        var pText = document.createTextNode(element.get("text"));
        p.appendChild(pText);
        document
          .getElementById("textVeranstaltungenAsta2Col8" + element.get("id"))
          .appendChild(p);

        var link = document.createElement("a");
        link.href = element.get("link");
        link.target = "_blank";
        link.style.paddingRight = "20px";
        link.innerHTML = element.get("linkText");
        link.style.color = "var(--asta-1)";
        document
          .getElementById("textVeranstaltungenAsta2Col8" + element.get("id"))
          .appendChild(link);
      });
    },
  },
};
</script>

<style scoped>
.list-group {
  /* width: 300px; */
}

.card {
  margin: 50px 100px 50px 100px;
  border-radius: 20px;
  -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  background-color: white;
}

.heading {
  margin: 100px 75px 0px 75px;
  border-radius: 20px;
}

h3 {
  color: #333333;
  font-size: 30px;
}

.nav-link {
  font-size: 20px;
}

@media only screen and (max-width: 1085px) {
  .tab-content {
    min-width: 400px;
  }
}

@media only screen and (max-width: 650px) {
  .tab-content {
    min-width: 300px;
  }
}

@media only screen and (max-width: 530px) {
  .tab-content {
    min-width: 250px;
  }
}
@media only screen and (max-width: 500px) {
  .tab-content {
    min-width: 150px;
  }
  .card {
    margin: 50px 75px 50px 75px;
  }
}
@media only screen and (max-width: 380px) {
  .tab-content {
    min-width: 150px;
  }
}
</style>
