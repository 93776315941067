<template>
  <div class="kulturflat" data-aos="fade-right" data-aos-duration="300">
    <div class="videoContainer">
      <video
        src="https://vs-pforzheim.de/upload/Kulturflat V1.mp4"
        loop
        autoplay
        muted
        controls
      ></video>
    </div>
    <div class="flatContainer">
      <div
        class="textContainer"
        data-aos="fade-left"
        data-aos-duration="500"
        data-aos-delay="300"
      >
        <h1 data-aos="fade-left" data-aos-duration="500" data-aos-delay="200">
          Die Kulturflat - Dein Gutscheincode
        </h1>
        <div class="pContainer">
          <p>
            Du möchtest Kultur in Pforzheim erleben?<br />
            Dann ist die Kulturflat genau das Richtige für dich!
          </p>
          <p>
            Gemeinsam mit der Hochschule sind der Stura und AStA Sponsoren der
            Kulturflat. Hiermit könnt ihr mithilfe eines Zugangscodes
            ausgewählte Veranstaltungen in Pforzheim genießen.
          </p>
          <p>
            <strong> Wie oft kann ich den Code einlösen?<br /> </strong>
            Jeder Code lässt sich nur 1-mal einlösen. Ihr könnt euch aber
            unbegrenzt Codes generieren.
          </p>
          <p>
            <strong>
              Wo kann ich den Code einlösen?
              <br />
            </strong>
            In diesen Einrichtungen:
          </p>
        </div>
        <div class="mailContainer justify-content-center">
          <form id="kulturflatForm" onSubmit="return false;">
            <input
              id="flatMail"
              type="text"
              class="form-control"
              placeholder="Deine Hochschul-Email"
              aria-label="Hochschul-Email"
              aria-describedby="basic-addon1"
            />
          </form>
          <div class="btn" v-on:click="checkForm()">CODE GENERIEREN</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import axios from "axios";
export default {
  name: "Kulturflat",
  mounted() {},
  methods: {
    checkForm() {
      let mail = document.getElementById("flatMail").value;
      // if (mail.includes("hs-pforzheim.de")) {
      //   var substr = mail.split("@")[0];
      //   if (substr.includes(".")) {
      //     $('#flatMailProfError').modal('toggle')
      //     return
      //   }
      //   axios.get(
      //       "https://vs-pforzheim.de/mailer.php?mail=" + mail
      //     )
      //     .catch(function (error) {
      //       $('#flatMailError').modal('toggle')
      //     })
      //     .then(response => {
      //       if (response.data == "Message has been sent successfully") {
      //         $('#flatMailSubmit').modal('toggle')
      //       }
      //     })
      // } else {
      $("#flatMailError").modal("toggle");
      // }
    },
  },
};
</script>

<style scoped>
.kulturflat {
  height: 1000px;
  position: relative;
  margin-top: 150px;
}
.bolder {
  font-weight: bolder;
}

video {
  width: 45vw;
  bottom: 150px;
  left: 100px;
  position: absolute;
  z-index: 10;
  -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  /* border-radius: 20px; */
}

.videoContainer {
  height: 100%;
  position: absolute;
  width: 100%;
  /* background-color: var(--secondary-green); */
}

.flatContainer {
  width: 100%;
  position: absolute;
  right: 0;
  height: 100%;
}

h1 {
  color: black;
  padding-top: 30px;
  text-align: right;
  font-family: var(--font-playfair);
  font-weight: bolder;
}

.textContainer {
  float: right;
  text-align: right;
  margin-right: 50px;
  width: 45%;
}

p {
  margin-top: 30px;
  color: black;
  font-size: 20px;
}

.mailContainer {
  padding: 0px 0px 0px 50px;
  width: 50%;
  float: right;
}

#flatMail {
  height: 60px;
}

.btn {
  background-color: var(--yellow);
  color: white;
  font-size: 20px;
  font-weight: bolder;
  width: 100%;
  margin-top: 20px;
  border-radius: 50px;
  padding: 25px 0px;
}

input {
  margin-top: 30px;
  border-radius: 20px;
  border-color: lightgray;
}

.modal-backdrop {
  /* bug fix - no overlay */
  display: none;
}

@media only screen and (max-width: 1520px) {
  video {
    bottom: 50px;
  }
}

@media only screen and (min-width: 3021px) {
  video {
    bottom: 10px;
  }
}

@media only screen and (max-width: 1720px) {
  p {
    width: 450px;
    float: right;
  }
}
</style>
