<template>
  <div class="kontaktStura">
    <emailPopUp></emailPopUp>
    <div id="kontaktStura"></div>
    <div class="row containerKontaktStura">
      <div
        class="col card"
        data-aos="flip-right"
        data-aos-duration="1500"
        data-aos-delay="700"
      >
        <form onsubmit="return false">
          <div class="form-row">
            <div class="form-group col">
              <label for="sturaContactName">Name</label>
              <input
                type="sturaContactName"
                class="form-control"
                id="sturaContactName"
                aria-describedby="nameHelp"
                placeholder="Gib deinen Namen ein"
              />
              <small id="emailHelp" class="form-text text-muted"></small>
            </div>
            <div class="form-group col">
              <label for="sturaContactMail">E-Mail</label>
              <input
                type="sturaContactMail"
                class="form-control"
                id="sturaContactMail"
                aria-describedby="emailHelp"
                placeholder="Gib deine E-Mail ein"
              />
              <small id="emailHelp" class="form-text text-muted"></small>
            </div>
          </div>
          <div class="form-group">
            <label for="sturaContactMessage">Deine Nachricht</label>
            <textarea
              type="text-area"
              class="form-control"
              id="sturaContactMessage"
              aria-describedby="MessageHelp"
              placeholder="Gib deine Nachricht ein"
            ></textarea>
            <small id="MessageHelp" class="form-text text-muted"></small>
          </div>
          <button @click="sendMessage()" class="btn">Absenden</button>
        </form>
      </div>
      <div
        class="col text1"
        id="kontaktSturaText1"
        data-aos="fade-left"
        data-aos-duration="1500"
        data-aos-delay="700"
      >
        <h1>
          Schreib uns eine Nachricht!
        </h1>
        <p>
          Wir antworten dir sobald wie möglich.
          <br /><br />
        </p>
        <p id="time">
          Oder schreib uns eine Mail! An:<br />
          <a href="mailto:Studierendenrat@hs-pforzheim.de"
            ><strong>Studierendenrat@hs-pforzheim.de</strong></a
          >
        </p>
      </div>
    </div>
    <div
      class="col text1"
      id="kontaktSturaText2"
      data-aos="fade-left"
      data-aos-duration="1500"
      data-aos-delay="700"
    >
      <h1>
        Schreib uns eine Nachricht!
      </h1>
      <p>
        Wir antworten dir sobald wie möglich.
        <br /><br />
      </p>
      <p id="time">
        Oder schreib uns eine Mail! An:<br />
        <a href="mailto:Studierendenrat@hs-pforzheim.de"
          ><strong>Studierendenrat@hs-pforzheim.de</strong></a
        >
      </p>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import emailPopUp from "../../EmailPopUp.vue";

export default {
  components: {
    emailPopUp,
  },
  data: {
    receiver: "",
    name: "",
    mail: "",
    message: "",
  },
  methods: {
    loadData() {
      this.receiver = "studierendenrat@hs-pforzheim.de";
      this.name = document.getElementById("sturaContactName").value;
      this.mail = document.getElementById("sturaContactMail").value;
      this.message = document.getElementById("sturaContactMessage").value;
    },
    async sendMessage() {
      await this.loadData();

      var axios = require("axios");

      var data = JSON.stringify({
        request: "kontaktformular",
        name: this.name,
        mail: this.mail,
        receiver: this.receiver,
        message: this.message,
      });

      var config = {
        method: "post",
        url: "https://vs-pforzheim.de/mailer.php",
        headers: {
          "Content-Type": "this/json",
        },
        data: data,
      };
      return axios(config)
        .then(function(response) {
          if (response.data == "Message has been sent successfully") {
            document.getElementById("emailPopUpTitle").innerText =
              "Nachricht versendet!";
            document.getElementById("emailPopUpBody").innerText =
              "Wir bearbeiten deine Anfrage und antworten dir so schnell wie möglich! Unter Umständen können E-Mails auch in deinem Spam landen!";
            document.getElementById("emailPopUpBtn").style.backgroundColor =
              "var(--asta-1)";
            document.getElementById("emailPopUpBtn").style.color = "white";
            $("#emailPopUp").modal("show");
          } else {
            document.getElementById("emailPopUpTitle").innerText =
              "Ups, hier ist etwas schief gelaufen!";
            document.getElementById("emailPopUpBody").innerText =
              "Auch unsere Technik hat den einen oder anderen Fehler! Bitte sende uns eine Mail an: test.XXX@xxx.de!";
            document.getElementById("emailPopUpBtn").style.backgroundColor =
              "var(--asta-1)";
            document.getElementById("emailPopUpBtn").style.color = "white";
            $("#emailPopUp").modal("show");
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
.kontaktStura {
  text-align: center;
  /* background-image: url('../../assets/backgrounds/KontaktAstaBG.png'); */
  margin-bottom: 150px;
  background-repeat: no-repeat;
}

.card {
  padding: 20px;
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
}

.containerKontaktStura {
  padding: 150px 170px 50px 170px;
}

h1 {
  color: var(--primary-green);
  text-align: center;
  padding-left: 20px;
}

p {
  font-size: 20px;
  color: var(--primary-green);
  text-align: center;
  padding-left: 20px;
}

a {
  color: var(--primary-orange);
}

#time {
  font-size: 25px;
  color: var(--primary-green);
  text-align: center;
  padding-left: 20px;
}

.btn {
  background-color: var(--primary-green);
  color: white;
  transition: all 0.5s;
  border-radius: 40px;
}

@media only screen and (max-width: 1280px) {
  #kontaktSturaText1 {
    display: none;
  }

  .col {
    min-width: 300px;
  }
}

@media only screen and (max-width: 650px) {
  .containerKontaktStura {
    padding: 150px 70px 50px 70px;
  }

  a {
    margin-left: auto;
    margin-right: auto;
  }

  .col {
    min-width: 200px;
  }
}

@media only screen and (min-width: 1281px) {
  #kontaktSturaText2 {
    display: none;
  }
}

.containerKontaktMapsStura {
  padding: 100px 140px 300px 160px;
}

iframe {
  border-radius: 10px;
  height: 300px;
  -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
}

.text2 {
  margin-top: 70px;
}

.text1 {
  margin-top: 40px;
}
</style>
