<template>
  <div class="grid">
    <div class="overlay" id="overlay"></div>
    <div class="spanner" id="spanner">
      <div class="loader"></div>
    </div>
    <div
      class="modal fade"
      id="grillpopup"
      tabindex="1"
      style=""
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <table>
              <tr>
                <td>
                  <h3 class="modal-title" id="popupTitle">
                    Grillplatz Reservierung
                  </h3>
                </td>
              </tr>
              <tr>
                <td>
                  <h5 class="text-muted" id="popupDate"></h5>
                </td>
              </tr>
            </table>

            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div id="contactFieldAushangHome">
              <form onsubmit="return false">
                <div class="form-row">
                  <div class="form-group col" id="popupName">
                    <label for="name">Name</label>
                    <input
                      type="name"
                      class="form-control"
                      id="name"
                      aria-describedby="nameHelp"
                      placeholder="Gib deinen Namen ein"
                    />
                    <small id="emailHelp" class="form-text text-muted"></small>
                  </div>
                  <div class="form-group col" id="popupCreatorEmail">
                    <label for="email">E-Mail</label>
                    <input
                      type="email"
                      class="form-control"
                      id="email"
                      aria-describedby="emailHelp"
                      placeholder="Gib deine E-Mail ein"
                    />
                    <small id="emailHelp" class="form-text text-muted"></small>
                  </div>
                  <div class="form-group col" id="popupMatNr">
                    <label for="matnr">Matrikelnummer</label>
                    <input
                      type="matnr"
                      class="form-control"
                      id="matnr"
                      aria-describedby="emailHelp"
                      placeholder="Gib deine Matrikelnummer ein"
                    />
                    <small id="emailHelp" class="form-text text-muted"></small>
                  </div>
                  <div class="form-group col" id="popupMatNr">
                    <label for="tel">Telefonnummer</label>
                    <input
                      type="tel"
                      class="form-control"
                      id="tel"
                      aria-describedby="emailHelp"
                      placeholder="Gib deine Telefonnummer ein"
                    />
                    <small id="emailHelp" class="form-text text-muted"></small>
                  </div>
                  <div class="form-group col" id="popupMatNr">
                    <label for="strasse">Straße</label>
                    <input
                      type="strasse"
                      class="form-control"
                      id="strasse"
                      aria-describedby="emailHelp"
                      placeholder="Gib deine Straße deiner Anschrift ein"
                    />
                    <small id="emailHelp" class="form-text text-muted"></small>
                  </div>
                  <div class="form-group col" id="popupMatNr">
                    <label for="city">Wohnort</label>
                    <input
                      type="city"
                      class="form-control"
                      id="city"
                      aria-describedby="emailHelp"
                      placeholder="Gib deine die PLZ und den Ort deiner Anschrift ein"
                    />
                    <small id="emailHelp" class="form-text text-muted"></small>
                  </div>
                  <div class="form-group col" id="popupMatNr">
                    <label for="teilnehmer">Teilnehmerzahl</label>
                    <input
                      type="teilnehmer"
                      class="form-control"
                      id="teilnehmer"
                      aria-describedby="emailHelp"
                      placeholder="Gib die Anzahl an Teilnehmern ein"
                    />
                    <small id="emailHelp" class="form-text text-muted"></small>
                  </div>
                  <div class="form-group col" id="popupStartTime">
                    <label for="start">Start</label>
                    <input type="time" id="start" name="appt" />
                  </div>
                  <div class="form-group col" id="popupEndTime">
                    <label for="end">Ende</label>
                    <input type="time" id="end" name="appt" />
                  </div>
                  <div class="form-group col" id="popupServiceDate">
                    <label for="date">Datum</label>
                    <datepicker
                      :language="de"
                      id="date"
                      bootstrap-styling="true"
                    ></datepicker>
                  </div>
                </div>

                <div class="text-center">
                  <p class="text-muted">
                    Mit dem Absenden bestätige ich die Weitergabe meiner
                    Kontaktdaten für den Kontakt.
                  </p>
                  <button
                    v-on:click="startUpload()"
                    class="btn "
                    id="submitBtnPopup"
                  >
                    Absenden
                  </button>
                </div>
              </form>
            </div>
            <div class="row">
              <p id="popupText"></p>
              <p id="popupEmail"></p>
              <img id="popupImg" src="" />
            </div>
            <p id="popupId" style="display: none"></p>
            <p id="popupType" style="display: none"></p>
            <a @click="startContact()" type="button" class="btn" id="popupBtn"
              >Jetzt kontaktieren!</a
            >
          </div>
          <div class="modal-footer" id="popupFooter">
            <a type="button" id="popupButton" class="btn" data-dismiss="modal"
              >Okay</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import Datepicker from "vuejs-datepicker";
import { de } from "vuejs-datepicker/dist/locale";
export default {
  components: {
    Datepicker,
  },
  data() {
    return {
      de: de,
      file: "",
      errorAlert: false,
      uploadedImage: "",
      Image: "",
      DB: "",
      mail: "",
      receiver: "",
      message: "",
      subject: "",
      name: "",
      request: "",
      matnr: "",
      teilnehmer: "",
      time: "",
      date: "",
      phone: "",
    };
  },
  mounted() {
    this.file = "";
    this.errorAlert = false;
    this.uploadedImage = "";
    this.Image = "";
    this.DB = "";
    this.mail = "";
    this.receiver = "";
    this.message = "";
    this.subject = "";
    this.request = "";
    this.name = "";

    this.matnr = "";
    this.teilnehmer = "";
    this.time = "";
    this.date = "";
    this.phone = "";
  },
  methods: {
    submit: function() {
      this.startUpload();
    },
    async startUpload() {
      await this.uploadData();
    },
    uploadData() {
      let DB = "newsFach";
      let tel = document.getElementById("tel").value;
      let strasse = document.getElementById("strasse").value;
      let matnr = document.getElementById("matnr").value;
      this.matnr = matnr;

      let city = document.getElementById("city").value;
      let teilnehmer = document.getElementById("teilnehmer").value;
      this.teilnehmer = teilnehmer;

      let name = document.getElementById("name").value;

      let phone = document.getElementById("tel").value;
      this.phone = phone;

      let email = document.getElementById("email").value;
      var date = document.getElementById("date").value;

      var month = "";
      month = date.substr(3, 3);
      switch (month) {
        case "Jan":
          month = "01";
          break;
        case "Feb":
          month = "02";
          break;
        case "Mär":
          month = "03";
          break;
        case "Apr":
          month = "04";
          break;
        case "Mai":
          month = "05";
          break;
        case "Jun":
          month = "06";
          break;
        case "Jul":
          month = "07";
          break;
        case "Aug":
          month = "08";
          break;
        case "Sep":
          month = "09";
          break;
        case "Okt":
          month = "10";
          break;
        case "Nov":
          month = "11";
          break;
        case "Dez":
          month = "12";
          break;

        default:
          break;
      }
      var dateString =
        date.substr(0, 2) + "." + month + "." + date.substr(7, 10);
      this.date = dateString;
      let time =
        "Von " +
        document.getElementById("start").value +
        " - " +
        document.getElementById("end").value +
        " Uhr";

      this.time = time;
      if (email == "") {
        alert("Bitte gib deine Mail an!");
        return;
      }
      if ((date = "")) {
        alert("Bitte gib das Datum deiner Reservierung an!");
        return;
      }
      if ((matnr = "")) {
        alert("Bitte gib deine Matrikelnummer an!");
        return;
      }
      if ((this.name = "")) {
        alert("Bitte gib deinen Namen an!");
        return;
      }
      if ((document.getElementById("start").value = "")) {
        alert("Bitte gib die Startzeit an!");
        return;
      }
      if ((document.getElementById("end").value = "")) {
        alert("Bitte gib die Endzeit an!");
        return;
      }
      if ((tel = "")) {
        alert("Bitte gib deine Telefonnummer an!");
        return;
      }
      if ((strasse = "")) {
        alert("Bitte gib deine Straße an!");
        return;
      }
      if ((city = "")) {
        alert("Bitte gib deinen Wohnort an!");
        return;
      }
      if ((teilnehmer = "")) {
        alert("Bitte gib die Teilnehmerzahl an!");
        return;
      }

      this.receiver = email;
      this.name = name;
      // this.mail = "julian.zoller@mail-zoller.de"
      this.mail = "fachschaftsrat-t@hs-pforzheim.de";
      this.message = "";
      var ref = this;
      var request = "upload_grill";
      var axios = require("axios");
      var data = JSON.stringify({
        username: "web163_2",
        pw: "Pf0rzelona",
        request: request,
        matnr: document.getElementById("matnr").value,
        menu: "Kalender",
        name: ref.name,
        text: "",
        image: "",
        video: "",
        link_text: "",
        link: "",
        type: "waiting",
        title: "Grillplatz Reservierung",
        email_creator: email,
        time: time,
        date: dateString,
        upload_type: DB,
        id: "",
        city: document.getElementById("city").value,
        street: document.getElementById("strasse").value,
        teilnehmer: document.getElementById("teilnehmer").value,
        phone: document.getElementById("tel").value,
      });
      var config = {
        method: "post",
        url: "https://vs-pforzheim.de/UploadHandler.php",
        headers: {
          "Content-Type": "this/json",
        },
        data: data,
      };

      return axios(config)
        .then(function(response) {
          document.getElementById("contactFieldAushangHome").style.display =
            "none";
          document.getElementById("email").value = "";
          document.getElementById("date").value = "";
          document.getElementById("matnr").value = "";
          document.getElementById("name").value = "";
          document.getElementById("strasse").value = "";
          document.getElementById("city").value = "";
          document.getElementById("tel").value = "";
          document.getElementById("teilnehmer").value = "";
          document.getElementById("start").value = "";
          document.getElementById("end").value = "";
          document.getElementById("popupDate").innerText = "";
          document.getElementById("popupFooter").style.display = "block";
          document.getElementById("popupButton").style.display = "block";
          document.getElementById("popupButton").style.backgroundColor =
            "var(--primary-orange)";
          document.getElementById("popupButton").style.color = "white";
          if (response.data == "Message has been sent successfully") {
            document.getElementById("popupTitle").innerText =
              "Anfrage versendet!";
            document.getElementById("popupText").innerText =
              "Wir bearbeiten deine Anfrage und antworten dir so schnell wie möglich! Unter Umständen können E-Mails auch in deinem Spam landen!";
            ref.request = "createAushang";
            ref.sendMessage();
          } else {
            document.getElementById("popupTitle").innerText =
              "Ups, hier ist etwas schief gelaufen!";
            document.getElementById("popupText").innerText =
              "Auch unsere Technik hat den einen oder anderen Fehler! Bitte sende uns eine Mail an: Studierendenrat@hs-pforzheim.de!";
          }
        })
        .catch(function(error) {});
    },
    async sendEmail() {
      await this.getEmailData();
      this.sendMessage();
    },
    getEmailData() {
      // var ref = this;
      // this.mail = document.getElementById("email").value
      // this.message = document.getElementById("Message").value
      // this.name = document.getElementById("name").value
      // this.subject = document.getElementById("popupTitle").innerHTML
      // if (document.getElementById("popupEmail").innerText != "") {
      // 	this.request = "kontaktformular"
      // 	document.getElementById("popupButton").style.backgroundColor = "var(--asta-1)"
      // 	this.receiver = document.getElementById("popupEmail").innerText
      // } else {
      // 	this.request = "aushang"
      // 	document.getElementById("popupButton").style.backgroundColor = "var(--primary-orange)"
      // 	let id = document.getElementById("popupId").innerHTML
      // 	var axios = require('axios');
      // 	var data = JSON.stringify({
      // 		"username": "web163_2",
      // 		"pw": "Pf0rzelona",
      // 		"request": "get_aushang_home"
      // 	});
      // 	var config = {
      // 		method: 'post',
      // 		url: 'https://vs-pforzheim.de/getHandler.php',
      // 		headers: {
      // 			'Content-Type': 'this/json'
      // 		},
      // 		data: data
      // 	};
      // 	return axios(config)
      // 		.then(function (response) {
      // 			response.data.forEach((element, index) => {
      // 				if (element["id"] == id) {
      // 					ref.receiver = element["email"];
      // 				}
      // 			});
      // 		})
      // 		.catch(function (error) {
      // 		});
      // }
    },
    async sendMessage() {
      var axios = require("axios");

      var data = JSON.stringify({
        request: "grillplatzAnfrage",
        name: this.name,
        matnr: this.matnr,
        mail: this.mail,
        receiver: this.receiver,
        email_creator: email,
        time: this.time,
        date: this.date,
        teilnehmer: this.teilnehmer,
        phone: this.phone,
        subject: "Grillplatz Reservierung",
      });

      var config = {
        method: "post",
        url: "https://vs-pforzheim.de/mailer.php",
        headers: {
          "Content-Type": "this/json",
        },
        data: data,
      };
      return axios(config)
        .then(function(response) {
          document.getElementById("contactFieldAushangHome").style.display =
            "none";
          document.getElementById("popupDate").innerText = "";
          document.getElementById("popupFooter").style.display = "block";
          document.getElementById("popupButton").style.display = "block";
          document.getElementById("popupButton").style.color = "white";
          if (response.data == "Message has been sent successfully") {
            document.getElementById("popupTitle").innerText =
              "Nachricht versendet!";

            document.getElementById("popupText").innerText =
              "Wir bearbeiten deine Anfrage und antworten dir so schnell wie möglich! Unter Umständen können E-Mails auch in deinem Spam landen!";
          } else {
            document.getElementById("popupTitle").innerText =
              "Ups, hier ist etwas schief gelaufen!";
            document.getElementById("popupText").innerText =
              "Auch unsere Technik hat den einen oder anderen Fehler! Bitte sende uns eine Mail an: Studierendenrat@hs-pforzheim.de!";
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    startContact() {
      document.getElementById("popupText").innerHTML = "";
      document.getElementById("popupImg").src = "";
      document.getElementById("popupBtn").style.display = "none";
      document.getElementById("popupTitel").style.display = "none";
      document.getElementById("popupName").style.display = "block";

      document.getElementById("popupType").innerHTML = "";
      document.getElementById("popupType").innerText = "contact";

      document.getElementById("contactFieldAushangHome").style.display =
        "block";
    },

    uploadImage: function() {
      this.file = this.$refs.file.files[0];
      const filesize = this.file.size / 1000000;
      var maxFileSize = 1.5;
      const fileType = this.file.type;
      if (
        fileType == "image/jpeg" ||
        fileType == "image/png" ||
        fileType == "application/pdf"
      ) {
        if (fileType == "image/jpeg" || fileType == "image/png") {
          maxFileSize = 0.5;
        }
        if (filesize >= maxFileSize) {
          alert(
            "Datei ist zu groß! Bitte wähle eine Datei mit einer Größe von maximal 1,5 MB für PDF und 0,5 MB für Bilder!"
          );
        } else {
          $("div.spanner").addClass("show");
          $("div.overlay").addClass("show");
          var ref = this;
          var formData = new FormData();

          formData.append("file", this.file);

          var axios = require("axios");

          axios
            .post("https://vs-pforzheim.de/upload.php", formData, {
              header: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then(function(response) {
              if (response.data.image == "") {
                ref.errorAlert = true;
                ref.errorMessage = response.data.message;
                ref.successMessage = "";
                ref.uploadedImage = "";
                $("div.spanner").removeClass("show");
                $("div.overlay").removeClass("show");
              } else {
                ref.errorAlert = false;
                ref.errorMessage = "";
                ref.successMessage = response.data.message;
                ref.Image = response.data.image;
                if (ref.successMessage != "PDF Uploaded") {
                  document.getElementById("uploadedImage").innerHTML = "";
                  var image_html = document.createElement("img");
                  image_html.id = "uploadImage";
                  image_html.style.width = "300px";
                  image_html.src =
                    "https://vs-pforzheim.de/" + response.data.image;
                  document
                    .getElementById("uploadedImage")
                    .appendChild(image_html);
                }
                document.getElementById("thumb").style.display = "block";
                document.getElementById("YT").value = "";

                $("div.spanner").removeClass("show");
                $("div.overlay").removeClass("show");
                ref.$refs.file.value = "";
              }
            });
        }
      } else {
        alert(
          "Falsches Dateiformat! Zulässige Formate sind: JPG, JPEG, PNG oder PDF!"
        );
      }
    },
  },
};
</script>

<style scoped>
#contactFieldAushangHome {
  display: none;
}

#popupEmail {
  display: none;
}

#thumb {
  display: none;
}

td {
  text-align: left;
}

h3 {
  color: var(--primary-orange);
}

p {
  font-size: 20px;
  padding: 20px;
}

.modal-dialog {
  max-width: 90vw;
}

img {
  max-width: 30vw;
  margin: 20px;
  margin-left: auto;
  margin-right: auto;
}

.btn {
  background-color: var(--primary-orange);
  color: white;
  width: 300px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 20px;
}

.text-muted {
  margin-left: auto !important;
  margin-right: auto !important;
  font-size: 15px;
}

.text-center {
  text-align: center;
  margin-left: auto !important;
  margin-right: auto !important;
}

#popupText {
  text-align: justify;
}

.spanner {
  position: fixed;
  top: 50%;
  left: 0;
  width: 100%;
  display: block;
  text-align: center;
  height: 300px;
  color: #fff;
  transform: translateY(-50%);
  z-index: 1000;
  visibility: hidden;
  text-align: center;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  visibility: hidden;
}

.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}

.loader {
  color: #ffffff;
  font-size: 10px;
  margin: 80px auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

.loader:before,
.loader:after {
  content: "";
  position: absolute;
  top: 0;
}

.loader:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.loader:after {
  left: 3.5em;
}

@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }

  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }

  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

.show {
  visibility: visible;
}

.spanner,
.overlay {
  opacity: 0;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

.spanner.show,
.overlay.show {
  opacity: 1;
  z-index: 2000;
}

input,
label {
  display: block;
  margin: auto;
}

input {
  min-width: 300px;
}
</style>
