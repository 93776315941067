<template>
	<div class="wirtschaft">
		<h2 data-aos="flip-left" data-aos-duration="1500" data-aos-delay="1000">Fachschaften</h2>
		<h1 data-aos="flip-right" data-aos-duration="1500" data-aos-delay="700">
			Wirtschaft & Recht
		</h1>
		<p data-aos="flip-up" data-aos-duration="1500" data-aos-delay="1500">
			Aktuell sind wir <strong>zehn Studierende</strong> im Fachschaftsrat aus der <strong>Fakultät Wirtschaft & Recht</strong>.
			Unsere Aufgabe ist es, uns für <strong>Deine Belangen</strong> an der Hochschule einzusetzen und in den höheren
			Hochschulgremien (Fakultätsrat und Senat) einzubringen sowie Lösungen zu finden. Wir
			sind <strong>Dein Sprachrohr zur Hochschule</strong> sowie umgekehrt und pflegen den Kontakt mit Professoren, Dekanat, AStA,
			Studierendenrat, Fakultätsrat und weiteren Gremien. Über die Fachschaft sind wir auch gleichzeitig Teil der
			verfassten Studierendenschaft und machen uns für Dich bei der <strong>Verteilung der Qualitätssicherungsmittel (QSM)</strong>
			stark. Mit Hilfe dieser QSM werden unter anderem <strong>Tutorien, GastprofessorInnen, (Online-) Bibliotheken</strong> und
			weiteres finanziert, um die Lehre an der Hochschule für Dich zu verbessern.
			<br><br>
        	<a><router-link class="router-link" :to="{ name: 'Stura'}">Hier kommst Du zum StuRa</router-link></a><br><br>
			<!-- <strong>Du willst auch aktiv werden und das Hochschulleben mitgestalten?</strong><br><br>
			Dann nehme an den nächsten Gremienwahlen teil oder lass Dich aufstellen und helfe auch Du mit, den Alltag
			und die Bildung an der Hochschule zu verbessern. <strong>Die Wahlen finden immer im Juni statt.</strong> Spannende Aufgaben,
			Kontakt zu ProfessorInnen und einen Einblick hinter die Kulissen der Hochschule warten auf Dich! -->

		</p>
	</div>
</template>

<script>
	export default {
		name: 'wirtschaft',
		mounted() {},
		data() {
			return {}
		}
	}
</script>

<style scoped>
	.wirtschaft {
		background-image: url('../../assets/backgrounds/WirtschaftBG.png');
		background-repeat: no-repeat;
		height: 1000px;
		background-position-x: -2px;
		padding: 50px 50vw 0 200px;
		text-align: left;
	}

	h1 {
		font-family: var(--font-georgia);
		font-size: 60px;
	}

	h2 {
		font-family: var(--font4);
		font-size: 25px;
		color: var(--primary-orange);
	}

	p {
		font-size: 20px;
	}
	a{
		color: var(--primary-orange);
	}
</style>