<template>
  <div class="DBAushang">
    <navbar></navbar>
    <PopUp></PopUp>
    <div class="selectContainer">
      <table
        class="table"
        id="AushangTable"
        data-aos-duration="1500"
        data-aos-delay="1000"
      >
        <thead style="width: 100%" data-aos="fade-up" class="thead-light">
          <tr>
            <th>
              Titel
            </th>
            <th>
              Datum
            </th>
            <th>
              Uhrzeit
            </th>
            <th>
              Teilnehmerzahl
            </th>
            <th>
              Name
            </th>
            <th>
              Matrikelnummer
            </th>
            <th>
              Email
            </th>
            <th>
              Telefon
            </th>
            <th>
              Anschrift
            </th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody id="tableBody"></tbody>
      </table>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import navbar from "@/components/NavBarDashboard";
import PopUp from "../../PopUp.vue";

export default {
  components: {
    navbar,
    PopUp,
  },
  data: {
    receiver: "",
  },
  mounted() {
    $(document).ready(function() {
      $(".active").removeClass("active");
      $("#navDBKalender").addClass("active");
    });
    this.getEntries();
  },
  methods: {
    updateUI(array) {
      var tr = document.createElement("tr");
      tr.id = "aushangTR" + array.get("index");
      tr.style.width = "100%";
      tr.style.textAlign = "left";
      document.getElementById("tableBody").appendChild(tr);

      // var tdID = document.createElement('td')
      // tdID.innerText = array.get("id");
      // document.getElementById('aushangTR' + array.get("index")).appendChild(tdID);

      var tdTitle = document.createElement("td");
      tdTitle.innerText = array.get("title");
      document
        .getElementById("aushangTR" + array.get("index"))
        .appendChild(tdTitle);

      var tdDate = document.createElement("td");
      tdDate.innerText = array.get("date");
      document
        .getElementById("aushangTR" + array.get("index"))
        .appendChild(tdDate);

      var tdTime = document.createElement("td");
      tdTime.innerText = array.get("time");
      document
        .getElementById("aushangTR" + array.get("index"))
        .appendChild(tdTime);

      var tdTeilnehmer = document.createElement("td");
      tdTeilnehmer.innerText = array.get("teilnehmer");
      document
        .getElementById("aushangTR" + array.get("index"))
        .appendChild(tdTeilnehmer);

      var tdName = document.createElement("td");
      tdName.innerText = array.get("Name");
      document
        .getElementById("aushangTR" + array.get("index"))
        .appendChild(tdName);

      var tdmatnr = document.createElement("td");
      tdmatnr.innerText = array.get("matnr");
      document
        .getElementById("aushangTR" + array.get("index"))
        .appendChild(tdmatnr);

      var tdemail = document.createElement("td");
      tdemail.innerText = array.get("email");
      document
        .getElementById("aushangTR" + array.get("index"))
        .appendChild(tdemail);

      var tdPhone = document.createElement("td");
      tdPhone.innerText = array.get("phone");
      document
        .getElementById("aushangTR" + array.get("index"))
        .appendChild(tdPhone);

      var tdAnschrift = document.createElement("td");
      tdAnschrift.innerText = array.get("street") + " " + array.get("city");
      document
        .getElementById("aushangTR" + array.get("index"))
        .appendChild(tdAnschrift);

      var deleteTd = document.createElement("td");
      deleteTd.id = "tdDelete" + array.get("index");
      document
        .getElementById("aushangTR" + array.get("index"))
        .appendChild(deleteTd);

      var changeBtn = document.createElement("div");
      changeBtn.classList = "btn";
      changeBtn.id = "changeBtnGrill" + array.get("index");
      var ref = this;
      changeBtn.style.cssText =
        "background-color: #343A3F; color: white; transition: all 0.5s; border-radius: 40px; margin: 20px";
      changeBtn.textContent = "Genehmigen";
      document
        .getElementById("tdDelete" + array.get("index"))
        .appendChild(changeBtn);
      document
        .getElementById("changeBtnGrill" + array.get("index"))
        .addEventListener("click", async function() {
          await ref.approveEntry(array.get("index"), array.get("email"));
        });

      var deleteBtn = document.createElement("div");
      deleteBtn.classList = "btn";
      deleteBtn.id = "deleteBtn" + array.get("index");

      deleteBtn.style.cssText =
        "background-color: red; color: white; transition: all 0.5s; border-radius: 40px; margin: 20px;";
      deleteBtn.textContent = "Löschen";
      document
        .getElementById("tdDelete" + array.get("index"))
        .appendChild(deleteBtn);
      document
        .getElementById("deleteBtn" + array.get("index"))
        .addEventListener("click", async function() {
          await ref.deleteEntry(array.get("index"), array.get("email"));
          ref.getEntries();
        });
    },
    approveEntry(ID, mail) {
      var ref = this;
      this.request = "grillApproved";
      this.receiver = mail;
      var axios = require("axios");
      var data = JSON.stringify({
        username: "web163_2",
        pw: "Pf0rzelona",
        request: "approve_entry",
        upload_type: "newsFach",
        id: ID,
      });
      var config = {
        method: "post",
        url: "https://vs-pforzheim.de/UploadHandler.php",
        headers: {
          "Content-Type": "this/json",
        },
        data: data,
      };
      return axios(config)
        .then(function(response) {
          document.getElementById("contactFieldAushangHome").style.display =
            "none";
          document.getElementById("popupBtn").style.display = "none";
          document.getElementById("popupDate").innerText = "";
          document.getElementById("popupFooter").style.display = "block";
          document.getElementById("popupButton").style.display = "block";
          document.getElementById("popupButton").style.backgroundColor =
            "var(--primary-orange)";
          document.getElementById("popupButton").style.color = "white";
          if (response.data == "erfolgreich") {
            document.getElementById("popupTitle").innerText =
              "Aushang genehmigt!";
            ref.sendMessage();
          } else {
            document.getElementById("popupTitle").innerText =
              "Ups, hier ist etwas schief gelaufen!";
            document.getElementById("popupText").innerText =
              "Auch unsere Technik hat den einen oder anderen Fehler! Bitte wende dich an julian.zoller@mail-zoller.de";
            $("#popup").modal("show");
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    deleteEntry(ID, mail) {
      var ref = this;
      this.request = "grillDenied";
      this.receiver = mail;
      var axios = require("axios");
      var data = JSON.stringify({
        username: "web163_2",
        pw: "Pf0rzelona",
        request: "delete_entry",
        DB: "newsFach",
        ID: ID,
      });

      var config = {
        method: "post",
        url: "https://vs-pforzheim.de/deleteHandler.php",
        headers: {
          "Content-Type": "this/json",
        },
        data: data,
      };

      return axios(config)
        .then(function(response) {
          document.getElementById("contactFieldAushangHome").style.display =
            "none";
          document.getElementById("popupBtn").style.display = "none";
          document.getElementById("popupDate").innerText = "";
          document.getElementById("popupFooter").style.display = "block";
          document.getElementById("popupButton").style.display = "block";
          document.getElementById("popupButton").style.backgroundColor =
            "var(--primary-orange)";
          document.getElementById("popupButton").style.color = "white";
          if (response.data == "Eintrag geloescht") {
            document.getElementById("popupTitle").innerText =
              "Aushang gelöscht!";
            ref.sendMessage(mail);
          } else {
            document.getElementById("popupTitle").innerText =
              "Ups, hier ist etwas schief gelaufen!";
            document.getElementById("popupText").innerText =
              "Auch unsere Technik hat den einen oder anderen Fehler! Bitte wende dich an julian.zoller@mail-zoller.de!";
            $("#popup").modal("show");
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    sendMessage(mail) {
      var ref = this;
      var axios = require("axios");
      this.mail = mail;

      var data = JSON.stringify({
        request: this.request,
        receiver: this.receiver,
        mail: this.mail,
      });
      console.log(data);
      var config = {
        method: "post",
        url: "https://vs-pforzheim.de/mailer.php",
        headers: {
          "Content-Type": "this/json",
        },
        data: data,
      };

      return axios(config)
        .then(function(response) {
          if (response.data == "Message has been sent successfully") {
            document.getElementById("popupText").innerText =
              "Der Verfasser wurde per Mail über den Status seiner Anfrage informiert!";
            ref.getEntries();

            $("#popup").modal("show");
          } else {
            document.getElementById("popupTitle").innerText =
              "Ups, hier ist etwas schief gelaufen!";
            document.getElementById("popupText").innerText =
              "Auch unsere Technik hat den einen oder anderen Fehler! Bitte wende dich an julian.zoller@mail-zoller.de!";
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    getEntries() {
      document.getElementById("tableBody").innerHTML = "";
      var ref = this;
      ref.aushangArray = [];
      var axios = require("axios");
      var data = JSON.stringify({
        username: "web163_2",
        pw: "Pf0rzelona",
        request: "get_news_fach",
      });

      var config = {
        method: "post",
        url: "https://vs-pforzheim.de/getHandler.php",
        headers: {
          "Content-Type": "this/json",
        },
        data: data,
      };

      return axios(config)
        .then(function(response) {
          response.data.forEach((element, index) => {
            console.log(response.data);

            if (
              element["type"] != "genehmigt" &&
              element["menu"] == "Kalender"
            ) {
              var tmpArray = new Map();
              tmpArray.set("index", element["id"]);
              tmpArray.set("title", element["title"]);
              tmpArray.set("date", element["date"]);
              tmpArray.set("time", element["time"]);
              tmpArray.set("Name", element["name"]);
              tmpArray.set("matnr", element["matnr"]);
              tmpArray.set("teilnehmer", element["teilnehmer"]);
              tmpArray.set("phone", element["phone"]);
              tmpArray.set("city", element["city"]);
              tmpArray.set("street", element["street"]);
              tmpArray.set("email", element["email_creator"]);
              ref.updateUI(tmpArray);
            }
          });
        })
        .catch(function(error) {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
.DBAushang {
  height: 100vh;
  background-image: url("../../assets/backgrounds/BG-2.jpg");
}

.selectContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  padding: 100px;
  width: 80vw;
  background-color: #343a3f;
  color: white;
  -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  text-align: center;
}

table {
  background-color: white;
  position: relative;
  display: block;
  overflow-y: scroll;
  max-height: 50vh;
  text-align: center;
}
</style>
