<template>
    <div class="impressum" >
        <div class="sidenav" data-aos="fade-right" data-aos-duration="1500" data-aos-delay="700">
      <a ><router-link class="router-link" :to="{ name: 'Startseite'}">Home</router-link></a>
      <a ><router-link class="router-link" href="https://asta-pforzheim.de" >AStA</router-link></a>
      <a ><router-link class="router-link" :to="{ name: 'Stura'}">StuRa</router-link></a>
      <a ><router-link class="router-link" :to="{ name: 'Fachschaften'}">Fachschaften</router-link></a>
    </div>
        <div class="header" data-aos="fade-in" data-aos-duration="1500" data-aos-delay="200" >

        </div>
        <div class="card">
			<div class="card-body" data-aos="fade-right" data-aos-duration="1500" data-aos-delay="1000">
                <h4>Impressum</h4>
                <h5 class="mt-2">Allgemeiner Studierendenausschuss der Hochschule Pforzheim</h5>
                    <p>
                        Adresse:Tiefenbronner Straße 65
                    </p>
                    <p>
                        75175 Pforzheim
                    </p>
                    <p>
                        Tel.: 0 72 31 / 28 62 63
                    </p>
                    <p>
                        E-Mail: <a href="mailto:asta@hs-pforzheim.de">asta@hs-pforzheim.de</a>
                    </p>
                    <p>
                        Rechtsform: Körperschaft des öffentlichen Rechts
                    </p>
                    <p>
                        Steuernummer 41402/32155
                    </p>
                <h5 class="mt-2">Studierendenrat der Hochschule Pforzheim</h5>
                <h5 class="mt-2">Fachschaften der Hochschule Pforzheim</h5>
                
            </div>
        </div>
    </div>
</template>

<script>
    export default {

    }
</script>

<style scoped>

ul{
    padding-left: 2em;
}
.impressum{
    text-align: left;
		
    margin-top: -200px;
    padding-top: 200px
}
h4{
    color: var(--primary-orange);
}

.header{
    background-image: url('../assets/backgrounds/pexels-kaboompics-com-5836.jpg');
    height: 500px;
    background-size: 100%;
}
.card{
    margin: -100px 200px 150px 200px;
    border-color: transparent;
		border-radius: 10px;
		-webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.75);
		-moz-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.75);
		box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
}
/* The sidebar menu */
  .sidenav {
    height: 100%;
    /* Full-height: remove this if you want "auto" height */
    position: fixed;
    /* Fixed Sidebar (stay in place on scroll) */
    z-index: 1;
    /* Stay on top */
    top: 20px;

    /* Stay at the top */
    left: 0;
    /* Disable horizontal scroll */
    cursor: pointer;
  }

  /* The navigation menu links */
  .sidenav a {
    padding: 6px 8px 6px 16px;
    text-decoration: none;
    font-size: 20px;
    color: gray;
    display: block;
    writing-mode: vertical-rl;
    text-orientation: mixed;
    font-family: montserrat, sans-serif;
    font-weight: 200;
    font-style: normal;
  }

  /* When you mouse over the navigation links, change their color */
  .sidenav a:hover {
    color: var(--primary-orange);
  }

  /* Style page content */
  .main {
    margin-left: 160px;
    /* Same as the width of the sidebar */
    padding: 0px 10px;
  }

  /* On smaller screens, where height is less than 450px, change the style of the sidebar (less padding and a smaller font size) */
  @media screen and (max-height: 450px) {
    .sidenav {
      padding-top: 15px;
    }

    .sidenav a {
      font-size: 18px;
    }
  }

  @media only screen and (max-width: 750px) {
		.card{
    margin: -100px 50px 150px 75px;
        }
.header{
    background-image: url('../assets/backgrounds/pexels-kaboompics-com-5836.jpg');
    height: 500px;
    background-size: 1020px;
}
	}
</style>