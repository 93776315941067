<template>
  <div class="infoAsta">
    <div id="info1"></div>
    <div
      class="heading"
      data-aos="fade-right"
      data-aos-duration="1500"
      data-aos-delay="700"
    >
      <h1 style="padding-left:70px; padding-right:70px; color: var(--asta-1)">
        Alles Wichtige auf einen Blick
      </h1>
    </div>
    <div
      class="tabs"
      data-aos="zoom-in-down"
      data-aos-duration="1000"
      data-aos-delay="800"
    >
      <div>
        <div class="nav nav-tabs" id="infoAstaNav" role="tablist">
          <div class="nav nav-tabs" id="nav-tab" role="tablist">
            <a
              class="nav-item nav-link active"
              id="nav-Wohnungsmarkt3-tab3"
              data-toggle="tab"
              href="#nav-Wohnungsmarkt3"
              role="tab"
              aria-controls="nav-Wohnungsmarkt3"
              aria-selected="false"
              >Wohnungsmarkt</a
            >
            <a
              class="nav-item nav-link "
              id="nav-band3-tab3"
              data-toggle="tab"
              href="#nav-band3"
              role="tab"
              aria-controls="nav-band3"
              aria-selected="true"
              >Band & Orchester</a
            >
            <a
              class="nav-item nav-link"
              id="nav-studiticket3-tab3"
              data-toggle="tab"
              href="#nav-studiticket3"
              role="tab"
              aria-controls="nav-studiticket3"
              aria-selected="false"
              >Studiticket</a
            >
            <a
              class="nav-item nav-link"
              id="nav-parkplaetze3-tab3"
              data-toggle="tab"
              href="#nav-parkplaetze3"
              role="tab"
              aria-controls="nav-parkplaetze3"
              aria-selected="false"
              >Parkplätze</a
            >
            <a
              class="nav-item nav-link"
              id="nav-BAföG3-tab3"
              data-toggle="tab"
              href="#nav-BAföG3"
              role="tab"
              aria-controls="nav-BAföG3"
              aria-selected="false"
              >BAföG</a
            >
            <a
              class="nav-item nav-link"
              id="nav-Minijobs3-tab3"
              data-toggle="tab"
              href="#nav-Minijobs3"
              role="tab"
              aria-controls="nav-Minijobs3"
              aria-selected="false"
              >Minijobs</a
            >
          </div>
        </div>
      </div>
      <div class="tab-content" id="infoContent">
        <div
          class="tab-pane fade "
          id="nav-band3"
          role="tabpanel"
          aria-labelledby="nav-home-tab"
        >
          <div class="first-tab tab" id="bandAsta3"></div>
        </div>
        <div
          class="tab-pane fade"
          id="nav-studiticket3"
          role="tabpanel"
          aria-labelledby="nav-studiticket3-tab3"
        >
          <div class="second-tab tab" id="studiticketAsta3"></div>
        </div>
        <div
          class="tab-pane fade"
          id="nav-parkplaetze3"
          role="tabpanel"
          aria-labelledby="nav-parkplaetze3-tab3"
        >
          <div class="third-tab tab" id="parkplaetzeAsta3"></div>
        </div>
        <div
          class="tab-pane fade show active"
          id="nav-Wohnungsmarkt3"
          role="tabpanel"
          aria-labelledby="nav-Wohnungsmarkt3-tab3"
        >
          <div class="fourth-tab tab" id="WohnungsmarktAsta3"></div>
        </div>
        <div
          class="tab-pane fade"
          id="nav-BAföG3"
          role="tabpanel"
          aria-labelledby="nav-BAföG3-tab3"
        >
          <div class="third-tab tab" id="BAföGAsta3"></div>
        </div>
        <div
          class="tab-pane fade"
          id="nav-Minijobs3"
          role="tabpanel"
          aria-labelledby="nav-Minijobs3-tab3"
        >
          <div class="fourth-tab tab" id="MinijobsAsta3"></div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="btn " @click="lastEntries()">Zurück</div>
        </div>
        <div class="col">
          <div class="btn " @click="nextEntries()">Nächste</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
export default {
  name: "InfoAsta",
  data: {
    infoArray: [],
    index: 0,
    arraySize: 0,
    maxEntries: 0,
    bandArray: [],
    studiticketArray: [],
    parkArray: [],
    bafoegArray: [],
    wohnungArray: [],
    jobsArray: [],
    activeTab: "",
  },
  mounted: async function() {
    let ref = this;
    ref.maxEntries = 0;
    ref.index = 0;
    ref.activeTab = "Wohnungsmarkt";
    ref.bandArray = [];
    ref.studiticketArray = [];
    ref.parkArray = [];
    ref.bafoegArray = [];
    ref.wohnungArray = [];
    ref.jobsArray = [];
    await this.getEntries();
    ref.bandArray = ref.bandArray.reverse();
    ref.studiticketArray = ref.studiticketArray.reverse();
    ref.parkArray = ref.parkArray.reverse();
    ref.bafoegArray = ref.bafoegArray.reverse();
    ref.wohnungArray = ref.wohnungArray.reverse();
    ref.jobsArray = ref.jobsArray.reverse();
    this.loadEntries();
    $("#nav-band3-tab3").click(function(e) {
      document.getElementById(ref.activeTab + "Asta3").innerHTML = "";
      ref.activeTab = "band";
      ref.index = 0;
      document.getElementById(ref.activeTab + "Asta3").innerHTML = "";

      ref.loadEntries();
    });
    $("#nav-studiticket3-tab3").click(function(e) {
      document.getElementById(ref.activeTab + "Asta3").innerHTML = "";
      ref.index = 0;
      ref.activeTab = "studiticket";
      document.getElementById(ref.activeTab + "Asta3").innerHTML = "";
      ref.loadEntries();
    });
    $("#nav-parkplaetze3-tab3").click(function(e) {
      document.getElementById(ref.activeTab + "Asta3").innerHTML = "";
      ref.index = 0;
      ref.activeTab = "parkplaetze";
      document.getElementById(ref.activeTab + "Asta3").innerHTML = "";

      ref.loadEntries();
    });
    $("#nav-Wohnungsmarkt3-tab3").click(function(e) {
      document.getElementById(ref.activeTab + "Asta3").innerHTML = "";
      ref.index = 0;
      ref.activeTab = "Wohnungsmarkt";
      document.getElementById(ref.activeTab + "Asta3").innerHTML = "";
      ref.loadEntries();
    });
    $("#nav-BAföG3-tab3").click(function(e) {
      document.getElementById(ref.activeTab + "Asta3").innerHTML = "";
      ref.index = 0;
      ref.activeTab = "BAföG";
      document.getElementById(ref.activeTab + "Asta3").innerHTML = "";
      ref.loadEntries();
    });
    $("#nav-Minijobs3-tab3").click(function(e) {
      document.getElementById(ref.activeTab + "Asta3").innerHTML = "";
      ref.index = 0;
      ref.activeTab = "Minijobs";
      document.getElementById(ref.activeTab + "Asta3").innerHTML = "";
      ref.loadEntries();
    });
  },
  methods: {
    nextEntries() {
      let ref = this;
      document.getElementById(ref.activeTab + "Asta3").innerHTML = "";

      this.index = this.index + this.maxEntries + 1;
      if (this.index > this.arraySize - 1) {
        this.index = 0;
      }
      this.loadEntries();
    },
    lastEntries() {
      let ref = this;
      document.getElementById(ref.activeTab + "Asta3").innerHTML = "";
      this.index = this.index - (this.maxEntries + 1);
      if (this.index < 0) {
        this.index = this.arraySize - (this.maxEntries + 1);
      }
      this.loadEntries();
    },
    getEntries() {
      var ref = this;
      ref.bandArray = [];
      ref.studiticketArray = [];
      ref.parkArray = [];
      ref.bafoegArray = [];
      ref.bandArray = [];
      ref.jobsArray = [];
      var axios = require("axios");
      var data = JSON.stringify({
        username: "web163_2",
        pw: "Pf0rzelona",
        request: "get_info_asta",
      });

      var config = {
        method: "post",
        url: "https://vs-pforzheim.de/getHandler.php",
        headers: {
          "Content-Type": "this/json",
        },
        data: data,
      };

      return axios(config)
        .then(function(response) {
          response.data.forEach((element, index) => {
            var tmpArray = new Map();
            tmpArray.set("id", element["id"]);
            tmpArray.set("type", element["menu"]);
            tmpArray.set("title", element["title"]);
            tmpArray.set("text", element["text"]);
            tmpArray.set("link", element["link"]);
            tmpArray.set("linkText", element["linkText"]);
            tmpArray.set("image", element["image"]);
            tmpArray.set("video", element["video"]);
            switch (element["menu"]) {
              case "Band & Orchester":
                ref.bandArray.push(tmpArray);
                break;
              case "Studiticket":
                ref.studiticketArray.push(tmpArray);
                break;
              case "Parkplätze":
                ref.parkArray.push(tmpArray);
                break;
              case "Wohnungsmarkt":
                ref.wohnungArray.push(tmpArray);
                break;
              case "BAföG":
                ref.bafoegArray.push(tmpArray);
                break;
              case "Minijobs":
                ref.jobsArray.push(tmpArray);
                break;
              default:
                break;
            }
          });
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    loadEntries() {
      var entries = 0;
      var ref = this;
      var array = [];

      switch (ref.activeTab) {
        case "band":
          array = ref.bandArray;
          break;
        case "studiticket":
          array = ref.studiticketArray;
          break;
        case "parkplaetze":
          array = ref.parkArray;
          break;
        case "Wohnungsmarkt":
          array = ref.wohnungArray;
          break;
        case "BAföG":
          array = ref.bafoegArray;
          break;
        case "Minijobs":
          array = ref.jobsArray;
          break;
        default:
          break;
      }
      ref.arraySize = array.length;
      array.forEach((element, index) => {
        if (index >= ref.index && index <= ref.index + 1) {
          var isLastElement;
          if (ref.maxEntries >= entries) {
            if (entries != ref.maxEntries) {
              isLastElement = false;
            } else {
              isLastElement = true;
            }

            ref.createContent(array[index], isLastElement);
            entries += 1;
          }
        }
      });
    },
    createContent(array, isLastElement) {
      var type = array.get("type");
      switch (type) {
        case "Band & Orchester":
          type = "band";
          break;
        case "Studiticket":
          type = "studiticket";

          break;
        case "Parkplätze":
          type = "parkplaetze";

          break;
        case "Wohnungsmarkt":
          type = "Wohnungsmarkt";

          break;
        case "BAföG":
          type = "BAföG";

          break;
        case "Minijobs":
          type = "Minijobs";

          break;
        default:
          break;
      }
      var div = document.createElement("div");
      div.classList.add("item");
      div.style.textAlign = "left";
      div.style.padding = "10px";
      div.id = "item3" + array.get("id");
      document.getElementById(type + "Asta3").appendChild(div);

      var div2 = document.createElement("div");
      div2.classList.add("inhalt");
      div2.classList.add("row");
      div2.id = "inhalt3" + array.get("id");
      document.getElementById("item3" + array.get("id")).appendChild(div2);

      if (array.get("image") != "" || array.get("video") != "") {
        var col8 = document.createElement("div");
        col8.classList.add("col-12");
        col8.id = "textinfoAstaCol83" + array.get("id");
        document.getElementById("inhalt3" + array.get("id")).appendChild(col8);

        var col4 = document.createElement("div");
        col4.classList.add("col-12");
        col4.id = "textinfoAstaCol43" + array.get("id");
        document.getElementById("inhalt3" + array.get("id")).appendChild(col4);
      } else {
        var col12 = document.createElement("div");
        col12.classList.add("col-12");
        col12.style.paddingRight = "40px";
        col12.id = "textinfoAstaCol83" + array.get("id");
        document.getElementById("inhalt3" + array.get("id")).appendChild(col12);
      }

      var h2 = document.createElement("h2");
      h2.id = "heading3" + array.get("id");
      h2.style.margin = "20px 0px 0px 20px";
      var heading = document.createTextNode(array.get("title"));
      h2.appendChild(heading);
      document
        .getElementById("textinfoAstaCol83" + array.get("id"))
        .appendChild(h2);

      var p = document.createElement("p");
      p.id = "p" + array.get("id");
      p.style.fontSize = "20px";
      p.style.textAlign = "justify";
      p.style.padding = "20px";
      var pText = document.createTextNode(array.get("text"));
      p.appendChild(pText);
      document
        .getElementById("textinfoAstaCol83" + array.get("id"))
        .appendChild(p);

      var a = document.createElement("a");
      a.id = "a1" + array.get("id");
      a.href = array.get("link");
      a.target = "_blank";
      a.style.fontSize = "20px";
      a.style.padding = "20px";
      a.style.color = "var(--asta-1)";
      var aText = document.createTextNode(array.get("linkText"));
      a.appendChild(aText);
      document
        .getElementById("textinfoAstaCol83" + array.get("id"))
        .appendChild(a);

      if (array.get("image") != "") {
        var mediaType = array.get("image").slice(-3);
        if (mediaType == "pdf" || mediaType == "PDF") {
          var pdfLink = document.createElement("a");
          pdfLink.innerText = "Öffne PDF";
          pdfLink.target = "_blank";
          pdfLink.href = "https://vs-pforzheim.de/" + array.get("image");
          document
            .getElementById("textinfoAstaCol83" + array.get("id"))
            .appendChild(pdfLink);
        } else {
          var img = document.createElement("img");
          img.style.maxHeight = "300px";
          img.style.margin = "0px auto 0px auto";
          img.style.paddingLeft = "20px";
          img.src = "https://vs-pforzheim.de/" + array.get("image");
          document
            .getElementById("textinfoAstaCol43" + array.get("id"))
            .appendChild(img);
        }
      }

      if (array.get("image") != "") {
      }

      if (array.get("video") != "") {
        var vid = document.createElement("iframe");
        vid.height = "315";
        vid.width = "100%";
        vid.style.margin = "0px auto 0px auto";
        vid.frameBorder = "0";
        vid.style.paddingLeft = "20px";
        vid.style.paddingRight = "20px";
        vid.src = array.get("video");
        document
          .getElementById("textinfoAstaCol43" + array.get("id"))
          .appendChild(vid);
      }

      if (!isLastElement) {
        var hr = document.createElement("hr");
        hr.style.marginTop = "50px";
        hr.style.textAlign = "center";
        document.getElementById("item3" + array.get("id")).appendChild(hr);
      }
    },
  },
};
</script>

<style scoped>
.list-group-item {
  cursor: pointer;
}

.card {
  margin: 50px 160px 50px 160px;
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  background-color: white;
}

.heading h2 {
  color: var(--asta-1);
  margin-left: auto;
  margin-right: auto;
  font-size: 50px;
}

.col1 {
  max-width: 20%;
}

.list-group-item.active {
  background-color: var(--asta);
  border-color: var(--asta);
}

.item {
  text-align: left;
  padding: 10px;
}

p {
  font-size: 20px;
  text-align: justify;
  padding: 20px;
}

a {
  color: var(--asta) !important;
}

hr {
  margin-top: 30px;
  width: 98%;
}

#orchesterImg {
  width: 100%;
}

.tabs {
  margin: 50px 75px 50px 75px;
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  background-color: white;
}

.tabs p {
  font-size: 20px;
  text-align: justify;
}

.nav-link {
  font-size: 20px;
}

.item h2 {
  margin: 20px 0px 0px 20px;
}

.btn {
  background-color: var(--asta-2);
  color: white;
  width: 30vw;
  margin-top: 20px;
  border-radius: 20px;
  cursor: pointer;
}

a {
  color: var(--primary-green) !important;
}
</style>
