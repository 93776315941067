<template>
  <div class="dashboard">
		<nav class="navbar navbar-expand-lg navbar-dark bg-dark" data-aos="fade-down"
				data-aos-duration="2000">
			<a class="navbar-brand" href="#">Dashboard</a>
			<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
				aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
				<span class="navbar-toggler-icon"></span>
			</button>
			<div class="collapse navbar-collapse" id="navbarNav">
				<ul class="navbar-nav">
					<li class="nav-item">
						 <router-link id="nav-home-asta" class="router-link nav-link" to="/">Zur Website</router-link>
					</li>
					<li class="nav-item " id="navDBAnpassung">
						<router-link id="nav-home-asta" class="router-link nav-link" :to="{ name: 'DBAnpassung'}">Seitenanpassung</router-link>
					</li>
					<li class="nav-item" id="navDBAushang">
						<router-link class="router-link nav-link" :to="{ name: 'DBAushang'}">Aushang Anfragen</router-link>
					</li>
					<li class="nav-item" id="navDBKalender">
						<router-link class="router-link nav-link" :to="{ name: 'DBKalender'}">Kalender Reservierungen</router-link>
					</li>
					<li class="nav-item" id="navDBKulturflat">
						<router-link class="router-link nav-link" :to="{ name: 'KulturflatStura'}">Kulturflat</router-link>
					</li>
					
				</ul>
			</div>
			<button class="btn btn-outline-success my-2 my-sm-0" type="submit" v-if="this.$auth.isAuthenticated" @click="logout" data-aos="fade-left"
				data-aos-duration="2000" data-aos-delay="1000">Logout</button>
		</nav>
	</div>
</template>

<script>
export default {
	methods:{
			logout() {
				this.$auth.logout({
					returnTo: window.location.origin
				});
			},
		}
}
</script>

<style scoped>

	a, li{
		cursor: pointer;
	}

</style>