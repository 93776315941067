<template>
	<div class="Gestaltung">
		<div class="gestaltungContainer">
			<h2 data-aos="flip-left" data-aos-duration="1500" data-aos-delay="1000">Fachschaften</h2>
			<h1 data-aos="flip-right" data-aos-duration="1500" data-aos-delay="700">
				Gestaltung
			</h1>
			<p data-aos="flip-up" data-aos-duration="1500" data-aos-delay="1500">
				Die Fachschaften der Fakultät für Gestaltung sind eine großartige Mischung aus allen möglichen
				Designbereichen wie Industrie, Automobil oder Mode. In der Holzgartenstraße kommen alle, die Talent und
				Kreativität zu bieten haben, zusammen um zu lernen. Alle Studierenden gemeinsam bilden die Fachschaft
				und der Fachschaftsrat, als gewähltes Gremium, vertritt die Studierenden und ihre Interessen.
			</p>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'wirtschaft',
		mounted() {},
		data() {
			return {}
		}
	}
</script>

<style scoped>
	.Gestaltung {
		background-image: url('../../assets/backgrounds/FachGestaltung2.png');
		background-repeat: no-repeat;
		background-position-x: 0px;
		background-position-y: -20px;
		padding: 50px 50vw 0 200px;
		text-align: left;
		height: 1000px;
	}

	h1 {
		font-family: var(--font-georgia);
		font-size: 60px;
	}

	h2 {
		font-family: var(--font4);
		font-size: 25px;
		color: var(--primary-orange);
	}

	p {
		font-size: 20px;
	}
</style>