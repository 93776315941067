<template>
	<div class="service">
		<Service1 class="Service1"></Service1>
		<Service2 class="Service2"></Service2>
		<Service3 class="Service3"></Service3>
		<Service4 class="Service4"></Service4>

	</div>
</template>

<script>
	import Service1 from '@/views/asta/Service1.vue'
	import Service2 from '@/views/asta/Service2.vue'
	import Service3 from '@/views/asta/Service3.vue'
	import Service4 from '@/views/asta/Service4.vue'
	import $ from "jquery";
	
	export default {
		components: {
			Service1,
			Service3,
			Service4,
			Service2
			
		},
	}
</script>

<style scoped>
	
@media only screen and (max-width: 1300px) {
		.Service1 {
			display: none;
		}
	}

	@media only screen and (min-width: 1301px) {
		.Service2 {
			display: none;
		}
	}
	@media only screen and (max-width: 900px) {
		.Service2 {
			display: none;
		}
	}

	@media only screen and (min-width: 901px) {
		.Service3 {
			display: none;
		}
	}
	@media only screen and (max-width: 500px) {
		.Service3 {
			display: none;
		}
	}

	@media only screen and (min-width: 501px) {
		.Service4 {
			display: none;
		}
	}
</style>
