<template>
  <div class="protokolle">
    <div id="protokolleStura"></div>
    <div class="row">
      <div class="col protkollCol">
        <h1 id="protokollHeading1">
          Sitzungsprotokolle
        </h1>

        <h1 id="protokollHeading2">Sitzungs-<br />protokolle</h1>
        <select
          class="btn select"
          id="protkollSelect"
          aria-label="Default select example"
        >
          <option selected value="2022">Protokolle aus dem Jahr 2022 </option>
          <option value="2021">Protokolle aus dem Jahr 2021 </option>
          <option value="2020">Protokolle aus dem Jahr 2020</option>
          <option value="2019">Protokolle aus dem Jahr 2019</option>
        </select>
        <div class="row" id="protokollList"></div>
      </div>
      <!-- <div class="col imgCol">
				<img id="protokollImg" src="@/assets/bilder/protokolleImg.jpeg" >

			</div> -->
    </div>
  </div>
</template>

<script>
import $ from "jquery";
export default {
  name: "protokolle",
  mounted: async function() {
    await this.getEntries();
    this.setEntries();
    let ref = this;
    var protkollSelect = document.getElementById("protkollSelect");
    protkollSelect.onchange = (event) => {
      ref.year = event.target.value;
      ref.setEntries();
    };
  },
  data: {
    entries: [],
    year: "",
  },
  methods: {
    getEntries() {
      var ref = this;
      ref.entries = [];
      var date = new Date().getFullYear();
      ref.year = date.toString();
      var axios = require("axios");
      var data = JSON.stringify({
        username: "web163_2",
        pw: "Pf0rzelona",
        request: "get_protokolle_asta",
      });

      var config = {
        method: "post",
        url: "https://vs-pforzheim.de/getHandler.php",
        headers: {
          "Content-Type": "this/json",
        },
        data: data,
      };

      return axios(config)
        .then(function(response) {
          response.data.forEach((element, index) => {
            var tmpArray = new Map();
            if (element["type"] == "protokoll") {
              tmpArray.set("id", element["id"]);
              tmpArray.set("type", element["type"]);
              tmpArray.set("image", element["image"]);
              tmpArray.set("date", element["date"]);
              tmpArray.set("title", element["title"]);
              ref.entries.push(tmpArray);
            }
          });
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    setEntries() {
      let ref = this;
      document.getElementById("protokollList").innerHTML = "";
      ref.entries.forEach((element) => {
        var date = element.get("date");
        var year = date.substr(date.length - 4, date.length);

        if (year == ref.year) {
          var a = document.createElement("a");
          a.href = "https://vs-pforzheim.de/" + element.get("image");
          a.innerText = element.get("title") + "\n";
          a.target = "_blank";
          a.style.color = "#333333";
          a.style.width = "100%";
          document.getElementById("protokollList").appendChild(a);
        }
      });
    },
  },
};
</script>

<style scoped>
.protokolle {
  margin-top: 300px;

  width: 100%;
}

@media only screen and (max-width: 950px) {
  .imgCol {
    display: none;
  }
  .protkollCol {
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 450px) {
  #protokollHeading1 {
    display: none;
  }
}

@media only screen and (min-width: 451px) {
  #protokollHeading2 {
    display: none;
  }
}

.protkollCol {
  background-color: #e7e0d4;
  padding-bottom: 100px;
}

.imgCol {
  /* max-width: 63.3%; */
  background-color: #e7e0d4;
}

h1 {
  color: #333333;

  margin-top: 20px;
}

svg {
  color: #333333;

  width: 100px;
  margin: auto;
}

.select {
  border-radius: 20px;
  margin: 50px;
  padding: 10px;
  color: #333333;

  border: 2px solid white;
}

select option {
  color: #333333;
}

p {
  color: #333333;
  text-align: center;
  margin: auto;
}

/* img{
		border-radius: 20px;
	} */
</style>
