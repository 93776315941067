<template>
  <div class="sturaMitglieder">
    <div class="row sturaMgContainer">
      <div class="row justify-content-center imgRow">
        <h3 data-aos="fade-left" data-aos-duration="200" data-aos-delay="2000">
          Präsidium
        </h3>
        <table>
          <tr data-aos="fade-up" data-aos-duration="200" data-aos-delay="2200">
            <td>
              <img src="@/assets/bilder/Stura MGs/Patrick.jpg" />
            </td>
            <td>
              <img src="@/assets/bilder/Stura MGs/Paul M J Günther.jpg" />
            </td>
            <td>
              <img src="@/assets/bilder/Stura MGs/Azize.jpg" />
            </td>
          </tr>
          <tr
            data-aos="fade-left"
            data-aos-duration="200"
            data-aos-delay="2400"
          >
            <td>
              <h4>Vizepräsident</h4>
            </td>
            <td>
              <h4>Präsident</h4>
            </td>
            <td>
              <h4>Vizepräsident</h4>
            </td>
          </tr>
          <tr
            data-aos="fade-left"
            data-aos-duration="200"
            data-aos-delay="2400"
          >
            <td>
              <h5>Patrick<br />Blaszczyk</h5>
            </td>
            <td>
              <h5>Paul<br />Günther</h5>
            </td>
            <td>
              <h5>Azize<br />Ercin</h5>
            </td>
          </tr>
          <tr
            data-aos="fade-left"
            data-aos-duration="200"
            data-aos-delay="2400"
          >
            <td>
              <p>Fakultät<br />Technik</p>
            </td>
            <td>
              <p>Fakultät<br />Wirt. & Recht</p>
            </td>
            <td>
              <p>Fakultät<br />Wirt. & Recht</p>
            </td>
          </tr>
        </table>
      </div>
      <div class="row justify-content-center imgRow">
        <h3 data-aos="fade-left" data-aos-duration="200">Mitglieder</h3>
        <table>
          <tr data-aos="fade-up" data-aos-duration="200" data-aos-delay="0">
            <td>
              <img src="@/assets/bilder/Stura MGs/Nurishahe.jpg" />
            </td>
            <td>
              <img src="@/assets/bilder/Stura MGs/Henna.jpg" />
            </td>
            <td>
              <img src="@/assets/bilder/Stura MGs/Hannah.jpg" />
            </td>
            <td>
              <img src="@/assets/bilder/Stura MGs/Marlon.jpg" />
            </td>
          </tr>
          <tr data-aos="fade-left" data-aos-duration="200" data-aos-delay="">
            <td>
              <h5>Nurishahe<br />Durguti</h5>
            </td>
            <td>
              <h5>Johanne<br />Freisleben</h5>
            </td>
            <td>
              <h5>Hannah<br />Fritz</h5>
            </td>
            <td>
              <h5>Marlon<br />Geisler</h5>
            </td>
          </tr>
          <tr data-aos="fade-left" data-aos-duration="200" data-aos-delay="">
            <td>
              <p>Fakultät<br />Wirt. & Recht</p>
            </td>
            <td>
              <p>Fakultät<br />Wirt. & Recht</p>
            </td>
            <td>
              <p>Fakultät<br />Wirt. & Recht</p>
            </td>
            <td>
              <p>Fakultät<br />Wirt. & Recht</p>
            </td>
          </tr>
        </table>
      </div>
      <div class="row justify-content-center imgRow">
        <table>
          <tr data-aos="fade-up" data-aos-duration="200" data-aos-delay="">
            <td>
              <img src="@/assets/bilder/Stura MGs/Verena.jpg" />
            </td>
            <td>
              <img src="@/assets/bilder/Stura MGs/Naomi.jpg" />
            </td>
            <td>
              <img src="@/assets/bilder/Stura MGs/Alina.jpg" />
            </td>
            <td>
              <img src="@/assets/bilder/Stura MGs/Moritz.jpg" />
            </td>
          </tr>
          <tr data-aos="fade-left" data-aos-duration="200" data-aos-delay="">
            <td>
              <h5>Verena<br />Siudek</h5>
            </td>
            <td>
              <h5>Naomi<br />Weimer</h5>
            </td>
            <td>
              <h5>Alina<br />Winkler</h5>
            </td>
            <td>
              <h5>Moritz<br />Winterfeldt</h5>
            </td>
          </tr>
          <tr data-aos="fade-left" data-aos-duration="200" data-aos-delay="">
            <td>
              <p>Fakultät<br />Wirt. & Recht</p>
            </td>
            <td>
              <p>Fakultät<br />Wirt. & Recht</p>
            </td>
            <td>
              <p>Fakultät<br />Wirt. & Recht</p>
            </td>
            <td>
              <p>Fakultät<br />Wirt. & Recht</p>
            </td>
          </tr>
        </table>
      </div>
      <div class="row justify-content-center imgRow">
        <table>
          <tr data-aos="fade-up" data-aos-duration="200" data-aos-delay="">
            <td>
              <img src="@/assets/bilder/Stura MGs/Alexander.jpg" />
            </td>
            <td>
              <img src="@/assets/bilder/Stura MGs/Emanuel.jpg" />
            </td>
            <td>
              <img src="@/assets/bilder/Stura MGs/Julie.jpg" />
            </td>
            <td>
              <img src="@/assets/bilder/Stura MGs/Jale.jpg" />
            </td>
            <td>
              <img src="@/assets/bilder/Stura MGs/Meltem.jpg" />
            </td>
            <!-- <td>
							<img src="@/assets/bilder/Stura MGs/.jpg">
						</td> -->
          </tr>
          <tr data-aos="fade-left" data-aos-duration="200" data-aos-delay="">
            <td>
              <h5>Alexander<br />Choroschawzew</h5>
            </td>
            <td>
              <h5>Emanuel<br />Fuchs</h5>
            </td>
            <td>
              <h5>Julie<br />Russell</h5>
            </td>
            <td>
              <h5>Jale<br />Sanlitürk</h5>
            </td>
            <td>
              <h5>Meltem<br />Bayir</h5>
            </td>
          </tr>
          <tr data-aos="fade-left" data-aos-duration="200" data-aos-delay="">
            <td>
              <p>Fakultät<br />Technik</p>
            </td>
            <td>
              <p>Fakultät<br />Technik</p>
            </td>
            <td>
              <p>Fakultät<br />Technik</p>
            </td>
            <td>
              <p>Fakultät<br />Technik</p>
            </td>
            <td>
              <p>Fakultät<br />Technik</p>
            </td>
          </tr>
        </table>
      </div>
      <div class="row justify-content-center imgRow">
        <table>
          <tr data-aos="fade-up" data-aos-duration="200" data-aos-delay="">
            <td>
              <img src="@/assets/bilder/Stura MGs/Yücel.jpg" />
            </td>
            <td>
              <img src="@/assets/bilder/Stura MGs/David.jpg" />
            </td>
            <td>
              <img src="@/assets/bilder/Stura MGs/Franziska.jpg" />
            </td>
            <td>
              <img src="@/assets/bilder/Stura MGs/Luis.jpg" />
            </td>
          </tr>
          <tr data-aos="fade-left" data-aos-duration="200" data-aos-delay="">
            <td>
              <h5>Yücel<br />Poyraz</h5>
            </td>
            <td>
              <h5>David<br />Lippert</h5>
            </td>
            <td>
              <h5>Franziska<br />Reich</h5>
            </td>
            <td>
              <h5>Luis<br />Winkelbrandt</h5>
            </td>
          </tr>
          <tr data-aos="fade-left" data-aos-duration="200" data-aos-delay="">
            <td>
              <p>Fakultät<br />Technik</p>
            </td>
            <td>
              <p>Fakultät<br />Gestaltung</p>
            </td>
            <td>
              <p>Fakultät<br />Gestaltung</p>
            </td>
            <td>
              <p>Fakultät<br />Gestaltung</p>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SturaMitglieder",
  mounted: async function() {
    await this.getEntries();
    this.loadEntries();
    this.mgArray = [];
  },
  methods: {
    getEntries() {
      var ref = this;
      ref.mgArray = [];
      var axios = require("axios");
      var data = JSON.stringify({
        username: "web163_2",
        pw: "Pf0rzelona",
        request: "get_stura_mg",
      });

      var config = {
        method: "post",
        url: "https://vs-pforzheim.de/getHandler.php",
        headers: {
          "Content-Type": "this/json",
        },
        data: data,
      };

      return axios(config)
        .then(function(response) {
          response.data.forEach((element, index) => {
            // console.log(response.data[index])
            // var tmpArray = new Map();
            // tmpArray.set('id', element["id"]);
            // tmpArray.set('vorname', element["vorname"]);
            // tmpArray.set('name', element["name"]);
            // tmpArray.set('stellung', element["stellung"]);
            // tmpArray.set('fakultaet', element["fakultaet"]);
            // tmpArray.set('image', element["image"]);
            // ref.mgArray.push(tmpArray);
          });
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    loadEntries() {
      var ref = this;
      ref.mgArray.forEach((element, index) => {
        switch (element.get("stellung")) {
          case "Präsident":
            break;
          case "Vizepräsident":
            break;
          case "Mitglied":
            switch (element.get("fakultaet")) {
              case "Fakultät Wirt. & Recht":
                break;
              case "Fakultät Technik":
                break;
              case "Fakultät Gestaltung":
                break;

              default:
                break;
            }
          default:
            break;
        }
      });
    },
  },
  data: {
    mgArray: [],
  },
};
</script>

<style scoped>
img {
  width: 100px;
  border-radius: 50%;
  transition: 0.5s;
  /* display: none; */
}

img:hover {
  width: 400px;
  transition: 0.5s;
}

.row {
  display: block;
}

.imgRow {
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
}

h3 {
  color: var(--primary-orange);
  font-size: 40px;
  /* font-family: azo-sans-uber, sans-serif; */
  font-weight: 750;
}

.imgContainerMG {
  position: relative;
  width: 400px;
  margin: auto;
}

.rowContainer {
  display: flex;
  width: 200px;
  margin: auto;
}

table {
  margin: auto;
}

td {
  padding-left: 10px;
}

/* h4 {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		opacity: 0;
		transition: 0.5s;
	}

	.imgContainerMG:hover h4 {
		opacity: 1;
		transition: 1s;
	} */
</style>
