<template>
  <div class="gallerie">
    <div id="gallerie1Home"></div>
   <gallerie1 class="gallerie1"></gallerie1>
   <gallerie2 class="gallerie2"></gallerie2>
   <gallerie3 class="gallerie3"></gallerie3>
  </div>
</template>
<script>
  import gallerie1 from '@/views/home/GallerieHome1.vue'
  import gallerie2 from '@/views/home/GallerieHome2.vue'
  import gallerie3 from '@/views/home/GallerieHome3.vue'

  export default {
    name: 'carousel',
    components: {
      gallerie1,
      gallerie2,
      gallerie3

    },
  }
</script>

<style scoped>
  

   @media only screen and (max-width: 950px) {
    .gallerie1 {
      display: none;
    }
  }

  @media only screen and (min-width: 951px) {
    .gallerie2 {
      display: none;
    }
  }

    @media only screen and (max-width: 650px) {
    .gallerie2 {
      display: none;
    }
  }

  @media only screen and (min-width: 651px) {
    .gallerie3 {
      display: none;
    }
  }


</style>