<template>
  <div class="news">
    <div id="infoStura"></div>
    <PopUp></PopUp>
    <h1>Infos</h1>
    <NewsStura1 class="NewsStura1"></NewsStura1>
    <NewsStura2 class="NewsStura2"></NewsStura2>
    <NewsStura3 class="NewsStura3"></NewsStura3>
    
     
  </div>
</template>

<script>
   import $ from "jquery";
  import PopUp from "../../PopUp.vue"
  import NewsStura1 from '@/views/stura/NewsStura1.vue'
  import NewsStura2 from '@/views/stura/NewsStura2.vue'
  import NewsStura3 from '@/views/stura/NewsStura3.vue'

  export default {
    name: 'newsStura',
    components: {
      PopUp,
      NewsStura1,
      NewsStura2,
      NewsStura3
    },
   
  }

</script>

<style scoped>
  @import url("https://use.typekit.net/kve4mnq.css");

  .news {
    /* background-image: url('../../assets/backgrounds/NewsBackground.png'); */
		background-color: var(--secondary-green);

    background-repeat: no-repeat;
    margin-top: 100px;
  }

   @media only screen and (max-width: 1200px) {
    .NewsStura1 {
      display: none;
    }
    .NewsStura2 {
      min-height: 1000px;
    }
  }

  @media only screen and (min-width: 1201px) {
    .NewsStura2 {
      display: none;
    }
  }
  @media only screen and (max-width: 750px) {
    .NewsStura2 {
      display: none;
    }
  }

  @media only screen and (min-width: 751px) {
    .NewsStura3 {
      display: none;
    }
  }

  h1{
		color: var(--primary-orange);
		font-size: 40px;
		/* font-family: azo-sans-uber, sans-serif; */
		font-weight: 750;
    margin: 0px 0px -200px 0px;
    padding-top: 50px;
  }

</style>
