<template>
  <div class="organisation">
    <div id="organisation1"></div>
    <div class="flexbox1" data-aos="fade-right" data-aos-duration="800">
      <h1 data-aos="fade-right" data-aos-duration="300">
        Die Verfasste Studierendenschaft
      </h1>
      <br>
      <p>
        Die Verfasste Studierendenschaft ist die Gesamtheit aller Studierenden einer Hochschule. Nachdem ihre
        Wiedereinführung im Juni 2012 vom Landtag von Baden-Württemberg beschlossen wurde heißt es: Studentische
        Interessenvertretung und Selbstverwaltung ist wieder möglich! Nach der Organisationssatzung hat unsere
        „Studierendenschaft der Hochschule Pforzheim“ drei Organe:
      </p>
    </div>
    <div class="flexbox2" data-aos="fade-right" data-aos-duration="300">
      <p>
        1. Der StuRa ist das legislative (gesetzgebende) Organ und stimmt damit z. B. über formale Änderungen oder die
        Verteilung der Qualitätssicherungsmittel ab. Er ist das höchste Gremium der Studierendenschaft, entscheidet
        über grundsätzliche Angelegenheiten der Studierendenschaft und wählt außerdem die Referatsleiter des AStA.
        Zudem verabschiedet er den Haushaltsplan. Alle Satzungen müssen von ihm abgesegnet und genehmigt werden.
      </p>
      <p>
        2. Der AStA ist das exekutive (ausführende) Organ und plant damit kulturelle, soziale und sportliche Angebote
        und vieles mehr für euch. Er führt die laufenden Geschäfte der Studierendenschaft und setzt die Beschlüsse
        des StuRa um.
      </p>
      <p>
        3. Der Fachschaftsrat ist das Organ der Fachschaft.
      </p>
      <!-- <img src="@/assets/bilder/Organisationsschema.png"> -->
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Organisation',
    mounted() {},
    data() {
      return {}
    }
  }
</script>

<style scoped>
  .organisation {
    background-image: url('../../assets/backgrounds/VSBG.png');
    background-repeat: no-repeat;
    background-size: 70.15% 70%;
    margin-top: -5px;
    /* margin-bottom: 200px; */
  }



  img {
    width: 80vw;
  }

  .flexbox2 {
    margin: 100px 50px 50px 40vw;
    text-align: left;
  }

  .flexbox1 {
    padding-top: 150px;
    padding-left: 200px;
    text-align: left;
    width: 60%;
  }

  p {
    font-size: 20px;
    text-align: left;
    border-radius: 20px;
    color: black;
    width: 70%;
  }

  h1 {
    font-family: var(--font-playfair) !important;
    color: black;
    font-weight: bolder;
  }

  @media only screen and (max-width: 1684px) {
    .organisation {
      background-size: 100% 60%;
    }
  }

  @media only screen and (max-width: 1300px) {
    .flexbox2 {
      margin: 100px 0px 50px 40vw;
    }

    .flexbox1 {
      padding-left: 100px;
    }

    p {
      width: 90%;
    }
  }

  @media only screen and (max-width: 900px) {

    .flexbox2 {
      margin: 200px 100px 50px 100px;
      width: 80%;
    }

    .flexbox1 {
      padding-left: 100px;
      padding-top: 50px;
      width: 90%;
    }

    p {
      width: 100%;
    }

    .organisation {
      padding-bottom: 0px;
      background-size: 150% 50%;

    }

  }

  @media only screen and (max-width: 700px) {
    .flexbox2 {
      margin: 150px 100px 50px 100px;
      width: 75%;
    }
  }

  @media only screen and (max-width: 580px) {
    .flexbox2 {
      margin: 80px 100px 50px 100px;
      width: 75%;
    }
    .organisation {
      background-size: 150% 55%;
    }
  }
  @media only screen and (max-width: 500px) {
     .flexbox2 {
      margin: 100px 100px 50px 50px;
      width: 80%;
    }

    h1{
      font-size: 35px!important;
    }

    .flexbox1 {
      padding-left: 50px;
      padding-top: 50px;
      width: 90%;
    }
     .organisation {
      background-size: 150% 52.5%;
    }
  }
</style>