<template>
  <div class="kulturflat" data-aos="fade-right" data-aos-duration="300">
    <div class="videoContainer"></div>
    <div class="flatContainer">
      <div
        class="textContainer"
        data-aos="fade-left"
        data-aos-duration="500"
        data-aos-delay="00"
      >
        <h1 data-aos="fade-down" data-aos-duration="500" data-aos-delay="200">
          Die Kulturflat - Dein Gutscheincode
        </h1>
        <p>
          Du möchtest Kultur in Pforzheim erleben?<br />
          Dann ist die Kulturflat genau das Richtige für dich!
        </p>
        <p>
          Gemeinsam mit der Hochschule sind der Stura und AStA Sponsoren der
          Kulturflat. Hiermit könnt ihr mithilfe eines Zugangscodes ausgewählte
          Veranstaltungen in Pforzheim genießen.
        </p>
        <p class="bolder">
          <strong> Wie oft kann ich den Code einlösen?<br /> </strong>
          Jeder Code lässt sich nur 1-mal einlösen. Ihr könnt euch aber
          unbegrenzt Codes generieren.
        </p>
        <p>
          <strong>
            Wo kann ich den Code einlösen?
            <br />
          </strong>
          In diesen Einrichtungen:
        </p>
        <div class="mailContainer justify-content-center">
          <form id="kulturflatForm" onSubmit="return false;">
            <input
              id="flatMail2"
              type="text"
              class="form-control"
              placeholder="Deine Hochschul-Email"
              aria-label="Hochschul-Email"
              aria-describedby="basic-addon1"
            />
          </form>
          <div class="btn" v-on:click="checkForm()">CODE GENERIEREN</div>
        </div>
      </div>
    </div>
    <video
      src="https://vs-pforzheim.de/upload/Kulturflat V1.mp4"
      loop
      muted
      controls
    ></video>
  </div>
</template>

<script>
import $ from "jquery";
import axios from "axios";
export default {
  name: "Kulturflat",
  mounted() {},
  methods: {
    checkForm() {
      let mail = document.getElementById("flatMail2").value;
      // if (mail.includes("hs-pforzheim.de")) {
      //   var substr = mail.split("@")[0];
      //   if (substr.includes(".")) {
      //     $('#flatMailProfError').modal('toggle')
      //     return
      //   }
      //   axios.get(
      //       "https://vs-pforzheim.de/mailer.php?mail=" + mail
      //     )
      //     .catch(function (error) {
      //        $('#flatMailError').modal('toggle')
      //     })
      //     .then(response => {
      //       if (response.data == "Message has been sent successfully") {
      //         $('#flatMailSubmit').modal('toggle')
      //       }
      //     })
      // } else {
      $("#flatMailError").modal("toggle");
      // }
    },
  },
};
</script>

<style scoped>
.kulturflat {
  margin-top: 150px;
  /* background-color: var(--yellow-light); */
  /* background-image: url('../../assets/backgrounds/KulturflatBG.png'); */
  text-align: center;
  padding-bottom: 50px;
}

video {
  width: 60vw;
  margin-top: 50px;
  -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  /* border-radius: 20px; */
}

h1 {
  color: black;
  padding-top: 30px;
  font-family: var(--font-playfair);
  font-weight: bolder;
}

.textContainer {
  margin-left: 50px;
  margin-right: 50px;
}

p {
  margin-top: 30px;
  color: black;

  font-size: 20px;
}

.mailContainer {
  margin-top: 50px;
  margin-left: 150px;
  margin-right: 150px;
}

@media only screen and (max-width: 660px) {
  .mailContainer {
    margin-left: 0px;
    margin-right: 0px;
  }
}

#flatMail {
  height: 60px;
}

.btn {
  background-color: var(--yellow);
  color: white;
  margin-top: 20px;
  border-radius: 20px;
  font-weight: bolder;
  border-radius: 50px;
  padding: 25px 0px;
  width: 80%;
}

input {
  width: 80%;

  border-radius: 20px;
  margin-left: auto;
  margin-right: auto;
}

.modal-backdrop {
  /* bug fix - no overlay */
  display: none;
}
</style>
