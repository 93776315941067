<template>
	<div class="sturaMitglieder">
		<div class="mgHeading">
			<h1 data-aos="fade-right" data-aos-duration="1500" data-aos-delay="00">
				Der<br>
				Stura<br>
				stellt<br>
				sich<br>
				vor.
			</h1>
			<h2 data-aos="fade-down" data-aos-duration="1500" data-aos-delay="700" id="h22020">
				2021/2022
			</h2>
		</div>
		<div class="row sturaMgContainer">
				<sturaMG1 class="sturaMG1"></sturaMG1>
				<sturaMG2 class="sturaMG2"></sturaMG2>
				<sturaMG3 class="sturaMG3"></sturaMG3>
				<sturaMG4 class="sturaMG4"></sturaMG4>
		</div>
	</div>

</template>

<script>
	import sturaMG1 from '@/views/stura/sturaMG1.vue'
	import sturaMG2 from '@/views/stura/sturaMG2.vue'
	import sturaMG3 from '@/views/stura/sturaMG3.vue'
	import sturaMG4 from '@/views/stura/sturaMG4.vue'

	export default {
		name: 'SturaMitglieder',
		components: {
			sturaMG1,
			sturaMG2,
			sturaMG3,
			sturaMG4
		},
		mounted() {},
		data() {
			return {}
		}
	}
</script>

<style scoped>
	.sturaMitglieder {
		/* background-color: var(--secondary-green); */
		padding-bottom: 100px;
		width: 100%;
		margin-top: 100px;
	}

	.mgHeading {
		margin-left: auto;
		margin-right: auto;
	}

	h1 {
		color: var(--primary-green);
		font-family: azo-sans-uber, sans-serif;
		text-transform: uppercase;
		font-weight: bolder;
		font-size: 80px!important;
		text-align: left;
		position: absolute;
		left: 40vw;
		top: 20px;
	}

	#h22020 {
		writing-mode: vertical-rl;
		-webkit-text-stroke-width: 1px;
		-webkit-text-stroke-color: var(--primary-green);
		font-size: 95px!important;
		color: white;
		position: absolute;
		left: 52vw;
		z-index: -1;
		top: 40px;
	}

	/* @media only screen and (max-width: 1130px) {
		.sturaMG1 {
			display: none;
		}
	} */

	/* @media only screen and (min-width: 1131px) { */
		.sturaMG2 {
			display: none;
		}
		.sturaMG3 {
			display: none;
		}
	/* } */

	@media only screen and (max-width: 800px) {
		.sturaMG1 {
			display: none;
		}
	}


	@media only screen and (min-width: 801px) {
		.sturaMG4 {
			display: none;
		}
	}

	@media only screen and (max-width: 1700px) {
		#h22020 {
			left: 53vw;

		}
	}

	@media only screen and (max-width: 1500px) {
		#h22020 {
			left: 54vw;

		}
	}

	@media only screen and (max-width: 1300px) {
		#h22020 {
			left: 55vw;

		}
	}

	@media only screen and (max-width: 1200px) {
		#h22020 {
			left: 58vw;

		}
	}

	@media only screen and (max-width: 1100px) {
		#h22020 {
			left: 58vw;

		}
	}

	@media only screen and (max-width: 1000px) {
		#h22020 {
			left: 59vw;
		}

		h1 {
			left: 37vw;
		}
	}

	@media only screen and (max-width: 900px) {
		#h22020 {
			left: 61vw;

		}

		h1 {
			left: 35vw;
		}
	}


	@media only screen and (max-width: 800px) {
		#h22020 {
			left: 64vw;

		}

		h1 {
			left: 30vw;
		}
	}

	@media only screen and (max-width: 700px) {
		#h22020 {
			left: 66vw;

		}

		h1 {
			left: 27vw;
		}
	}

	@media only screen and (max-width: 600px) {
		#h22020 {
			left: 68vw;
		}

		h1 {
			left: 25vw;
		}
	}

	@media only screen and (max-width: 500px) {
		#h22020 {
			left: 70vw;

		}

		h1 {
			left: 19vw;
		}

		.sturaMitglieder{
			padding-left: 15px;
		}
	}

	@media only screen and (max-width: 400px) {
		#h22020 {
			left: 72vw;

		}

		h1 {
			left: 15vw;
			font-size: 70px!important;
		}
	}

	.sturaMgContainer {
		margin-top: 600px;
		/* height: 90vh; */
		width: 100%;
	}
</style>