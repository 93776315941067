<template>
	<div class="kulturflat-Login">
		<div class="card">
			<div class="card-body">
				<h1 class="card-title" data-aos="fade-left" data-aos-duration="1500">Login</h1>
				<div v-if="!$auth.loading">
					<!-- show login when not authenticated -->
					<button v-if="!this.$auth.isAuthenticated" @click="login">Log in</button>
					<!-- show logout when authenticated -->
					<button v-if="this.$auth.isAuthenticated" @click="logout">Log out</button>
				</div>
				<!-- <form id="kulturflatFormPartner" onSubmit="return false;"  data-aos="fade-right" data-aos-duration="1500" >
					<input id="user" type="text" class="form-control" placeholder="Nutzername" aria-label="Nutzername"
						aria-describedby="basic-addon1">
					<input id="pwd" type="text" class="form-control" placeholder="Passwort" aria-label="Code"
						aria-describedby="basic-addon1">
					<input type="button" class="btn" value="einloggen" v-on:click="checkForm()">
				</form> -->
			</div>
		</div>
	</div>
</template>

<script>
	import $ from "jquery";
	import axios from "axios";
	export default {
		name: 'KulturflatLogin',
		mounted() {
		},
		methods: {
			login() {
				this.$auth.loginWithRedirect();
			},
			// Log the user out
			logout() {
				this.$auth.logout({
					returnTo: window.location.origin
				});
			},
		}
	}
</script>

<style scoped>
	.kulturflat-Login {
		background-image: url('../assets/backgrounds/FlatBG.png');
		background-repeat: no-repeat;
		background-position-x: right;
		height: 100vh;
		text-align: center;
	}


	.card {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 30%;
		transform: translate(-50%, -50%);
		border-color: transparent;
		border-radius: 10px;
		-webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.75);
		-moz-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.75);
		box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
	}

	.btn {
		background-color: var(--primary-orange);
		color: white;
		width: 100%;
		margin-top: 20px;
		border-radius: 20px;
	}

	#user {
		margin-bottom: 20px;
	}
</style>