<template>
  <div class="kulturflat-partner">
    <div
      class="modal fade"
      id="failurePartner"
      tabindex="1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">
              Keine Institution gewählt!
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            Bitte wählen Sie Ihre Institution aus, um den Code einzulösen!
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Okay
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="flatCodeSuccess"
      tabindex="1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
      data-keyboard="false"
      data-backdrop="static"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">Glückwunsch!</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              v-on:click="reloadPage()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            Der Code wurde erfolgreich eingelöst!
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              v-on:click="reloadPage()"
              data-dismiss="modal"
            >
              Okay
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="flatCodeEingeloest"
      tabindex="1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">
              Code bereits eingelöst!
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            Der Code wurde bereits eingelöst. Bitte gehe auf
            <a href="vs-pforzheim.de">asta-pf.de</a>
            um einen neuen Code zu generieren!
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Okay
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="flatCodeNA"
      tabindex="1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">
              Code nicht vorhanden!
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            Der Code ist nicht vorhanden. Bitte gehe auf
            <a href="vs-pforzheim.de">asta-pf.de</a> um einen neuen Code zu
            generieren!
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Okay
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="card-container">
      <div class="card">
        <div class="card-body">
          <h1 class="card-title">Code-Eingabe</h1>
          <h4 class="card-subtitle mb-2 text-muted">
            Geben Sie den Code ein, um ihn zu verifizieren und einzulösen.
          </h4>
          <form id="kulturflatFormPartner" onSubmit="return false;">
            <select
              id="partner"
              class="form-select"
              aria-label="Default select example"
              required
            >
              <option selected>Wählen Sie Ihre Institution</option>
              <option value="Stadttheater Pforzheim"
                >Stadttheater Pforzheim</option
              >
              <option value="Kulturhaus Osterfeld">Kulturhaus Osterfeld</option>
              <option value="Mottenkäfig">Mottenkäfig</option>
              <option value="Figurentheater Raphael Mürle"
                >Figurentheater Raphael Mürle</option
              >
              <option value="Technisches Museum">Technisches Museum</option>
              <option value="Schmuckmuseum">Schmuckmuseum</option>
              <option value="Südwestdeutsches Kammerorchester"
                >Südwestdeutsches Kammerorchester</option
              >
              <option value="Kommunales Kino">Kommunales Kino</option>
            </select>
            <input
              id="Code"
              type="text"
              class="form-control"
              placeholder="Code eingeben"
              aria-label="Code"
              aria-describedby="basic-addon1"
            />
            <input
              type="button"
              class="btn"
              value="Code eingeben"
              v-on:click="checkForm()"
            />
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import axios from "axios";
export default {
  name: "kulturflatpartner",
  mounted() {
    var code = this.$route.query.code;
    if (code) {
      document.getElementById("Code").value = code;
    }
  },
  methods: {
    reloadPage() {
      location.reload();
    },
    checkForm() {
      let code = document.getElementById("Code").value;
      let partner = document.getElementById("partner").value;
      if (partner == "Wählen Sie Ihre Institution") {
        $("#failurePartner").modal("toggle");
      } else {
        var res = 0;
        axios
          .get("https://vs-pforzheim.de/flatHandler.php?request=getlist")
          .then((response) => {
            let responseArray = this.data_to_array(response.data);
            responseArray.forEach((element, index) => {
              if (element[0] == code && element[1] == null) {
                res = 1;
                $("#flatCodeSuccess").modal("toggle");
                this.sendCode(code, partner);
              } else if (element[0] == code && element[1] != null) {
                res = 2;
                $("#flatCodeEingeloest").modal("toggle");
              }
            });
            if (res == 0) {
              $("#flatCodeNA").modal("toggle");
            }
          });
      }
    },
    sendCode(code, partner) {
      axios
        .get(
          "https://vs-pforzheim.de/flatHandler.php?request=sendCode&code=" +
            code +
            "&partner=" +
            partner
        )
        .then((response) => {});
    },
    data_to_array(data) {
      var array = [];
      for (var i = 0; i < data.length; i++) {
        var obj = data[i];
        array[i] = new Array();
        for (var key in obj) {
          array[i].push(obj[key]);
        }
      }
      return array;
    },
  },
};
</script>

<style scoped>
.kulturflat-partner {
  background-image: url("../assets/backgrounds/FlatBG.png");
  background-repeat: no-repeat;
  background-position-x: right;
  min-height: 80vh;
  text-align: center;
}

@media only screen and (min-width: 601px) {
  .card {
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-color: transparent;
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  }
}

@media only screen and (max-width: 600px) {
  .card {
    position: relative;
    top: 50px;
    margin: auto;
    width: 90%;
    border-color: transparent;
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  }
}

.btn {
  background-color: var(--primary-orange);
  color: white;
  width: 100%;
  margin-top: 20px;
  border-radius: 20px;
}

select {
  width: 100%;
  margin: 20px 0px 20px 0px;
  border: 1px solid #ced4da;
  padding: 5px;
}
</style>
