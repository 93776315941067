<template>
	<div class="Gestaltung">
		<div class="gestaltungContainer">
			<h2 data-aos="flip-left" data-aos-duration="1500" data-aos-delay="1000">Fachschaften</h2>
			<h1 data-aos="flip-right" data-aos-duration="1500" data-aos-delay="700">
				Gestaltung
			</h1>
			<p data-aos="flip-up" data-aos-duration="1500" data-aos-delay="1500">
				Die Fachschaften der Fakultät für Gestaltung sind eine großartige Mischung aus allen möglichen
				Designbereichen wie Industrie, Automobil oder Mode. In der Holzgartenstraße kommen alle, die Talent und
				Kreativität zu bieten haben, zusammen um zu lernen. Alle Studierenden gemeinsam bilden die Fachschaft
				und der Fachschaftsrat, als gewähltes Gremium, vertritt die Studierenden und ihre Interessen.
			</p>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'wirtschaft',
		mounted() {},
		data() {
			return {}
		}
	}
</script>

<style scoped>
	.Gestaltung {
		background-color: var(--secondary-green);
		text-align: left;

	}

	.gestaltungContainer {
		background-color: white;
		top: 0;
		margin: 0px 75px 0px 75px;
		padding: 50px 50px 50px 50px;
		min-height: 500px;
	}

	h1 {
		font-family: var(--font-georgia);
		font-size: 60px;
	}

	h2 {
		font-family: var(--font4);
		font-size: 25px;
		color: var(--primary-orange);
	}

	p {
		font-size: 20px;
	}

	@media only screen and (max-width: 820px) {
		.gestaltungContainer {
			padding: 50px 75px 100px 75px;

		}
	}

	@media only screen and (max-width: 520px) {
		.gestaltungContainer {
			padding: 50px 55px 120px 55px;
			margin: 0px 55px 0px 55px;

		}
	}

	@media only screen and (max-width: 450px) {
		.gestaltungContainer {
			padding: 50px 35px 120px 35px;
			margin: 0px 55px 0px 55px;

		}
	}
</style>