<template>
    <div class="datenschutz">
        <div class="sidenav" data-aos="fade-right" data-aos-duration="1500" data-aos-delay="700">
      <a ><router-link class="router-link" :to="{ name: 'Startseite'}">Home</router-link></a>
      <a ><router-link class="router-link" href="https://asta-pforzheim.de" >AStA</router-link></a>
      <a ><router-link class="router-link" :to="{ name: 'Stura'}">StuRa</router-link></a>
      <a ><router-link class="router-link" :to="{ name: 'Fachschaften'}">Fachschaften</router-link></a>
    </div>
        <div class="header" data-aos="fade-in" data-aos-duration="1500" data-aos-delay="200">

        </div>
        <div class="card">
			<div class="card-body" data-aos="fade-right" data-aos-duration="1500" data-aos-delay="1500" >
                <h4>Haftungsausschluss, Rechtliche Hinweise, Datenschutz</h4>
                <h5 class="mt-2">Inhaltliche Verantwortung und Haftungsbeschränkung</h5>
                <p>Das Angebot dieser Website besteht zum Teil aus redaktionellen Inhalten. Diese
                    Inhalte wurden
                    mit größtmöglicher Sorgfalt erstellt. Der Anbieter übernimmt jedoch keine Gewähr für die
                    Richtigkeit,
                    Vollständigkeit und Aktualität der bereitgestellten Inhalte. Die Nutzung der Inhalte der Website
                    erfolgt auf
                    eigene Gefahr des Nutzers. Namentlich gekennzeichnete Beiträge geben die Meinung des jeweiligen
                    Autors und
                    nicht immer die Meinung des Anbieters wieder. Mit der reinen Nutzung der Website des Anbieters kommt
                    keinerlei Vertragsverhältnis zwischen dem Nutzer und dem Anbieter zustande.</p>
                <p>Bezüglich der redaktionellen Inhalte, die uns Partner zur Verfügung stellen und bezüglich von
                    Interessierten
                    präsentierten Informationen, Produkten und Dienstleistungen wird keine Gewähr für die Richtigkeit
                    übernommen
                    und jede Haftung ausgeschlossen.</p>
                <h5 class="mt-2">Externe Links</h5>
                <p>Diese Website enthält Verknüpfungen zu Websites Dritter ("externe Links"). Diese Websites unterliegen
                    der
                    Haftung der jeweiligen Betreiber. Die Allgemeiner Studierendenausschuss der Hochschule Pforzheim hat bei der erstmaligen Verknüpfung der
                    externen Links die fremden Inhalte daraufhin überprüft, ob etwaige Rechtsverstöße bestehen. Zu dem
                    Zeitpunkt
                    waren keine Rechtsverstöße ersichtlich. Der Anbieter hat keinerlei Einfluss auf die aktuelle und
                    zukünftige
                    Gestaltung und auf die Inhalte der verknüpften Seiten. Das Setzen von externen Links bedeutet nicht,
                    dass
                    sich der Anbieter die hinter dem Verweis oder Link liegenden Inhalte zu Eigen macht. Eine ständige
                    Kontrolle
                    der externen Links ist für den Anbieter ohne konkrete Hinweise auf Rechtsverstöße nicht zumutbar.
                    Bei
                    Kenntnis von Rechtsverstößen werden jedoch derartige externe Links unverzüglich gelöscht.</p>
                <h5>Urheber- und Leistungsschutzrechte
                </h5>
                <p>Die auf dieser Website veröffentlichten Inhalte unterliegen dem deutschen Urheber- und
                    Leistungsschutzrecht.
                    Jede vom deutschen Urheber- und Leistungsschutzrecht nicht zugelassene Verwertung bedarf der
                    vorherigen
                    schriftlichen Zustimmung des Anbieters oder jeweiligen Rechteinhabers. Dies gilt insbesondere für
                    Vervielfältigung, Bearbeitung, Übersetzung, Einspeicherung, Verarbeitung bzw. Wiedergabe von
                    Inhalten in
                    Datenbanken oder anderen elektronischen Medien und Systemen. Inhalte und Rechte Dritter sind dabei
                    als
                    solche gekennzeichnet. Die unerlaubte Vervielfältigung oder Weitergabe einzelner Inhalte oder
                    kompletter
                    Seiten ist nicht gestattet und strafbar. Lediglich die Herstellung von Kopien und Downloads für den
                    persönlichen, privaten und nicht kommerziellen Gebrauch ist erlaubt. Die Darstellung dieser Website
                    in
                    fremden Frames ist nur mit schriftlicher Erlaubnis zulässig.</p>
                <h5>Produkt- und Markennamen
                </h5>
                <p>Diese Website enthält unter Umständen Hinweise auf Produkt- und Markennamen anderer Firmen. Die
                    verwendeten
                    Begriffe sind Warenzeichen und/oder eingetragene Warenzeichen der entsprechenden Firmen.</p>
                <h5>Datenschutz Informationen
                </h5>
                <p>Sofern innerhalb des Internetangebotes die Möglichkeit zur Eingabe persönlicher oder geschäftlicher
                    Daten
                    (E-Mail Adressen, Namen, Anschriften) besteht, so erfolgt die Preisgabe dieser Daten seitens des
                    Nutzers auf
                    ausdrücklich freiwilliger Basis. Die Inanspruchnahme und Bezahlung aller angebotenen Dienste ist -
                    soweit
                    technisch möglich und zumutbar - auch ohne Angabe solcher Daten bzw. unter Angabe anonymisierter
                    Daten oder
                    eines Pseudonyms gestattet.
                </p>
                <p>Bei jedem Zugriff eines Nutzers auf eine Seite aus dem Angebot der Allgemeiner Studierendenausschuss der Hochschule Pforzheim und bei
                    jedem
                    Abruf einer Datei werden Daten über diesen Vorgang in einer Protokolldatei gespeichert.
                </p>
                <p>Im einzelnen wird über jeden Abruf folgender Datensatz gespeichert:
                </p>
                <ul>
                    <li>Name der abgerufenen Datei,</li>
                    <li>Datum und Uhrzeit des Abrufs,</li>
                    <li>übertragene Datenmenge,</li>
                    <li>Referrer und Herkunft des Benutzers</li>
                    <li>Meldung, ob der Abruf erfolgreich war.</li>
                    <li>Es wird ein Cookie gesetzt um wiederkehrende Besucher zu erkennen.</li>
                </ul>
                <p>Zusätzlich wird die IP-Adresse des Rechners, von dem die Anfrage abgeschickt wurde, gespeichert.</p>
                <p>Die gespeicherten Daten werden zu statistischen Zwecken der Webanalyse und des Webcontrollings
                    ausgewertet
                    und zur kontinuierlichen Verbesserung der Webseiten und des Marketing und des Vertriebs der 
                    Allgemeiner Studierendenausschuss der Hochschule Pforzheim verwendet. Eine Weitergabe an Dritte findet nicht statt außer im Falle von Google
                    Analytics siehe
                    folgender Zusatz Absatz.</p>
                <p>Diese Website benutzt zusätzlich Google Analytics, einen Webanalysedienst der Google Inc. ("Google")
                    Google
                    Analytics verwendet sog. "Cookies", Textdateien, die auf Ihrem Computer gespeichert werden und die
                    eine
                    Analyse der Benutzung der Website durch Sie ermöglicht. Die durch den Cookie erzeugten Informationen
                    über
                    Ihre Benutzung dieser Website (einschließlich Ihrer IP-Adresse) wird an einen Server von Google in
                    den USA
                    übertragen und dort gespeichert. Google wird diese Informationen benutzen, um Ihre Nutzung der
                    Website
                    auszuwerten, um Reports über die Websiteaktivitäten für die Allgemeiner Studierendenausschuss der Hochschule Pforzheim
                    zusammenzustellen und
                    um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen zu erbringen.
                    Auch
                    wird Google diese Informationen gegebenenfalls an Dritte übertragen, sofern dies gesetzlich
                    vorgeschrieben
                    oder soweit Dritte diese Daten im Auftrag von Google verarbeiten. Google wird nach Aussagen von
                    Google in
                    keinem Fall Ihre IP-Adresse mit anderen Daten der Google in Verbindung bringen. Sie können die
                    Installation
                    der Cookies durch eine entsprechende Einstellung Ihrer Browser Software verhindern; wir weisen Sie
                    jedoch
                    darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website voll
                    umfänglich
                    nutzen können.</p>
                <p>Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der
                    Website
                    bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google
                    verhindern, indem sie das unter dem folgenden Link (<a
                        href="http://tools.google.com/dlpage/gaoptout?hl=de">http://tools.google.com/dlpage/gaoptout?hl=de</a>)
                    verfügbare Browser-Plugin herunterladen und installieren.</p>
                <p>Nähere Informationen hierzu finden Sie unter http://tools.google.com/dlpage/gaoptout?hl=de bzw. unter
                    <a
                        href="http://www.google.de/analytics/terms/de.htm">http://www.google.de/analytics/terms/de.html</a>
                    (allgemeine Informationen zu Google Analytics und Datenschutz). Wir weisen Sie darauf hin, dass auf
                    dieser
                    Webseite Google Analytics um den Code "gat._anonymizeIp();" erweitert wurde, um eine anonymisierte
                    Erfassung
                    von IP-Adressen (sog. IP-Masking) zu gewährleisten.</p>
                <p>Sie können die Erfassung durch Google Analytics verhindern, indem Sie auf folgenden Link klicken. Es
                    wird ein
                    Opt-Out-Cookie gesetzt, der die zukünftige Erfassung Ihrer Daten beim Besuch dieser Website
                    verhindert:
                    Google Analytics deaktivieren</p>
                <p>Durch die Nutzung dieser Website erklären Sie sich mit der Bearbeitung der über Sie erhobenen Daten
                    durch uns
                    und durch Google sowie in der zuvor beschriebenen Art und Weise und zu dem zuvor benannten Zweck
                    einverstanden.</p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {

    }
</script>

<style scoped>

ul{
    padding-left: 2em;
}
.datenschutz{
    text-align: left;
		
    margin-top: -200px;
    padding-top: 200px
}
h4{
    color: var(--primary-orange);
}

.header{
    background-image: url('../assets/backgrounds/pexels-kaboompics-com-5836.jpg');
    height: 500px;
    background-size: 100%;
}
.card{
    margin: -100px 200px 150px 200px;
    border-color: transparent;
		border-radius: 10px;
		-webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.75);
		-moz-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.75);
		box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
}
/* The sidebar menu */
  .sidenav {
    height: 100%;
    /* Full-height: remove this if you want "auto" height */
    position: fixed;
    /* Fixed Sidebar (stay in place on scroll) */
    z-index: 1;
    /* Stay on top */
    top: 20px;

    /* Stay at the top */
    left: 0;
    /* Disable horizontal scroll */
    cursor: pointer;
  }

  /* The navigation menu links */
  .sidenav a {
    padding: 6px 8px 6px 16px;
    text-decoration: none;
    font-size: 20px;
    color: gray;
    display: block;
    writing-mode: vertical-rl;
    text-orientation: mixed;
    font-family: montserrat, sans-serif;
    font-weight: 200;
    font-style: normal;
  }

  /* When you mouse over the navigation links, change their color */
  .sidenav a:hover {
    color: var(--primary-orange);
  }

  /* Style page content */
  .main {
    margin-left: 160px;
    /* Same as the width of the sidebar */
    padding: 0px 10px;
  }

  /* On smaller screens, where height is less than 450px, change the style of the sidebar (less padding and a smaller font size) */
  @media screen and (max-height: 450px) {
    .sidenav {
      padding-top: 15px;
    }

    .sidenav a {
      font-size: 18px;
    }
  }


	@media only screen and (max-width: 750px) {
		.card{
    margin: -100px 50px 150px 75px;
        }
.header{
    background-image: url('../assets/backgrounds/pexels-kaboompics-com-5836.jpg');
    height: 500px;
    background-size: 1020px;
}
	}
</style>