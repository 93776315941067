<template>
    <div class="footer" id="footer1">
        <div class="row1footer">
            <div class="footerContainer">
                <h1 class="headingFooter" >
                    Links
                </h1>
                <router-link class="router-link" :to="{ name: 'Startseite'}">Startseite</router-link><br>
                <router-link class="router-link" href="https://asta-pforzheim.de" >AStA</router-link><br>
                <router-link class="router-link" :to="{ name: 'Stura'}">StuRa</router-link><br>
                <router-link class="router-link" :to="{ name: 'Fachschaften'}">Fachschaften</router-link>
            </div>
            <div class="footerContainer FooterFragen" id="">
                <h1 class="headingFooter">
                    Du hast Fragen?
                </h1>
                <ul style="list-style:none">
                    <li>
                        <i class="fa fa-phone">
                        </i>
                        <a>Tel.: 0 72 31 / 28 62 63</a>
                    </li>
                    <li>
                        <i class="fa fa-envelope">
                        </i>
                        <a class="footerMail" href="mailto:asta@hs-pforzheim.de">
                            E-Mail: asta@hs-pforzheim.de
                        </a>
                    </li>
                </ul>
            </div>
            <div class="footerContainer">
                <a href="https://vs-pforzheim.de"><img src="@/assets/bilder/asta-Logo.png"></a>

            </div>
        </div>
        <div class="row2">
            <hr>
        </div>
        <div class="row3">
            <div class="footerContainer2">
                <div class="copyright" style="color:gray">
                    &copy; {{year}} <a href="#" style="color:gray">Zoller-Design</a>
                </div>
            </div>
            <div class="footerContainer3">
                <router-link class="router-link2" :to="{ name: 'Datenschutz'}">Datenschutz</router-link>
                <router-link class="router-link2" :to="{ name: 'Impressum'}">Impressum</router-link>
                 <router-link class="router-link2" :to="{ name: 'DBAnpassung'}">Login</router-link>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                year: new Date().getFullYear()
            }
        }
    }
</script>

<style>
    #footer1 {
        padding: 20px 10vw 20px 10vw;
        background-color: #212629;
        color: white;
    }

    #footer1 .FooterFragen{
        text-align: center;
    }

    #footer1 .footerContainer img{
        max-width: 15vw;
        float: right;
    }

     #footer1 .footerContainer img{
        max-width: 15vw;
        float: right;
    }

    .row1footer {
        display: flex;
    }

    #footer1  .footerContainer {
        width: 100%;
        text-align: left;
    }

    .footerContainer2 {
        flex-grow: 3;
    }

    .headingFooter {
        font-size: 20px;
    }

    .router-link, .footerMail {
        color: white;
    }

    .router-link2 {
        color: gray;
        padding-left: 5px;
    }

    hr {
        border: 1px solid gray!important;
    }

    .row3 {
        text-align: left;
        display: flex;
    }
</style>