<template>
  <div class="stura" >
    <div id="mitgliederStura"></div>
    <SturaMitglieder ></SturaMitglieder>
    <NewsStura ></NewsStura>
    <Satzungen></Satzungen>
    <Protokolle></Protokolle>
    <Kontakt></Kontakt>
    <div class="sidenav" data-aos="fade-right" data-aos-duration="1500" data-aos-delay="700">
      <router-link id="nav-home" class="router-link" to="/"><span>Home</span></router-link>
      <a id="nav-mitglieder" href="#mitgliederStura">Mitglieder</a>
      <a id="nav-news-stura" href="#infoStura">Infos</a>
      <a id="nav-satzungen" href="#satzungenStura">Satzungen</a>
      <a id="nav-protokolle" href="#protokolleStura">Protokolle</a>
      <a id="nav-kontakt-stura" href="#kontaktStura">Kontakt</a>
    </div>
  </div>
</template>

<script>
  import Kontakt from '@/views/stura/KontaktStura'
  import SturaMitglieder from '@/views/stura/SturaMitglieder'
  import NewsStura from '@/views/stura/NewsStura'
  import Protokolle from '@/views/stura/Protokolle'
  import Satzungen from '@/views/stura/Satzungen'


  export default {
    name: 'Stura',
    components: {
      Kontakt,
      SturaMitglieder,
      NewsStura,
      Protokolle,
      Satzungen
    },
    mounted() {
      const mount = document.querySelector('#mitgliederStura');
      const navMGs = document.getElementById('nav-mitglieder');

      const infos = document.querySelector('#infoStura');
      const navInfo = document.getElementById('nav-news-stura');

      const satzungen = document.querySelector('#satzungenStura');
      const navSatzungen = document.getElementById('nav-satzungen');

      const protokoll = document.querySelector('#protokolleStura');
      const navProtokoll = document.getElementById('nav-protokolle');

      const Kontakt = document.querySelector('#kontaktStura');
      const navKontakt = document.getElementById('nav-kontakt-stura');
      const ref = this

      const views = [
                      mount, infos, satzungen, protokoll, Kontakt
                    ]

      const navs = [
                    navMGs, navInfo, navSatzungen, navProtokoll, navKontakt
                    ]

      if (ref.isInViewport(mount)) {
        navMGs.style.color = "var(--primary-orange)"
        navMGs.style.textDecoration = "underline"
      }

      document.addEventListener('scroll', function () {
        for (var i = 0; i < views.length; i++) {

          if (ref.isInViewport(views[i])) {

            navs[i].style.color = "var(--primary-orange)"
            navs[i].style.textDecoration = "underline"

            let cloneArray = navs.except(navs[i])
            for (var i = 0; i < cloneArray.length; i++) {
              cloneArray[i].style.color = "gray"
              cloneArray[i].style.textDecoration = "none"
              cloneArray[i].style
            }
          }
        }
      });

      Array.prototype.except = function (val) {
        return this.filter(function (x) {
          return x !== val
        })
      }

    },
    methods: {
      isInViewport(el) {
        const rect = el.getBoundingClientRect()
        return (
          rect.top >= 0 &&
          rect.left >= 0 &&
          rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
          rect.right <= (window.innerWidth || document.documentElement.clientWidth)

        )
      }
    },
    data() {
      return {}
    }
  }
</script>

<style scoped>
  /* The sidebar menu */
  .sidenav {
    height: 100%;
    /* Full-height: remove this if you want "auto" height */
    position: fixed;
    /* Fixed Sidebar (stay in place on scroll) */
    z-index: 10;
    /* Stay on top */
    top: 20px;

    /* Stay at the top */
    left: 0;
    /* Disable horizontal scroll */
    cursor: pointer;
  }

  /* The navigation menu links */
  .sidenav a {
    padding: 6px 8px 6px 16px;
    text-decoration: none;
    font-size: 20px;
    color: gray;
    display: block;
    writing-mode: vertical-rl;
    text-orientation: mixed;
    font-family: montserrat, sans-serif;
    font-weight: 200;
    font-style: normal;
  }

  /* When you mouse over the navigation links, change their color */
  .sidenav a:hover {
    color: var(--primary-green);
  }

  /* Style page content */
  .main {
    margin-left: 160px;
    /* Same as the width of the sidebar */
    padding: 0px 10px;
  }

  /* On smaller screens, where height is less than 450px, change the style of the sidebar (less padding and a smaller font size) */
  @media screen and (max-height: 450px) {
    .sidenav {
      padding-top: 15px;
    }

    .sidenav a {
      font-size: 18px;
    }
  }
</style>