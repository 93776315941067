<template>
	<navbar></navbar>

</template>

<script>
import navbar from '@/components/NavBarDashboard'
	export default {
		components:{
			navbar
		}
		
	}
</script>

<style scoped>
	.dashboard{
		height: 100vh;
		background-image: url('../assets/backgrounds/BG-2.jpg');
	}
</style>