<template>
  <div class="grid">
    <div class="overlay"></div>
    <div class="spanner">
      <div class="loader"></div>
    </div>
    <div
      class="modal fade"
      id="erstellenPopup"
      tabindex="1"
      style=""
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <table>
              <tr>
                <td>
                  <h3 class="modal-title" id="popupTitle"></h3>
                </td>
              </tr>
              <tr>
                <td>
                  <h5 class="text-muted" id="popupDate"></h5>
                </td>
              </tr>
            </table>

            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <table class="table" id="DBTable">
              <thead
                data-aos="fade-up"
                data-aos-duration="1500"
                data-aos-delay="1000"
                class="thead-light"
              >
                <tr>
                  <th>
                    ID
                  </th>
                  <th>
                    Medien
                  </th>
                  <th>
                    Titel
                  </th>
                  <th>
                    Datum
                  </th>
                  <th>
                    Text
                  </th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody
                data-aos="flip-left"
                data-aos-duration="1500"
                id="tableBody"
              ></tbody>
            </table>
            <form ref="form" id="uploadForm" onsubmit="return false">
              <div class="form-row">
                <div class="form-group col-1" id="IDCol" style="display: block">
                  <label for="ID">ID</label>
                  <input
                    type="ID"
                    class="form-control"
                    id="ID"
                    aria-describedby="nameHelp"
                    placeholder="Reihenfolge"
                  />
                  <small id="emailHelp" class="form-text text-muted"></small>
                </div>
                <div class="form-group col" id="titleCol">
                  <label for="titel">Titel</label>
                  <input
                    type="titel"
                    class="form-control"
                    id="titel"
                    aria-describedby="nameHelp"
                    placeholder="Gib den Titel ein"
                  />
                  <small id="emailHelp" class="form-text text-muted"></small>
                </div>
                <div class="form-group col" id="menuCol">
                  <label for="menu">Menü</label>
                  <input
                    type="menu"
                    class="form-control"
                    id="menu"
                    aria-describedby="nameHelp"
                    placeholder="Gib den Titel des Navigationsmenüs ein"
                  />
                  <small id="emailHelp" class="form-text text-muted"></small>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col" id="menuSelectCol">
                  <label for="menuSelect">Menü</label>
                  <select
                    type="menuSelect"
                    class="form-control"
                    id="menuSelect"
                    aria-describedby="nameHelp"
                    placeholder="Gib den Titel des Navigationsmenüs ein"
                    style="margin-top:0pc;"
                  >
                    <option value="Band & Orchester">Band & Orchester</option>
                    <option value="Studiticket">Studiticket</option>
                    <option value="Parkplätze">Parkplätze</option>
                    <option value="Wohnungsmarkt">Wohnungsmarkt</option>
                    <option value="BAföG">BAföG</option>
                    <option value="Minijobs">Minijobs</option>
                  </select>
                  <small id="emailHelp" class="form-text text-muted"></small>
                </div>
                <div class="form-group col" id="dateCol">
                  <label for="datum">Datum</label>
                  <input
                    type="datum"
                    class="form-control"
                    id="datum"
                    aria-describedby="emailHelp"
                    placeholder="Gib das Datum (dd.mm.YYYY - z.B. 01.01.2021) ein"
                  />
                  <small id="emailHelp" class="form-text text-muted"></small>
                </div>
                <div class="form-group col" id="emailCol">
                  <label for="email">Email</label>
                  <input
                    type="email"
                    class="form-control"
                    id="email"
                    aria-describedby="emailHelp"
                    placeholder="Gib deine Email ein"
                  />
                  <small id="emailHelp" class="form-text text-muted"></small>
                </div>
                <div class="form-group col" id="nameCol">
                  <label for="name">Name</label>
                  <input
                    type="name"
                    class="form-control"
                    id="name"
                    aria-describedby="emailHelp"
                    placeholder="Gib deinen Namen ein"
                  />
                  <small id="emailHelp" class="form-text text-muted"></small>
                </div>
              </div>
              <div class="form-group" id="textCol">
                <label for="text">Text</label>
                <textarea
                  type="text-area"
                  class="form-control"
                  id="text"
                  aria-describedby="MessageHelp"
                  placeholder="Gib den Text ein"
                ></textarea>
                <small id="MessageHelp" class="form-text text-muted"></small>
              </div>
              <div class="form-row">
                <div class="form-group col-12" id="shortTextCol">
                  <label for="shortText">Text</label>
                  <textarea
                    type="shortText"
                    class="form-control"
                    id="shortText"
                    aria-describedby="nameHelp"
                    placeholder="Gib einen Text ein"
                  ></textarea>
                  <small id="emailHelp" class="form-text text-muted"></small>
                </div>
                <div class="form-group col-6" id="longTextCol">
                  <label for="longText">Text</label>
                  <textarea
                    type="longText"
                    class="form-control"
                    id="longText"
                    aria-describedby="emailHelp"
                    placeholder="Gib den Haupttext ein"
                  ></textarea>
                  <small id="emailHelp" class="form-text text-muted"></small>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-6" id="linkCol">
                  <label for="Link">Link</label>
                  <input
                    type="Link"
                    class="form-control"
                    id="Link"
                    aria-describedby="nameHelp"
                    placeholder="Füge einen Hyperlink ein"
                  />
                  <small id="emailHelp" class="form-text text-muted"></small>
                </div>
                <div class="form-group col-6" id="linkTextCol">
                  <label for="linkText">Link Text</label>
                  <input
                    type="linkText"
                    class="form-control"
                    id="linkText"
                    aria-describedby="emailHelp"
                    placeholder="Gib den Text ein, mit dem der Hyperlink angezeigt werden soll."
                  />
                  <small id="emailHelp" class="form-text text-muted"></small>
                </div>
              </div>
              <div id="mediaCol">
                <div class="row">
                  <hr />
                  <h5>Medien</h5>
                </div>
                <div class="row">
                  <select
                    class="btn select"
                    id="mediaSelect"
                    aria-label="Default select example"
                  >
                    <option selected value="">Wähle eine Medienquelle</option>
                    <option value="yt">YouTube</option>
                    <option value="image">Bild-Upload</option>
                  </select>
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col" id="ytCol">
                  <label for="YT">YouTube-Link</label>
                  <input
                    type="YT"
                    class="form-control"
                    id="YT"
                    aria-describedby="nameHelp"
                    placeholder="Gib den Youtube Link ein"
                  />
                  <small id="emailHelp" class="form-text text-muted"></small>
                </div>
                <div class="form-group col" id="imageCol">
                  <div class="container" id="uploadApp">
                    <br />
                    <br />

                    <div
                      v-if="errorAlert"
                      class="alert alert-danger alert-dismissible"
                    >
                      <a
                        href="#"
                        class="close"
                        aria-label="close"
                        @click="errorAlert = false"
                        >&times;</a
                      >
                      {{ errorMessage }}
                    </div>
                    <div class="panel panel-default">
                      <div class="panel-heading"></div>
                      <div class="panel-body">
                        <div class="row">
                          <p style="margin: auto">
                            Wähle ein Bild / eine Datei aus
                          </p>
                        </div>
                        <div class="row">
                          <input
                            style="margin: auto; margin-bottom: 30px"
                            type="file"
                            ref="file"
                          />
                        </div>
                        <div class="row">
                          <button
                            style="margin: auto"
                            type="button"
                            @click="uploadImage"
                            class="btn btn-primary"
                          >
                            Upload
                          </button>
                        </div>
                        <div class="row">
                          <svg
                            id="thumb"
                            xmlns="http://www.w3.org/2000/svg"
                            width="50"
                            height="50"
                            fill="green"
                            class="bi bi-hand-thumbs-up"
                            viewBox="0 0 16 16"
                          >
                            <path
                              d="M8.864.046C7.908-.193 7.02.53 6.956 1.466c-.072 1.051-.23 2.016-.428 2.59-.125.36-.479 1.013-1.04 1.639-.557.623-1.282 1.178-2.131 1.41C2.685 7.288 2 7.87 2 8.72v4.001c0 .845.682 1.464 1.448 1.545 1.07.114 1.564.415 2.068.723l.048.03c.272.165.578.348.97.484.397.136.861.217 1.466.217h3.5c.937 0 1.599-.477 1.934-1.064a1.86 1.86 0 0 0 .254-.912c0-.152-.023-.312-.077-.464.201-.263.38-.578.488-.901.11-.33.172-.762.004-1.149.069-.13.12-.269.159-.403.077-.27.113-.568.113-.857 0-.288-.036-.585-.113-.856a2.144 2.144 0 0 0-.138-.362 1.9 1.9 0 0 0 .234-1.734c-.206-.592-.682-1.1-1.2-1.272-.847-.282-1.803-.276-2.516-.211a9.84 9.84 0 0 0-.443.05 9.365 9.365 0 0 0-.062-4.509A1.38 1.38 0 0 0 9.125.111L8.864.046zM11.5 14.721H8c-.51 0-.863-.069-1.14-.164-.281-.097-.506-.228-.776-.393l-.04-.024c-.555-.339-1.198-.731-2.49-.868-.333-.036-.554-.29-.554-.55V8.72c0-.254.226-.543.62-.65 1.095-.3 1.977-.996 2.614-1.708.635-.71 1.064-1.475 1.238-1.978.243-.7.407-1.768.482-2.85.025-.362.36-.594.667-.518l.262.066c.16.04.258.143.288.255a8.34 8.34 0 0 1-.145 4.725.5.5 0 0 0 .595.644l.003-.001.014-.003.058-.014a8.908 8.908 0 0 1 1.036-.157c.663-.06 1.457-.054 2.11.164.175.058.45.3.57.65.107.308.087.67-.266 1.022l-.353.353.353.354c.043.043.105.141.154.315.048.167.075.37.075.581 0 .212-.027.414-.075.582-.05.174-.111.272-.154.315l-.353.353.353.354c.047.047.109.177.005.488a2.224 2.224 0 0 1-.505.805l-.353.353.353.354c.006.005.041.05.041.17a.866.866 0 0 1-.121.416c-.165.288-.503.56-1.066.56z"
                            />
                          </svg>
                        </div>

                        <br />
                        <br />
                        <div id="uploadedImage" align="center"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button
                v-on:click="submit"
                class="btn"
                type="submit"
                id="submitBtnPopup"
              >
                Erstellen
              </button>
            </form>
            <p id="popupEmail" style="display: none"></p>
            <p id="popupType" style="display: none"></p>
            <p id="popupDB" style="display: none"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
export default {
  components: {},
  data: {
    file: "",

    errorAlert: false,
    uploadedImage: "",
    Menu: "",
    title: "",
    YT: "",
    text: "",
    linkText: "",
    Link: "",
    email: "",
    datum: "",
    DB: "",
    Image: "",
    Name: "",
    type: "",
    mailCreator: "",
    shortText: "",
    longText: "",
    imagePath: "",
    request: "",
    id: "",
    maxTextLength: 0,
    changeEntry: false,
    DataArray: [],
  },
  mounted: async function() {
    var ref = this;

    ref.Menu = "";
    (ref.maxTextLength = 100), (ref.title = "");
    ref.text = "";
    ref.YT = "";
    ref.linkText = "";
    ref.Link = "";
    ref.email = "";
    ref.datum = "";
    ref.DB = "";
    ref.Image = "";
    ref.type = "";
    ref.mailCreator = "";
    ref.shortText = "";
    ref.longText = "";
    ref.imagePath = "";
    ref.Name = "";
    ref.request = "";
    ref.id = "";
    ref.changeEntry = false;
    ref.errorAlert = false;
    ref.DataArray = [];

    var mediaSelect = document.getElementById("mediaSelect");
    mediaSelect.onchange = (event) => {
      document.getElementById("YT").value = "";

      if ("yt" == event.target.value) {
        document.getElementById("ytCol").style.display = "block";
        document.getElementById("imageCol").style.display = "none";
        document.getElementById("uploadedImage").innerHTML = "";

        ref.Image = "";
      } else {
        document.getElementById("YT").value = "";
        document.getElementById("ytCol").style.display = "none";
        document.getElementById("imageCol").style.display = "block";
      }
    };
    $("#erstellenPopup").on("hidden.bs.modal", function(e) {
      ref.Menu = "";
      ref.title = "";
      ref.text = "";
      ref.YT = "";
      ref.linkText = "";
      ref.Link = "";
      ref.email = "";
      ref.datum = "";
      ref.DB = "";
      ref.Image = "";
      ref.type = "";
      ref.mailCreator = "";
      ref.shortText = "";
      ref.longText = "";
      ref.imagePath = "";
      ref.Name = "";
      ref.request = "";
      ref.id = "";
      ref.changeEntry = false;
      ref.errorAlert = false;
      ref.DataArray = [];
    });
    $(window).on("shown.bs.modal", function() {
      ref.getDataFromDB();
    });
  },
  methods: {
    getDataFromDB() {
      this.DB = document.getElementById("popupDB").innerText;
      this.getDB();
      document.getElementById("tableBody").innerHTML = "";
      var ref = this;
      var axios = require("axios");
      var data = JSON.stringify({
        username: "web163_2",
        pw: "Pf0rzelona",
        request: this.request,
      });

      console.log(data);

      var config = {
        method: "post",
        url: "https://vs-pforzheim.de/getHandler.php",
        headers: {
          "Content-Type": "this/json",
        },
        data: data,
      };

      return axios(config)
        .then(function(response) {
          response.data.forEach((element, index) => {
            var tmpArray = new Map();
            tmpArray.set("id", element["id"]);
            tmpArray.set("index", index);
            tmpArray.set("type", element["type"]);
            tmpArray.set("name", element["name"]);
            tmpArray.set("email", element["email"]);
            tmpArray.set("email_creator", element["email_creator"]);
            tmpArray.set("longText", element["longText"]);
            tmpArray.set("shortText", element["shortText"]);
            tmpArray.set("menu", element["menu"]);
            tmpArray.set("title", element["title"]);
            tmpArray.set("date", element["date"]);
            tmpArray.set("text", element["text"]);
            tmpArray.set("link", element["link"]);
            tmpArray.set("linkText", element["linkText"]);
            tmpArray.set("image", element["image"]);
            tmpArray.set("video", element["video"]);
            if (ref.type != "") {
              if (ref.type == tmpArray.get("type")) {
                ref.updateUI(tmpArray, ref.getDB);
              }
            } else {
              ref.updateUI(tmpArray, ref.getDB);
            }
          });
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    updateUI(array) {
      var tr = document.createElement("tr");
      tr.id = "dataTR" + array.get("index");
      tr.style.width = "100%";
      document.getElementById("tableBody").appendChild(tr);

      var tdID = document.createElement("td");
      tdID.innerText = array.get("id");
      document.getElementById("dataTR" + array.get("index")).appendChild(tdID);

      var tdImg = document.createElement("td");
      tdImg.id = "tdImg" + array.get("index");
      document.getElementById("dataTR" + array.get("index")).appendChild(tdImg);

      if (array.get("image") != "") {
        var mediaType = array.get("image").slice(-3);
        if (mediaType == "pdf" || mediaType == "PDF") {
          var pdfLink = document.createElement("a");
          pdfLink.innerText = "Öffne PDF";
          pdfLink.target = "_blank";
          pdfLink.href = "https://vs-pforzheim.de/" + array.get("image");
          document
            .getElementById("tdImg" + array.get("index"))
            .appendChild(pdfLink);
        } else {
          var image_html = document.createElement("img");
          image_html.style.width = "200px";
          image_html.src = "https://vs-pforzheim.de/" + array.get("image");
          document
            .getElementById("tdImg" + array.get("index"))
            .appendChild(image_html);
        }
      }
      if (array.get("video") != "") {
        var video = document.createElement("iframe");
        video.style.width = "200px";
        video.src = array.get("video");
        document
          .getElementById("tdImg" + array.get("index"))
          .appendChild(video);
      }

      var tdTitle = document.createElement("td");
      tdTitle.innerText = array.get("title");
      document
        .getElementById("dataTR" + array.get("index"))
        .appendChild(tdTitle);

      var tdDate = document.createElement("td");
      tdDate.innerText = array.get("date");
      document
        .getElementById("dataTR" + array.get("index"))
        .appendChild(tdDate);

      var text = document.createElement("td");
      text.innerText = array.get("text");
      if (array.get("shortText") != "") {
        text.innerText = array.get("shortText");
      }
      if (text.innerText.length > this.maxTextLength) {
        text.innerText =
          text.innerText.substring(0, this.maxTextLength) + "...";
      }
      document.getElementById("dataTR" + array.get("index")).appendChild(text);
      var deleteTd = document.createElement("td");
      deleteTd.id = "tdDelete" + array.get("index");
      document
        .getElementById("dataTR" + array.get("index"))
        .appendChild(deleteTd);

      var changeBtn = document.createElement("div");
      changeBtn.classList = "btn";
      changeBtn.id = "changeBtn" + array.get("index");
      var ref = this;
      changeBtn.style.cssText =
        "background-color: #343A3F; color: white; transition: all 0.5s; border-radius: 40px; margin: 20px";
      changeBtn.textContent = "Ändern";
      document
        .getElementById("tdDelete" + array.get("index"))
        .appendChild(changeBtn);
      document
        .getElementById("changeBtn" + array.get("index"))
        .addEventListener("click", async function() {
          ref.changeEntry = true;
          ref.id = array.get("id");
          document.getElementById("DBTable").style.display = "none";
          document.getElementById("uploadForm").style.display = "block";
          document.getElementById("menu").value = array.get("menu");
          document.getElementById("menuSelect").value = array.get("menu");
          document.getElementById("text").value = array.get("text");
          document.getElementById("name").value = array.get("name");
          document.getElementById("shortText").value = array.get("shortText");
          document.getElementById("longText").value = array.get("longText");
          document.getElementById("titel").value = array.get("title");
          document.getElementById("ID").value = array.get("id");
          document.getElementById("datum").value = array.get("date");
          document.getElementById("email").value = array.get("email");
          document.getElementById("Link").value = array.get("link");
          document.getElementById("linkText").value = array.get("linkText");

          if (array.get("video") != "") {
            document
              .getElementById("mediaSelect")
              .getElementsByTagName("option")[1].selected = "selected";
            document.getElementById("ytCol").style.display = "block";
          }

          document.getElementById("YT").value = array.get("video");
          document.getElementById("popupDB").innerText = ref.DB;

          if (array.get("image") != "") {
            document
              .getElementById("mediaSelect")
              .getElementsByTagName("option")[2].selected = "selected";
            document.getElementById("imageCol").style.display = "block";
            var image_html = document.createElement("img");
            image_html.id = "uploadImage";
            image_html.style.width = "300px";
            image_html.src = "https://vs-pforzheim.de/" + array.get("image");
            document.getElementById("uploadedImage").appendChild(image_html);

            ref.Image = array.get("image");
          }
        });

      var deleteBtn = document.createElement("div");
      deleteBtn.classList = "btn";
      deleteBtn.id = "deleteBtn" + array.get("index");

      deleteBtn.style.cssText =
        "background-color: red; color: white; transition: all 0.5s; border-radius: 40px; margin: 20px;";
      deleteBtn.textContent = "Löschen";
      document
        .getElementById("tdDelete" + array.get("index"))
        .appendChild(deleteBtn);
      document
        .getElementById("deleteBtn" + array.get("index"))
        .addEventListener("click", async function() {
          await ref.deleteEntry(ref.DB, array.get("id"));
          ref.getDataFromDB();
        });
    },
    deleteEntry(DB, ID) {
      var ref = this;
      var axios = require("axios");
      var data = JSON.stringify({
        username: "web163_2",
        pw: "Pf0rzelona",
        request: "delete_entry",
        DB: DB,
        ID: ID,
      });

      var config = {
        method: "post",
        url: "https://vs-pforzheim.de/deleteHandler.php",
        headers: {
          "Content-Type": "this/json",
        },
        data: data,
      };
      return axios(config);
    },
    submit: function() {
      this.startUpload();
    },
    async startUpload() {
      this.loadData();
      this.getDB();
      await this.uploadData();
      // location.reload()
    },
    loadData() {
      this.Menu = document.getElementById("menu").value;
      if (
        document.getElementById("menu").value == "" &&
        this.request == "get_info_asta"
      ) {
        this.Menu = document.getElementById("menuSelect").value;
      }
      this.text = document.getElementById("text").value;
      this.text = this.text.replace(/\n\r?/g, "<br>");

      this.name = document.getElementById("name").value;
      this.shortText = document.getElementById("shortText").value;
      this.longText = document.getElementById("longText").value;
      this.shortText = this.shortText.replace(/\n\r?/g, "<br>");
      this.longText = this.longText.replace(/\n\r?/g, "<br>");

      this.title = document.getElementById("titel").value;
      this.datum = document.getElementById("datum").value;
      this.email = document.getElementById("email").value;
      this.Link = document.getElementById("Link").value;
      this.linkText = document.getElementById("linkText").value;
      if (document.getElementById("YT").value != "") {
        this.YT =
          "https://www.youtube.com/embed/" +
          this.getId(document.getElementById("YT").value);
      }

      this.DB = document.getElementById("popupDB").innerText;
    },
    getDB() {
      switch (this.DB) {
        case "Startseite - News":
          this.DB = "NewsHome";
          this.type = "news";
          this.request = "get_news_home";
          break;
        case "Startseite - News - Alle Veranstaltungen":
          this.DB = "NewsHome";
          this.type = "veranstaltungen";
          this.request = "get_news_home";
          break;
        case "Startseite - News - AStA":
          this.DB = "NewsHome";
          this.type = "asta";
          this.request = "get_news_home";

          break;
        case "Startseite - News - Initiativen":
          this.DB = "NewsHome";
          this.type = "init";
          this.request = "get_news_home";

          break;
        case "Startseite - Aushang":
          this.DB = "AushangHome";
          this.request = "get_aushang_home";

          break;
        case "Startseite - Galerie":
          this.DB = "Gallery";
          this.type = "home";
          this.request = "get_images_home";

          break;
        case "Referate - AStA":
          this.DB = "Referate";
          this.request = "get_referate";

          break;
        case "Veranstaltungen - AStA":
          this.DB = "VeranstaltungenAsta";
          this.request = "get_veranstaltungen_asta";

          break;
        case "Information - AStA":
          this.DB = "InfoAsta";
          this.request = "get_info_asta";

          break;
        case "Protokolle - AStA":
          this.DB = "ProtokolleAsta";
          this.request = "get_protokolle_asta";
          this.type = "protokoll";

          break;
        case "Infos - StuRa":
          this.DB = "newsStura";
          this.request = "get_news_stura";
          break;
        case "StuRa - Protokolle":
          this.DB = "ProtokolleStura";
          this.request = "get_satzungen_stura";
          this.type = "protokoll";

          break;

        case "StuRa - Protokolle - Organisationssatzung":
          this.DB = "ProtokolleStura";
          this.request = "get_satzungen_stura";
          this.type = "organisationssatzung";

          break;

        case "StuRa - Protokolle - Beitragsordnung":
          this.DB = "ProtokolleStura";
          this.request = "get_satzungen_stura";
          this.type = "beitragsordnung";

          break;

        case "StuRa - Protokolle - Haushaltsplan":
          this.DB = "ProtokolleStura";
          this.request = "get_satzungen_stura";
          this.type = "haushaltsplan";

          break;

        case "StuRa - Protokolle - Wahlordnung":
          this.DB = "ProtokolleStura";
          this.request = "get_satzungen_stura";
          this.type = "wahlordnung";

          break;

        case "StuRa - Protokolle - Finanzordnung":
          this.DB = "ProtokolleStura";
          this.request = "get_satzungen_stura";
          this.type = "finanzordnung";

          break;

        case "StuRa - Protokolle - Qualitätssicherungsmittel":
          this.DB = "ProtokolleStura";
          this.request = "get_satzungen_stura";
          this.type = "qualitätssicherungsm";

          break;

        case "Technik - Kalender":
          this.DB = "newsFach";
          this.request = "get_news_fach";

          break;

        case "Technik - Galerie":
          this.DB = "Gallery";
          this.type = "fach";
          this.request = "get_images_home";

          break;
        default:
          break;
      }
    },
    uploadData() {
      var ref = this;
      var changeID = "";
      var request = "upload_data";
      if (this.changeEntry == true) {
        request = "change_entry";
      }
      if (document.getElementById("ID").value != "") {
        changeID = document.getElementById("ID").value;
      }
      if (ref.DB == "AushangHome") {
        ref.type = "genehmigt";
      }
      var axios = require("axios");
      var data = JSON.stringify({
        username: "web163_2",
        pw: "Pf0rzelona",
        request: request,
        menu: ref.Menu,
        name: ref.name,
        text: ref.text,
        image: ref.Image,
        video: ref.YT,
        link_text: ref.linkText,
        link: ref.Link,
        type: ref.type,
        title: ref.title,
        email_creator: ref.mailCreator,
        date: ref.datum,
        short_text: ref.shortText,
        long_text: ref.longText,
        mail: ref.email,
        upload_type: ref.DB,
        change_id: changeID,
        id: ref.id,
      });

      var config = {
        method: "post",
        url: "https://vs-pforzheim.de/UploadHandler.php",
        headers: {
          "Content-Type": "this/json",
        },
        data: data,
      };
      return axios(config)
        .then(function(response) {
          if (response.data == "Message has been sent successfully") {
            location.reload();
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    getId(url) {
      const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
      const match = url.match(regExp);

      return match && match[2].length === 11 ? match[2] : null;
    },
    data_to_array(data) {
      var array = [];
      for (var i = 0; i < data.length; i++) {
        var obj = data[i];
        array[i] = new Array();
        for (var key in obj) {
          array[i].push(obj[key]);
        }
      }
      return array;
    },
    uploadImage: function() {
      this.file = this.$refs.file.files[0];
      const filesize = this.file.size / 1000000;
      var maxFileSize = 1.5;
      const fileType = this.file.type;

      if (
        fileType == "image/jpeg" ||
        fileType == "image/png" ||
        fileType == "application/pdf"
      ) {
        if (fileType == "image/jpeg" || fileType == "image/png") {
          maxFileSize = 0.5;
        }
        if (filesize >= maxFileSize) {
          alert(
            "Datei ist zu groß! Bitte wähle eine Datei mit einer Größe von maximal 1,5 MB für PDF und 0,5 MB für Bilder!"
          );
        } else {
          $("div.spanner").addClass("show");
          $("div.overlay").addClass("show");
          var ref = this;
          var formData = new FormData();

          formData.append("file", this.file);

          var axios = require("axios");

          axios
            .post("https://vs-pforzheim.de/upload.php", formData, {
              header: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then(function(response) {
              if (response.data.image == "") {
                ref.errorAlert = true;
                ref.errorMessage = response.data.message;
                ref.successMessage = "";
                ref.uploadedImage = "";
                $("div.spanner").removeClass("show");
                $("div.overlay").removeClass("show");
              } else {
                ref.errorAlert = false;
                ref.errorMessage = "";
                ref.successMessage = response.data.message;
                ref.Image = response.data.image;
                if (ref.successMessage != "PDF Uploaded") {
                  document.getElementById("uploadedImage").innerHTML = "";
                  var image_html = document.createElement("img");
                  image_html.id = "uploadImage";
                  image_html.style.width = "300px";
                  image_html.src =
                    "https://vs-pforzheim.de/" + response.data.image;
                  document
                    .getElementById("uploadedImage")
                    .appendChild(image_html);
                }
                document.getElementById("thumb").style.display = "block";
                document.getElementById("YT").value = "";

                $("div.spanner").removeClass("show");
                $("div.overlay").removeClass("show");
                ref.$refs.file.value = "";
              }
            });
        }
      } else {
        alert(
          "Falsches Dateiformat! Zulässige Formate sind: JPG, JPEG, PNG oder PDF!"
        );
      }
    },
  },
};
</script>

<style scoped>
#contactFieldAushangHome {
  display: none;
}

#popupEmail {
  display: none;
}

#DB {
  display: none;
}

#DBTable {
  background-color: white;
  text-align: left;
  position: relative;
  display: block;
  margin-left: auto;
  margin-right: auto;
  overflow-y: scroll;
  max-height: 50vh;
  text-align: center;
  max-width: 70vw;
  width: 100%;
}

td {
  text-align: left;
}

h3 {
  color: #343a3f;
}

p {
  font-size: 20px;
  text-align: left;
  padding: 20px;
}

.modal-dialog {
  max-width: 90vw;
}

img {
  max-width: 30vw;
  margin: 20px;
  margin-left: auto;
  margin-right: auto;
}

.spanner {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  display: block;
  text-align: center;
  height: 300px;
  color: #fff;
  transform: translateY(-50%);
  z-index: 1000;
  visibility: hidden;
  text-align: center;
}

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  visibility: hidden;
}

.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}

.loader {
  color: #ffffff;
  font-size: 10px;
  margin: 80px auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

.loader:before,
.loader:after {
  content: "";
  position: absolute;
  top: 0;
}

.loader:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.loader:after {
  left: 3.5em;
}

@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }

  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }

  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

.show {
  visibility: visible;
}

.spanner,
.overlay {
  opacity: 0;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

.spanner.show,
.overlay.show {
  opacity: 1;
  z-index: 2000;
}

.btn {
  background-color: #343a3f;
  color: white;
  width: 300px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 20px;
}

.form-group {
  display: none;
}

h5 {
  margin: auto;
}

hr {
  width: 100%;
  margin: 20px;
}

select {
  text-align-last: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
</style>
