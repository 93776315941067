<template>
  <div class="aushang" id="aushang1">
    <PopUp></PopUp>
      <div id="aushangMark"></div>
    <aushangHome1 class="aushangHome1"></aushangHome1>
    <aushangHome2 class="aushangHome2"></aushangHome2>
    <aushangHome3 class="aushangHome3"></aushangHome3>
  </div>
</template>

<script>
  import $ from "jquery";
  import aushangHome1 from '@/views/home/AushangHome1.vue'
  import PopUp from "../../PopUp.vue"
  import aushangHome2 from '@/views/home/AushangHome2.vue'
  import aushangHome3 from '@/views/home/AushangHome3.vue'
 
  export default {
    name: 'Aushang',
    components: {
      aushangHome1,
      aushangHome2,
      aushangHome3,
      PopUp

    },
    data: {
    },
  }
</script>

<style scoped>
  @import url("https://use.typekit.net/kve4mnq.css");

  .aushang {
    /* background-image: url('../../assets/backgrounds/AushangBG.png');
    background-repeat: no-repeat;
    background-position-x: -50px;
    background-size: 120%; */
    /* background-color: var(--secondary-pastelle); */
    margin-top: 200px;
    
  }
  
  @media only screen and (max-width: 1200px) {
    .aushangHome1 {
      display: none;
    }
  }

  @media only screen and (min-width: 1201px) {
    .aushangHome2 {
      display: none;
    }
  }
  @media only screen and (max-width: 750px) {
    .aushangHome2 {
      display: none;
    }
  }

  @media only screen and (min-width: 751px) {
    .aushangHome3 {
      display: none;
    }
  }
</style>