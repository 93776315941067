<template>
  <div class="gallerie">
    <h1>Galerie</h1>
    <div class="leftContainer" @mouseover="prev()">
      <span class="icon carousel-control-prev-icon" aria-hidden="true"></span>
    </div>
    <div class="rightContainer" @mouseover="next()">
      <span class="icon carousel-control-next-icon" aria-hidden="true"></span>
    </div>
    <swiper
      class="swiper"
      id="mySwiper"
      ref="mySwiper"
      :options="swiperOptions"
      data-aos="flip-up"
      data-aos-delay="200"
      data-aos-duration="1000"
    >
    </swiper>

    <CoolLightBox :items="items" :index="index" @close="index = null">
    </CoolLightBox>
  </div>
</template>
<script>
import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
export default {
  name: "carousel",
  components: {
    CoolLightBox,
  },
  data() {
    return {
      items: [],
      index: 0,
      swiperOptions: {
        slidesPerView: 3,
        spaceBetween: 30,
        grabCursor: true,
        loop: true,
        loopFillGroupWithBlank: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        autoplay: {
          delay: 15500,
          disableOnInteraction: false,
        },
      },
    };
  },
  computed: {},
  mounted: async function() {
    this.$refs.mySwiper.swiper.slideTo(3, 1000, false);
    await this.getImage();
    this.createSlide();
  },
  methods: {
    showLightbox(i) {
      this.index = i;
    },
    next() {
      this.$refs.mySwiper.swiper.slideNext();
    },
    prev() {
      this.$refs.mySwiper.swiper.slidePrev();
    },
    getImage() {
      var ref = this;
      ref.galleryArray = [];
      var axios = require("axios");
      var data = JSON.stringify({
        username: "web163_2",
        pw: "Pf0rzelona",
        request: "get_images_home",
      });

      var config = {
        method: "post",
        url: "https://vs-pforzheim.de/getHandler.php",
        headers: {
          "Content-Type": "this/json",
        },
        data: data,
      };

      return axios(config)
        .then(function(response) {
          response.data.forEach((element, index) => {
            if (element["type"] == "home") {
              ref.items.push("https://vs-pforzheim.de/" + element["image"]);
            }
          });
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    createSlide() {
      let ref = this;
      this.items.forEach((element, index) => {
        this.$refs.mySwiper.swiper.appendSlide([
          '<div class="swiper-slide" id="homeGallerySlide' +
            index +
            '"><img class="d-block w-100" src="' +
            element +
            '" ></div>',
        ]);
        document
          .getElementById("homeGallerySlide" + index)
          .addEventListener("click", function() {
            ref.showLightbox(index);
          });
      });
    },
  },
};
</script>

<style scoped>
.gallerie {
  margin-top: 150px;
  background-image: url("../../assets/backgrounds/GallerieBG.png");
  background-repeat: no-repeat;
  background-position-x: -15.5px;
  background-size: 101%;
  height: 947px;
  position: relative;
}

.swiper {
  padding: 200px 100px 00px 100px;
}
h1 {
  padding-top: 50px;
}

.lightbox {
  background: red;
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.swiper-slide {
  width: 100%;
  overflow: hidden;
}

.leftContainer {
  height: 50%;
  width: 80px;
  background: black;
  opacity: 30%;
  position: absolute;
  left: -20px;
  top: 25%;
  border-radius: 20px;
  z-index: 2;
}

.rightContainer {
  height: 50%;
  width: 80px;
  background: black;
  opacity: 30%;
  position: absolute;
  right: -20px;
  top: 25%;
  border-radius: 20px;
  z-index: 2;
}

.icon {
  margin-top: 250%;
}
</style>
