<template>
	<div class="ueberAsta">
		<table>
			<tr>
				<td data-aos="fade-down" data-aos-duration="1500" data-aos-delay="200">
					<img src="@/assets/bilder/a.svg">
				</td>
				<td data-aos="fade-down" data-aos-duration="1500" data-aos-delay="400">
					<img src="@/assets/bilder/s.svg">
				</td>
				<td data-aos="fade-down" data-aos-duration="1500" data-aos-delay="600">
					<img src="@/assets/bilder/t.svg">
				</td>
				<td data-aos="fade-down" data-aos-duration="1500" data-aos-delay="800">
					<img src="@/assets/bilder/a2.svg">
				</td>
			</tr>
		</table>
		<img id="headingAsta" data-aos="fade-right" data-aos-duration="1500" data-aos-delay="1200" src="@/assets/bilder/hs.svg">
	</div>
</template>

<script>
	export default {
		mounted() {}
	}
</script>

<style scoped>
	@import url("https://use.typekit.net/kve4mnq.css");

	.ueberAsta {
		margin-top: 100px;
	}

	table {
		margin-left: auto;
		margin-right: auto;
		table-layout: fixed;
	}

	

	td{
		padding-left: 10px;
	}


	p {
		font-size: 80px !important;
		margin-left: 10px;
		color: white;
		padding: 20px;
		font-family: mr-eaves-xl-modern, sans-serif;
		font-weight: 800;
		font-style: normal;
	}

	#a {
		background-color: var(--asta-4);
	}

	#s {
		background-color: var(--asta-3);
	}

	#t {
		background-color: var(--asta-2);
	}

	#a2 {
		background-color: var(--asta-1);
	}


	img{
		width: 100px;
	}
	#headingAsta{
		width: 430px;
		margin-top: 1px;
		margin-left: 10px;
	}

	@media only screen and (max-width: 480px) {
		

		img{
		width: 60px;
	}

		#headingAsta{
			width: 270px;
			margin-top: 1px;
			margin-left: 10px;
		}
		
		
	}

	@media only screen and (max-width: 460px) {
		h1{
			margin-left: 20px;
			font-size: 30px!important;
		}
	}

	
</style>