<template>
	<div class="news">
		<div id="news1"></div>
		<newsHome1 class="newsHome1"></newsHome1>
		<newsHome2 class="newsHome2"></newsHome2>
		<newsHome3 class="newsHome3"></newsHome3>
	</div>
</template>

<script>
	import $ from "jquery";
	import newsHome1 from '@/views/home/NewsHome1.vue'
	import newsHome2 from '@/views/home/NewsHome2.vue'
	import newsHome3 from '@/views/home/NewsHome3.vue'

	export default {
		name: 'News',
		components: {
			newsHome1,
			newsHome2,
			newsHome3
		},
	}
</script>

<style scoped>
	@import url("https://use.typekit.net/kve4mnq.css");

	.news {
		/* background-image: url('../../assets/backgrounds/NewsBackground.png'); */
		min-height: 100vh;
		background-position-y: -100px;
		background-repeat: no-repeat;
		margin-top: 100px;
	}

	@media only screen and (max-width: 1200px) {
		.newsHome1 {
			display: none;
		}
	}

	@media only screen and (min-width: 1201px) {
		.newsHome2 {
			display: none;
		}
	}

	@media only screen and (max-width: 900px) {
		.newsHome2 {
			display: none;
		}
	}

	@media only screen and (min-width: 901px) {
		.newsHome3 {
			display: none;
		}
	}
</style>