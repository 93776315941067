<template>
  <div class="fachschaften">
    <!-- <PopUp></PopUp> -->
  <GrillPopUp></GrillPopUp>
  <div  id="mount"></div>
    <technik data-aos="fade-in" data-aos-duration="1500" data-aos-delay="200"></technik>
    <!-- <Projekte></Projekte> -->
    <Gallerie ></Gallerie>
    <div id="gestaltung1"></div>
    <gestaltung  data-aos="fade-in" data-aos-duration="1500" data-aos-delay="200"></gestaltung>
    <div id="wirtschaft1"></div>
    <wirtschaft  data-aos="fade-in" data-aos-duration="1500" data-aos-delay="200"></wirtschaft>
    <Kontakt></Kontakt>
    <div class="sidenav" id="navFachschaften" data-aos="fade-right" data-aos-duration="1500" data-aos-delay="700">
      <router-link id="nav-home-fachschaften" class="router-link" to="/"><span>Home</span></router-link>
      <a id="nav-technik" v-on:click="scrollMeTo('technik1')">Technik</a>
      <a id="nav-gestaltung" v-on:click="scrollMeTo('gestaltung1')">Gestaltung</a>
      <a id="nav-wirtschaft" v-on:click="scrollMeTo('wirtschaft1')">Wirtschaft & Recht</a>
      <a id="nav-kontakt-fach" v-on:click="scrollMeTo('kontakt-fach')">Kontakt</a>
    </div>
  </div>
</template>

<script>
  import Technik from '@/views/fachschaften/Technik'
  import Gallerie from '@/views/fachschaften/GallerieFach'
  import Projekte from '@/views/fachschaften/ProjekteFach'
  	import PopUp from "../PopUp.vue"
  	import GrillPopUp from "../GrillPopUp.vue"

  import Gestaltung from '@/views/fachschaften/Gestaltung'
  import Kontakt from '@/views/fachschaften/KontaktFachschaften'
  import Wirtschaft from '../views/fachschaften/Wirtschaft.vue'
  export default {
    name: 'Fachschaften',
    components: {
      Technik,
      Gestaltung,
      Kontakt,
      Wirtschaft,
      Gallerie,
      Projekte,
			PopUp,
			GrillPopUp

    },
    mounted() {

      const mount = document.querySelector('#mount');
      const wirtschaft = document.querySelector('#wirtschaft1');
      const navWirtschaft = document.getElementById('nav-wirtschaft');

      const gestaltung = document.querySelector('#gestaltung1');
      const navGestaltung = document.getElementById('nav-gestaltung');

      const technik = document.querySelector('#technik1');
      const navTechnik = document.getElementById('nav-technik');

      const kontaktFach = document.querySelector('#kontakt-fach');
      const navKontaktFach = document.getElementById('nav-kontakt-fach');


      const ref = this

      const views = [
        technik,
        gestaltung,
        wirtschaft,
        kontaktFach
      ]

      const navs = [
        navTechnik,
        navGestaltung,
        navWirtschaft,
        navKontaktFach
      ]

      if (ref.isInViewport(mount)) {
        navTechnik.style.color = "var(--primary-orange)"
        navTechnik.style.textDecoration = "underline"
      }


      document.addEventListener('scroll', function () {
        for (var i = 0; i < views.length; i++) {

          if (ref.isInViewport(views[i])) {
            var color = "gray"
            if (navs[i] == "nav-technik") {
              color = "white"
            }
            navs[i].style.color = "var(--primary-orange)"
            navs[i].style.textDecoration = "underline"
            console.log(navs[i])
            let cloneArray = navs.except(navs[i])
            for (var i = 0; i < cloneArray.length; i++) {
              cloneArray[i].style.color = color
              cloneArray[i].style.textDecoration = "none"
              cloneArray[i].style
            }
          }
        }
      });

      Array.prototype.except = function (val) {
        return this.filter(function (x) {
          return x !== val
        })
      }

    },
    methods: {
      scrollMeTo(refName) {

        const el = document.getElementById(refName);
        el.scrollIntoView({
          behavior: "smooth"
        });
      },
      isInViewport(el) {
        const rect = el.getBoundingClientRect()
        return (
          rect.top >= 0 &&
          rect.left >= 0 &&
          rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
          rect.right <= (window.innerWidth || document.documentElement.clientWidth)

        )
      }
    },
    data() {
      return {}
    }
  }
</script>

<style scoped>
  /* The sidebar menu */
  .sidenav {
    height: 100%;
    /* Full-height: remove this if you want "auto" height */
    position: fixed;
    /* Fixed Sidebar (stay in place on scroll) */
    z-index: 1;
    /* Stay on top */
    top: 20px;
    /* Stay at the top */
    left: 0;
    /* Disable horizontal scroll */
    cursor: pointer;
  }
  #navFachschaften a{
    color: gray;
  }
  /* The navigation menu links */
  .sidenav a {
    padding: 6px 8px 6px 16px;
    text-decoration: none;
    font-size: 20px;
    color: gray;
    display: block;
    writing-mode: vertical-rl;
    text-orientation: mixed;
    font-family: montserrat, sans-serif;
    font-weight: 200;
    font-style: normal;
  }

  /* When you mouse over the navigation links, change their color */
  .sidenav a:hover {
    color: var(--primary-orange);
  }

  /* Style page content */
  .main {
    margin-left: 160px;
    /* Same as the width of the sidebar */
    padding: 0px 10px;
  }

  /* On smaller screens, where height is less than 450px, change the style of the sidebar (less padding and a smaller font size) */
  @media screen and (max-height: 450px) {
    .sidenav {
      padding-top: 15px;
    }

    .sidenav a {
      font-size: 18px;
    }
  }
</style>