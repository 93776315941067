<template>
  <div class="Satzungen" style="margin-top: 100px;">
    <h1 id="satzungenHeading">Satzungen</h1>
    <div id="satzungenStura"></div>
    <div class="row">
      <div
        class="col"
        data-aos="fade-right"
        data-aos-duration="1000"
        data-aos-delay="400"
      >
        <a id="organisationssatzung" href="" target="_blank">
          <img src="@/assets/bilder/OrganisationS.png" />
          <h2>
            Organisationssatzung der Studierendenschaft der HS Pforzheim<br />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-box-arrow-up-right"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"
              />
              <path
                fill-rule="evenodd"
                d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z"
              />
            </svg>
          </h2>
        </a>
      </div>
      <div
        class="col"
        data-aos="fade-left"
        data-aos-duration="1000"
        data-aos-delay="800"
      >
        <a id="beitragsordnung" href="" target="_blank">
          <img src="@/assets/bilder/BeitragS.png" />
          <h2>
            Beitragsordnung<br />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-box-arrow-up-right"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"
              />
              <path
                fill-rule="evenodd"
                d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z"
              />
            </svg>
          </h2>
        </a>
      </div>
      <div
        class="col"
        data-aos="fade-left"
        data-aos-duration="1000"
        data-aos-delay="800"
      >
        <a id="haushaltsplan" href="" target="_blank">
          <img src="@/assets/bilder/HaushaltS.png" />
          <h2>
            Haushaltsplan<br />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-box-arrow-up-right"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"
              />
              <path
                fill-rule="evenodd"
                d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z"
              />
            </svg>
          </h2>
        </a>
      </div>
    </div>
    <div class="row" id="secRow">
      <div
        class="col"
        data-aos="fade-right"
        data-aos-duration="1000"
        data-aos-delay=""
      >
        <a id="finanzordnung" href="" target="_blank">
          <img src="@/assets/bilder/FinanzS.png" />
          <h2>
            Finanzordnung<br />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-box-arrow-up-right"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"
              />
              <path
                fill-rule="evenodd"
                d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z"
              />
            </svg>
          </h2>
        </a>
      </div>
      <div
        class="col"
        data-aos="fade-left"
        data-aos-duration="1000"
        data-aos-delay="200"
      >
        <a id="wahlordnung" href="" target="_blank">
          <img src="@/assets/bilder/WahlS.png" />
          <h2>
            Wahlordnung<br />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-box-arrow-up-right"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"
              />
              <path
                fill-rule="evenodd"
                d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z"
              />
            </svg>
          </h2>
        </a>
      </div>
      <div
        class="col"
        data-aos="fade-left"
        data-aos-duration="1000"
        data-aos-delay="200"
      >
        <a id="qualitätssicherungsm" href="" target="_blank">
          <img src="@/assets/bilder/VerteilS.png" />
          <h2>
            Verteilung der<br />Qualitätssicherungsmittel<br />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-box-arrow-up-right"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"
              />
              <path
                fill-rule="evenodd"
                d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z"
              />
            </svg>
          </h2>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "satzungen",
  mounted: async function() {
    await this.getEntries();
    this.setEntries();
  },
  data: {
    entries: [],
  },
  methods: {
    getEntries() {
      var ref = this;
      ref.entries = [];
      var axios = require("axios");
      var data = JSON.stringify({
        username: "web163_2",
        pw: "Pf0rzelona",
        request: "get_satzungen_stura",
      });

      var config = {
        method: "post",
        url: "https://vs-pforzheim.de/getHandler.php",
        headers: {
          "Content-Type": "this/json",
        },
        data: data,
      };

      return axios(config)
        .then(function(response) {
          response.data.forEach((element, index) => {
            var tmpArray = new Map();
            if (element["type"] != "protokoll") {
              tmpArray.set("id", element["id"]);
              tmpArray.set("type", element["type"]);
              tmpArray.set("image", element["image"]);
              ref.entries.push(tmpArray);
            }
          });
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    setEntries() {
      let ref = this;
      ref.entries.forEach((element) => {
        console.log(element);
        document.getElementById(element.get("type")).href =
          "https://vs-pforzheim.de/" + element.get("image");
      });
    },
  },
};
</script>

<style scoped>
#secRow {
  margin-top: 100px;
}

h2 {
  font-size: 20px;
  color: var(--primary-green);
  padding: 5px 80px 0px 80px;
  text-align: center;
  cursor: pointer;
}

@media only screen and (max-width: 640px) {
  h1 {
    padding: 5px 0px 0px 0px;
  }
  #secRow {
    margin-top: 0px;
  }
}

a {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.col-4 {
  text-align: center;
}

.row {
  padding-left: 90px;
  padding-right: 90px;
}

img {
  width: 150px;
  transition: 0.5s;
  cursor: pointer;
}

img:hover {
  width: 200px;
  transition: 0.5s;
}

#satzungenHeading {
  color: var(--primary-orange);
  font-size: 40px;
  /* font-family: azo-sans-uber, sans-serif; */
  font-weight: 750;
  padding-bottom: 100px;
}
</style>
