<template>
  <div
    class="selectFormular"
    id="selectFormular"
    data-aos="flip-right"
    data-aos-duration="1500"
    data-aos-delay="700"
  >
    <div class="row">
      <div class="col-2">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          id="formularBackBtn"
          width="50"
          height="50"
          fill="white"
          class="bi bi-arrow-left-circle"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"
          />
        </svg>
      </div>
      <div class="col-8">
        <h1 id="selecth1"></h1>
        <select class="form-select" id="siteSelect"> </select>
      </div>
      <div class="col-2">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="50"
          height="50"
          fill="none"
          class="bi bi-arrow-left-circle"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"
          />
        </svg>
      </div>
    </div>
    <div class="row" id="btnRowFormular">
      <div class="col-6">
        <a class="btn" id="bearbeitenBtn">Einträge ändern</a>
      </div>
      <div class="col-6">
        <a class="btn" id="erstellenBtn">Einträge erstellen</a>
      </div>
    </div>
    <div id="timeController">
      <form onsubmit="return false">
        <div class="form-group">
          <label for="timeTextarea">Öffnungszeiten</label>
          <textarea class="form-control" id="timeTextarea" rows="3"></textarea>
        </div>
        <button type="submit" class="btn btn-primary" @click="submit()">
          Absenden
        </button>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  data: {},
  mounted() {
    let ref = this;
    ref.site = "";
  },
  methods: {
    submit() {
      let text = document.getElementById("timeTextarea").value;
      text = text.replace(/\n\r?/g, "<br>");

      var ref = this;

      var axios = require("axios");
      var data = JSON.stringify({
        username: "web163_2",
        pw: "Pf0rzelona",
        request: "upload_data",
        Typ: "asta",
        Zeiten: text,
      });

      var config = {
        method: "post",
        url: "https://vs-pforzheim.de/uploadTime.php",
        headers: {
          "Content-Type": "this/json",
        },
        data: data,
      };

      axios(config)
        .then(function(response) {
          if (response.data == "erfolgreich") {
            alert("Zeiten erfolgreich geändert! ✅");
            location.reload();
          }
        })
        .catch(function(error) {
          alert("Hier ist etwas schiefgelaufen! 🫠");
        });
    },
  },
};
</script>

<style scoped>
.selectFormular {
  border-radius: 10px;
  padding: 100px;
  background-color: #343a3f;
  color: white;
  -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  text-align: center;
}

.col-8 {
  text-align: center;
}

select {
  border-radius: 20px;
  margin: 50px;
  padding: 10px;
  text-align-last: center;
  color: white;
  border: 2px solid white;
}

svg {
  cursor: pointer;
}

.btn {
  background-color: white;
  color: #343a3f;
  width: 200px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 20px;
}

#btnRowFormular {
  display: none;
}

#timeController {
  display: none;
  width: 100%;
}
</style>
