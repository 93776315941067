<template>
  <div class="wirtschaft">
	  <Wirtschaft1 class="Wirtschaft1"></Wirtschaft1>
		<Wirtschaft2 class="Wirtschaft2"></Wirtschaft2>

  </div>
</template>

<script>
  import Wirtschaft1 from '@/views/fachschaften/Wirtschaft1.vue'
	import Wirtschaft2 from '@/views/fachschaften/Wirtschaft2.vue'

	import $ from "jquery";

	export default {
		components:{
			Wirtschaft1,
			Wirtschaft2
		}
	}
</script>

<style scoped>
	@media only screen and (max-width: 1100px) {
    .Wirtschaft1 {
      display: none;
    }
  }

  @media only screen and (min-width: 1101px) {
    .Wirtschaft2 {
      display: none;
    }
  }
</style>