<template>
  <div id="playground">
    <h1>Playground</h1>
    <form>
      <div class="form-group">
        <label for="exampleFormControlTextarea1">Example textarea</label>
        <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
      </div>
      <button type="submit" class="btn btn-primary" @click="submit()">Submit</button>
    </form>
    <br>
    <h2>Input</h2>
    <div v-html="input">
     
    </div>
  </div>

</template>

<script>
  export default {
    data(){
      return{
        input: 'test'
      }
    },
    methods:{
      submit(){
        let text = document.getElementById("exampleFormControlTextarea1").value
        text = text.replace(/\n\r?/g, '<br>')
        console.log(text)

        this.input = text
      }
    }
  }
</script>

<style>
  #playground {
    height: 100vh;
    margin: 5vw;    
  }
</style>