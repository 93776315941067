<template>
  <div class="technik">
    <div class="technik-info">
      <h2 data-aos="flip-left" data-aos-duration="1500" data-aos-delay="1000">
        Fachschaften
      </h2>
      <h1 data-aos="flip-right" data-aos-duration="1500" data-aos-delay="700">
        Technik
      </h1>
      <div
        id="carouselTechnik2"
        class="carousel slide"
        data-ride="carousel"
        data-aos="flip-up"
        data-pause="hover"
        data-aos-delay="200"
        data-aos-duration="1000"
      >
        <ol class="carousel-indicators">
          <li
            data-target="#carouselTechnik2"
            data-slide-to="0"
            class="active"
            v-on:click="showIndex(0)"
          ></li>
          <li
            data-target="#carouselTechnik2"
            data-slide-to="1"
            v-on:click="showIndex(1)"
          ></li>
        </ol>
        <div class="carousel-inner">
          <div class="carousel-item active">
            <div class="carousel-text">
              <h1>
                Über uns
              </h1>
              <hr />
              <p>
                Die Fachschaft Technik bildet sich aus allen Studierenden der
                Fakultät für Technik. In unserem Fachschaftsrat sind wir derzeit
                10 Studierende, um deine Anliegen anzuhören und beispielsweise
                im Fakultätsrat, Studierenden Rat (StuRa) oder Senat
                einzubringen. Hiermit können wir Probleme aber auch Wünsche für
                das Studium oder soziale Leben an der HS mit euch gemeinsam
                lösen und umsetzen. Über die Fachschaft sind wir gleichzeitig
                ein Teil der Verfassten Studierendenschaft und machen uns für
                Dich bei der Verteilung der Qualitätssicherungsmittel (QSM) im
                StuRa stark. Mit Hilfe dieser QSM werden unter anderem Tutorien,
                (Online-)Bibliotheken, als auch Projekte wie die neue
                Drohnenarena, die Lernecken für Studierende im T2-Gebäude oder
                3D-Drucker finanziert.
                <a
                  ><router-link class="router-link" :to="{ name: 'Stura' }"
                    >Hier kommst Du zum StuRa</router-link
                  ></a
                ><br /><br />
              </p>
            </div>
          </div>
          <div class="carousel-item ">
            <h1>
              Kalender
            </h1>
            <hr />
            <div id="newsFach2"></div>
            <div class="row" id="entryBtnRow2">
              <div class="col">
                <div class="btn " @click="lastEntries()">Zurück</div>
              </div>
              <div class="col">
                <div class="btn " @click="nextEntries()">Nächste</div>
              </div>
            </div>
          </div>
          <div class="carousel-item ">
            <h1>
              Grillplatz reservieren
            </h1>
            <hr />
            <p>
              Du möchtest einen entspannten Grillabend mit deinen
              Mitstudierenden veranstalten? Dann sende uns eine Mietanfrage für
              unseren Grillplatz!
            </p>
            <div class="row">
              <div
                class="btn"
                @click="grillAnfrage()"
                style="width: 300px; margin-left: auto; margin-right: auto; margin-top: 40px; margin-bottom: 10px;"
              >
                Anfrage
              </div>
            </div>
          </div>
        </div>
        <a
          class="carousel-control-prev"
          href="#carouselTechnik2"
          role="button"
          data-slide="prev"
          v-on:click="prevSlide()"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a
          class="carousel-control-next"
          href="#carouselTechnik2"
          role="button"
          data-slide="next"
          v-on:click="nextSlide()"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import Kalender from "@/views/fachschaften/Kalender";
import $ from "jquery";

export default {
  data: {
    index: 0,
    arraySize: 0,
    maxEntries: 0,
    maxTextLength: 0,
    newsArray: [],
  },
  mounted: async function() {
    $("#carouselTechnik2").carousel({
      interval: 7000,
    });
    $("#carouselTechnik2BG").carousel({
      interval: 4000,
    });

    let ref = this;
    ref.index = 0;
    ref.maxTextLength = 500;
    if ($(window).width() < 820) {
      ref.maxTextLength = 80;
    }
    ref.maxEntries = 2;
    await this.getEntries();
    this.loadEntries();
  },
  methods: {
    grillAnfrage() {
      let popupTitle = "Grillanfrage";

      document.getElementById("popupTitel").style.display = "none";
      document.getElementById("popupDate").innerHTML = "";
      document.getElementById("popupText").innerHTML = "";
      document.getElementById("popupImg").src = "";
      document.getElementById("popupId").innerHTML = "";
      document.getElementById("contactFieldAushangHome").style.display =
        "block";
      document.getElementById("popupBtn").style.display = "none";

      document.getElementById("popupTitle").innerText = popupTitle;
      document.getElementById("imageCol").style.display = "none";
      document.getElementById("popupFooter").style.display = "none";

      $("#grillpopup").modal("show");
    },
    nextEntries() {
      let ref = this;
      document.getElementById("newsFach2").innerHTML = "";
      this.index = this.index + this.maxEntries + 1;
      if (this.index > this.arraySize - 1) {
        this.index = 0;
      }
      this.loadEntries();
    },
    lastEntries() {
      let ref = this;
      document.getElementById("newsFach2").innerHTML = "";
      this.index = this.index - (this.maxEntries + 1);
      if (this.index < 0) {
        this.index = this.arraySize - (this.maxEntries + 1);
      }
      this.loadEntries();
    },
    getEntries() {
      var ref = this;
      ref.newsArray = [];

      var axios = require("axios");
      var data = JSON.stringify({
        username: "web163_2",
        pw: "Pf0rzelona",
        request: "get_news_fach",
      });

      var config = {
        method: "post",
        url: "https://vs-pforzheim.de/getHandler.php",
        headers: {
          "Content-Type": "this/json",
        },
        data: data,
      };

      return axios(config)
        .then(function(response) {
          response.data.forEach((element, index) => {
            var tmpArray = new Map();
            if (element["type"] == "genehmigt") {
              tmpArray.set("dateValue", ref.convertDate(element["date"]));
              if (!ref.isDateBeforeToday(tmpArray.get("dateValue"))) {
                tmpArray.set("id", element["id"]);
                tmpArray.set("type", element["type"]);
                tmpArray.set("title", element["title"]);
                tmpArray.set("text", element["text"]);
                tmpArray.set("date", element["date"]);
                tmpArray.set("shortText", element["shortText"]);
                tmpArray.set("longText", element["longText"]);
                tmpArray.set("link", element["link"]);
                tmpArray.set("linkText", element["linkText"]);
                tmpArray.set("image", element["image"]);
                tmpArray.set("video", element["video"]);
                tmpArray.set("menu", element["menu"]);
                tmpArray.set("time", element["time"]);
                ref.newsArray.push(tmpArray);
              }
            }
          });
          ref.newsArray.sort(function(a, b) {
            var c = new Date(a.get("dateValue"));
            var d = new Date(b.get("dateValue"));
            return c - d;
          });
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    isDateBeforeToday(date) {
      return (
        new Date(date.toDateString()) < new Date(new Date().toDateString())
      );
    },
    convertDate(date) {
      var parts = date.split(".");
      var mydate = new Date(parts[2], parts[1] - 1, parts[0]);
      return mydate;
    },
    loadEntries() {
      var entries = 0;
      var ref = this;
      var array = ref.newsArray;

      ref.arraySize = array.length;
      if (ref.arraySize <= ref.maxEntries + 1) {
        document.getElementById("entryBtnRow2").style.display = "none";
      }
      array.forEach((element, index) => {
        if (index >= ref.index && index <= ref.index + 2) {
          var isLastElement;
          if (ref.maxEntries >= entries) {
            if (entries != ref.maxEntries) {
              isLastElement = false;
            } else {
              isLastElement = true;
            }
            ref.createNewsEntry(array[index], isLastElement);
            entries += 1;
          }
        }
      });
    },
    createNewsEntry(array, isLastElement) {
      let type = array.get("type");

      var div = document.createElement("div");
      div.classList.add("item");
      div.style.textAlign = "left";
      div.id = "item2" + array.get("id");
      document.getElementById("newsFach2").appendChild(div);

      var div2 = document.createElement("div");
      div2.classList.add("inhalt");
      div2.classList.add("row");
      div2.id = "inhalt2" + array.get("id");
      document.getElementById("item2" + array.get("id")).appendChild(div2);

      var div3 = document.createElement("div");
      div3.classList.add("col-12");
      div3.classList.add("text");
      div3.id = "text2" + array.get("id");
      document.getElementById("inhalt2" + array.get("id")).appendChild(div3);

      var h2 = document.createElement("h2");
      h2.id = "heading2" + array.get("id");
      h2.style.color = "var(--primary-orange)";

      var heading = document.createTextNode(array.get("title"));
      h2.appendChild(heading);
      document.getElementById("text2" + array.get("id")).appendChild(h2);

      var date = document.createElement("h6");
      date.id = "date2" + array.get("id");
      date.style.margin = "5px 0px 0px 0px";
      date.classList.add("text-muted");
      var dateText = document.createTextNode(array.get("date"));
      date.appendChild(dateText);
      document.getElementById("text2" + array.get("id")).appendChild(date);

      if (array.get("time") != "") {
        var time = document.createElement("h6");
        time.id = "time2" + array.get("id");
        time.style.margin = "5px 0px 0px 0px";
        var timeText = document.createTextNode(array.get("time"));
        time.appendChild(timeText);
        document.getElementById("text2" + array.get("id")).appendChild(time);
      }

      var needDetails = false;
      var p = document.createElement("p");
      p.id = "p" + array.get("id");
      p.style.fontSize = "20px";
      p.style.textAlign = "justify";
      var text = array.get("text");
      // if (text.length > this.maxTextLength) {
      // 	text = array.get("text").substring(0, this.maxTextLength) + "...";
      // 	needDetails = true;
      // }
      // if (array.get("image") != "") {
      // 	needDetails = true;
      // }
      // var pText = document.createTextNode(text);
      // p.appendChild(pText);
      // document.getElementById('text2' + array.get("id")).appendChild(p);

      // var a = document.createElement('a');
      // a.id = "a1" + array.get("id");
      // a.href = array.get("link");
      // a.target = "_blank";
      // a.style.fontSize = "20px";
      // a.style.color = "#FC6E32";
      // var aText = document.createTextNode(array.get("linkText"));
      // a.appendChild(aText);
      // document.getElementById('text2' + array.get("id")).appendChild(a);

      // var div4 = document.createElement('div');
      // div4.classList.add('col-12');
      // div4.style.marginTop = "20px";
      // div4.classList.add('media');
      // div4.id = "media2" + array.get("id");
      // document.getElementById('inhalt2' + array.get("id")).appendChild(div4);

      // if (array.get("image") != "") {
      // 	var img = document.createElement('img');
      // 	img.style.maxWidth = "22.5vw";
      // 	img.style.maxHeight = "200px";
      // 	img.style.float = "right"

      // 	img.src =
      // 		'https://vs-pforzheim.de/' + array.get("image");
      // 	document.getElementById('media2' + array.get("id")).appendChild(img);
      // }

      // if (array.get("video") != "") {
      // 	var vid = document.createElement('iframe');
      // 	img.style.maxWidth = "22.5vw";
      // 	img.style.maxHeight = "200px";
      // 	vid.frameBorder = "0";
      // 	vid.src = array.get("video");
      // 	document.getElementById('media2' + array.get("id")).appendChild(vid);
      // }
      var ref = this;
      // if (needDetails) {
      // 	var detailsBtn = document.createElement('div');
      // 	detailsBtn.classList = "btn";
      // 	detailsBtn.id = "newsFachDetails2" + array.get("id");

      // 	detailsBtn.style.cssText =
      // 		"background-color: var(--primary-orange); color: white; transition: all 0.5s; z-index: 2; width: 100%; border-radius: 40px; m";
      // 	detailsBtn.textContent = "Details";
      // 	document.getElementById("item2" + array.get("id")).appendChild(detailsBtn);
      // 	document.getElementById("newsFachDetails2" + array.get("id")).addEventListener('click',
      // 		function () {
      // 			ref.showDetails(array.get("id"));
      // 		});
      // }

      var hr = document.createElement("hr");
      hr.style.marginTop = "20px";
      hr.style.textAlign = "center";
      document.getElementById("item2" + array.get("id")).appendChild(hr);
    },
    showDetails(id) {
      var array;
      this.newsArray.forEach((element) => {
        if (element.get("id") == id) {
          array = element;
        }
      });
      let popupTitle = array.get("title");
      let popupDate = array.get("date");
      let popupText = array.get("text");
      let popupImg = array.get("image");

      document.getElementById("popupTitle").innerHTML = "";
      document.getElementById("popupDate").innerHTML = "";
      document.getElementById("popupText").innerHTML = "";
      document.getElementById("popupImg").src = "";
      document.getElementById("popupId").innerHTML = "";
      document.getElementById("popupId").innerHTML = id;
      document.getElementById("contactFieldAushangHome").style.display = "none";
      document.getElementById("popupBtn").style.display = "block";
      document.getElementById("popupBtn").style.display = "none";

      document.getElementById("popupTitle").innerText = popupTitle;
      document.getElementById("popupDate").innerText = popupDate;
      document.getElementById("imageCol").style.display = "none";
      document.getElementById("popupFooter").style.display = "none";

      document.getElementById("popupText").innerText = popupText;
      document.getElementById("popupImg").src =
        "https://vs-pforzheim.de/" + popupImg;

      if (popupImg == "") {
        document.getElementById("popupImg").style.display = "none";
      }

      $("#popup").modal("show");
    },
  },
};
</script>

<style scoped>
.technik {
  background-color: var(--primary-green);
}

.background-carousel {
  position: absolute;
  top: 0;
}

.technik-info {
  background-color: white;
  top: 0;
  margin: 0px 75px 0px 75px;
  padding: 50px 50px 50px 50px;
}

.kalender {
  overflow: visible;
}

h1 {
  font-family: var(--font-georgia);
  font-size: 60px;
}

h2 {
  font-family: var(--font4);
  font-size: 25px;
  color: var(--primary-orange);
}

p {
  font-size: 20px;
}

#carouselTechnik2 .carousel-item {
  height: 700px;
  background-color: #333333;
  color: white;
  text-align: left;
  padding: 20px;
  border-radius: 20px;
  margin-top: 50px;
}

.carousel-inner {
  overflow: hidden;
}

.carousel-control-next {
  margin-right: -80px;
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='orange' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
}

.carousel-control-prev {
  margin-left: -80px;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='orange' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
}

.carousel-item {
}

img {
  max-height: 1000px;
}

@media only screen and (max-width: 820px) {
  .technik-info {
    padding: 50px 75px 100px 75px;
  }

  #carouselTechnik2 .carousel-item {
    height: 100%;
  }

  .carousel-indicators {
    margin-bottom: -2.5px;
  }
}

@media only screen and (max-width: 520px) {
  .technik-info {
    padding: 50px 55px 120px 55px;
    margin: 0px 55px 0px 55px;
  }

  .carousel-control-next {
    margin-right: -50px;
  }

  .carousel-control-prev {
    margin-left: -50px;
  }
}

@media only screen and (max-width: 450px) {
  .technik-info {
    padding: 50px 35px 120px 35px;
    margin: 0px 55px 0px 55px;
  }

  .carousel-control-next {
    margin-right: -40px;
  }

  .carousel-control-prev {
    margin-left: -40px;
  }
}

.btn {
  background-color: var(--primary-orange);
  color: white;
  width: 100%;
  border-radius: 20px;
  cursor: pointer;
}

a {
  color: var(--primary-orange);
}
</style>
