<template>
	<div class="referate">
		<PopUp></PopUp>
		<Referate1 class="Referate1"></Referate1>
		<Referate2 class="Referate2"></Referate2>
		<Referate3 class="Referate3"></Referate3>
	</div>
</template>

<script>
	import Referate1 from '@/views/asta/Referate1.vue'
	import Referate2 from '@/views/asta/Referate2.vue'
	import Referate3 from '@/views/asta/Referate3.vue'
	import PopUp from "../../PopUp.vue"
	
	export default {
		name: 'Referate',
		components: {
		Referate1,
		Referate3,
		Referate2,
			PopUp

		},
		
	}
</script>

<style scoped>
	.referate {
		margin-top: 100px;
		background-color: #E7E0D4;
		padding-bottom: 50px;
		padding-top: 10px;
	}

	@media only screen and (max-width: 1500px) {
    .Referate1 {
      display: none;
    }
  }

  @media only screen and (min-width: 1501px) {
    .Referate2 {
      display: none;
    }
  }


	@media only screen and (max-width: 800px) {
    .Referate2 {
      display: none;
    }
  }

  @media only screen and (min-width: 801px) {
    .Referate3 {
      display: none;
    }
  }

</style>