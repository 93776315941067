<template>
  <div class="DBAushang">
    <navbar></navbar>
    <PopUp></PopUp>
    <div class="selectContainer">
      <table class="table" id="AushangTable">
        <thead
          style="width: 100%"
          data-aos="fade-up"
          data-aos-duration="1500"
          data-aos-delay="1000"
          class="thead-light"
        >
          <tr>
            <th>
              Medien
            </th>
            <th>
              Titel
            </th>
            <th>
              Datum
            </th>
            <th>
              Text
            </th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody
          data-aos="flip-left"
          data-aos-duration="1500"
          id="tableBody"
        ></tbody>
      </table>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import navbar from "@/components/NavBarDashboard";
import PopUp from "../../PopUp.vue";
import { unwatchFile } from "fs";

export default {
  components: {
    navbar,
    PopUp,
  },
  data: {
    receiver: "",
  },
  mounted() {
    $(document).ready(function() {
      $(".active").removeClass("active");
      $("#navDBAushang").addClass("active");
    });
    this.getEntries();
  },
  methods: {
    updateUI(array) {
      var ref = this;
      var tr = document.createElement("tr");
      tr.id = "aushangTR" + array.get("index");
      tr.style.width = "100%";
      tr.style.textAlign = "left";
      document.getElementById("tableBody").appendChild(tr);

      // var tdID = document.createElement('td')
      // tdID.innerText = array.get("id");
      // document.getElementById('aushangTR' + array.get("index")).appendChild(tdID);

      var tdImg = document.createElement("td");
      tdImg.id = "tdImg" + array.get("index");
      document
        .getElementById("aushangTR" + array.get("index"))
        .appendChild(tdImg);

      if (array.get("image") != "") {
        var mediaType = array.get("image").slice(-3);
        if (mediaType == "pdf" || mediaType == "PDF") {
          var pdfLink = document.createElement("a");
          pdfLink.innerText = "Öffne PDF";
          pdfLink.target = "_blank";
          pdfLink.href = "https://vs-pforzheim.de/" + array.get("image");
          document
            .getElementById("tdImg" + array.get("index"))
            .appendChild(pdfLink);
        } else {
          var image_html = document.createElement("img");
          image_html.style.width = "200px";
          image_html.src = "https://vs-pforzheim.de/" + array.get("image");
          document
            .getElementById("tdImg" + array.get("index"))
            .appendChild(image_html);
        }
      }
      var tdTitle = document.createElement("td");
      tdTitle.innerText = array.get("title");
      document
        .getElementById("aushangTR" + array.get("index"))
        .appendChild(tdTitle);

      var tdDate = document.createElement("td");
      tdDate.innerText = array.get("date");
      document
        .getElementById("aushangTR" + array.get("index"))
        .appendChild(tdDate);

      var text = document.createElement("td");
      text.innerText = array.get("text");

      document
        .getElementById("aushangTR" + array.get("index"))
        .appendChild(text);
      var deleteTd = document.createElement("td");
      deleteTd.id = "tdDelete" + array.get("index");
      document
        .getElementById("aushangTR" + array.get("index"))
        .appendChild(deleteTd);
      if (array.get("type") != "genehmigt") {
        var changeBtn = document.createElement("div");
        changeBtn.classList = "btn";
        changeBtn.id = "changeBtn" + array.get("index");
        var ref = this;
        changeBtn.style.cssText =
          "background-color: #343A3F; color: white; transition: all 0.5s; border-radius: 40px; margin: 20px";
        changeBtn.textContent = "Genehmigen";
        document
          .getElementById("tdDelete" + array.get("index"))
          .appendChild(changeBtn);
        document
          .getElementById("changeBtn" + array.get("index"))
          .addEventListener("click", async function() {
            await ref.approveEntry(array.get("index"), array.get("mail"));
          });
      }

      var deleteBtn = document.createElement("div");
      deleteBtn.classList = "btn";
      deleteBtn.id = "deleteBtn" + array.get("index");

      deleteBtn.style.cssText =
        "background-color: red; color: white; transition: all 0.5s; border-radius: 40px; margin: 20px;";
      deleteBtn.textContent = "Löschen";
      document
        .getElementById("tdDelete" + array.get("index"))
        .appendChild(deleteBtn);
      document
        .getElementById("deleteBtn" + array.get("index"))
        .addEventListener("click", async function() {
          await ref.deleteEntry(array.get("index"), array.get("mail"));
          ref.getEntries();
        });
    },

    approveEntry(ID, mail) {
      var ref = this;
      this.request = "aushangApproved";
      this.receiver = mail;
      var axios = require("axios");
      var data = JSON.stringify({
        username: "web163_2",
        pw: "Pf0rzelona",
        request: "approve_entry",
        upload_type: "AushangHome",
        id: ID,
      });

      var config = {
        method: "post",
        url: "https://vs-pforzheim.de/UploadHandler.php",
        headers: {
          "Content-Type": "this/json",
        },
        data: data,
      };
      return axios(config)
        .then(function(response) {
          document.getElementById("contactFieldAushangHome").style.display =
            "none";
          document.getElementById("popupBtn").style.display = "none";
          document.getElementById("popupDate").innerText = "";
          document.getElementById("popupFooter").style.display = "block";
          document.getElementById("popupButton").style.display = "block";
          document.getElementById("popupButton").style.backgroundColor =
            "var(--primary-orange)";
          document.getElementById("popupButton").style.color = "white";
          if (response.data == "erfolgreich") {
            document.getElementById("popupTitle").innerText =
              "Aushang genehmigt!";
            ref.sendMessage();
          } else {
            document.getElementById("popupTitle").innerText =
              "Ups, hier ist etwas schief gelaufen!";
            document.getElementById("popupText").innerText =
              "Auch unsere Technik hat den einen oder anderen Fehler! Bitte wende dich an julian.zoller@mail-zoller.de";
            $("#popup").modal("show");
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },

    deleteEntry(ID, mail) {
      var ref = this;
      this.request = "aushangDenied";
      this.receiver = mail;
      var axios = require("axios");
      var data = JSON.stringify({
        username: "web163_2",
        pw: "Pf0rzelona",
        request: "delete_entry",
        DB: "AushangHome",
        ID: ID,
      });

      var config = {
        method: "post",
        url: "https://vs-pforzheim.de/deleteHandler.php",
        headers: {
          "Content-Type": "this/json",
        },
        data: data,
      };

      return axios(config)
        .then(function(response) {
          document.getElementById("contactFieldAushangHome").style.display =
            "none";
          document.getElementById("popupBtn").style.display = "none";
          document.getElementById("popupDate").innerText = "";
          document.getElementById("popupFooter").style.display = "block";
          document.getElementById("popupButton").style.display = "block";
          document.getElementById("popupButton").style.backgroundColor =
            "var(--primary-orange)";
          document.getElementById("popupButton").style.color = "white";
          if (response.data == "Eintrag geloescht") {
            document.getElementById("popupTitle").innerText =
              "Aushang gelöscht!";

            ref.sendMessage();
          } else {
            document.getElementById("popupTitle").innerText =
              "Ups, hier ist etwas schief gelaufen!";
            document.getElementById("popupText").innerText =
              "Auch unsere Technik hat den einen oder anderen Fehler! Bitte wende dich an julian.zoller@mail-zoller.de!";
            $("#popup").modal("show");
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    sendMessage() {
      var ref = this;
      var axios = require("axios");
      this.mail = "asta@hs-pforzheim.de";

      var data = JSON.stringify({
        request: this.request,
        receiver: this.receiver,
        mail: this.mail,
      });
      var config = {
        method: "post",
        url: "https://vs-pforzheim.de/mailer.php",
        headers: {
          "Content-Type": "this/json",
        },
        data: data,
      };

      return axios(config)
        .then(function(response) {
          if (response.data == "Message has been sent successfully") {
            document.getElementById("popupText").innerText =
              "Der Verfasser wurde per Mail über den Status seiner Anfrage informiert!";
            ref.getEntries();

            $("#popup").modal("show");
          } else {
            document.getElementById("popupTitle").innerText =
              "Ups, hier ist etwas schief gelaufen!";
            document.getElementById("popupText").innerText =
              "Auch unsere Technik hat den einen oder anderen Fehler! Bitte wende dich an julian.zoller@mail-zoller.de!";
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    getEntries() {
      document.getElementById("tableBody").innerHTML = "";
      var ref = this;
      ref.aushangArray = [];
      var axios = require("axios");
      var data = JSON.stringify({
        username: "web163_2",
        pw: "Pf0rzelona",
        request: "get_aushang_home",
      });

      var config = {
        method: "post",
        url: "https://vs-pforzheim.de/getHandler.php",
        headers: {
          "Content-Type": "this/json",
        },
        data: data,
      };

      return axios(config)
        .then(function(response) {
          response.data.forEach((element, index) => {
            var tmpArray = new Map();
            tmpArray.set("index", element["id"]);
            tmpArray.set("title", element["title"]);
            tmpArray.set("date", element["date"]);
            tmpArray.set("text", element["text"]);
            tmpArray.set("image", element["image"]);
            tmpArray.set("mail", element["email"]);
            tmpArray.set("type", element["type"]);
            ref.updateUI(tmpArray);
          });
        })
        .catch(function(error) {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
.DBAushang {
  height: 100vh;
  background-image: url("../../assets/backgrounds/BG-2.jpg");
}

.selectContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  padding: 100px;
  width: 80vw;
  background-color: #343a3f;
  color: white;
  -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  text-align: center;
  height: 80vh;
  overflow: auto;
}

table {
  background-color: white;
  position: relative;
  overflow-y: scroll;
  max-height: 50vh;
  text-align: center;
}

thead {
  text-align: left;
  position: sticky;
}
</style>
