<template>
	<div class="trin">
		<div class="stura" data-aos="fade-right" data-aos-duration="1500">

			<h1 data-aos="zoom-out-left" data-aos-delay="1000" data-aos-duration="1000">
				StuRa
			</h1>
			<h3>
				Der Studierendenrat ist das Legislativorgan der verfassten Studierendenschaft.
				<br><br>
			</h3>
			<router-link class="router-link" to="Stura">
				<div class="btn btn-stura">
					<span>mehr</span>
				</div>
			</router-link>
			<div class="sturaImg img">
			</div>
		</div>
		<div class="asta" data-aos="fade-up" data-aos-duration="1500">
			<h1 data-aos="zoom-out-down" data-aos-delay="1000" data-aos-duration="1000">
				AStA
			</h1>
			<h3>
				Wir sind der allgemeine Studierendenausschuss, eure Studierendenvertretung und setzen uns für eure
				Belange ein.
				<br><br>
			</h3>
			<a class="router-link" href="https://asta-pforzheim.de" >
				<div class="btn btn-asta">
					<span>mehr</span>
				</div>
			</a>
			<div class="astaImg img"></div>
		</div>
		<div class="fachschaften" data-aos="fade-left" data-aos-duration="1500">
			<h1 data-aos="zoom-out-right" data-aos-delay="1000" data-aos-duration="1000">
				Fachschaften
			</h1>
			<h3>
				Studentische Selbstverwaltung<br>Wir stehen euch bei all euren Anliegen zur Seite.

				<br><br>
			</h3>
			<router-link class="router-link" to="Fachschaften">
				<div class="btn btn-fachschaften">
					<span>mehr</span>
				</div>
			</router-link>
			<div class="fachschaftenImg img"></div>
		</div>
		<div class="arrow" data-aos="fade-up" data-aos-duration="3500">
			<span></span>
			<span></span>
			<span></span>
		</div>
	</div>

</template>

<script>
	export default {

	}
</script>

<style scoped>
	@import url("https://use.typekit.net/kve4mnq.css");

	.trin {}

	.img {
		background-position: 0% 0px;
		background-repeat: no-repeat;
		background-size: cover;
		opacity: 0.25;
		z-index: 0;
		height: 100%;
	}

	@media only screen and (min-width: 516px) {
		.img {
			background-position: 0% -50px;
		}
	}

	#menuStura {
		position: absolute;
		top: 50%;
		left: 0%;
		right: 0%;
		text-align: center;
		top: 15em;
		opacity: 0;
		margin: 100px;
	}

	.stura {
		background-color: var(--primary-green);
		flex-grow: 1;
		height: 18em;
		position: relative;
		justify-content: center;
		transition: 0.75s;
	}

	.stura:hover {
		background-color: black;
	}

	.stura:hover h1 {
		color: white;
		transition: 0.75s;
	}

	.stura:hover h2 {
		opacity: 0;
		transition: 0.75s;
		transition-delay: 0s !important;
	}

	.h2::after {
		transition-delay: 0s !important;
	}

	.stura:hover h3 {
		opacity: 1;
		transition: 0.75s;
	}

	.asta:hover h3 {
		opacity: 1;
		transition: 0.75s;
	}

	.fachschaften:hover h3 {
		opacity: 1;
		transition: 0.75s;
	}

	.stura:hover .btn-stura {
		opacity: 1;
		transition: 0.75s;
	}

	.asta:hover .btn-asta {
		opacity: 1;
		transition: 0.75s;
	}

	.fachschaften:hover .btn-fachschaften {
		opacity: 1;
		transition: 0.75s;
	}

	.sturaImg {
		background-image: url('../../assets/bilder/SturaHeader.jpeg');
	}

	.stura h2 {
		/* background-color: white; */
		padding-top: 130px;
		padding-bottom: 30px;
		z-index: 1;
	}

	.stura h3,
	.fachschaften h3,
	.asta h3 {
		font-family: var(--font2);
		font-weight: 400;
		font-size: 20px;
		font-style: italic;
		color: white;
		margin-top: 50px;
		opacity: 0;
	}

	.btn-stura,
	.btn-asta,
	.btn-fachschaften {
		/* z-index: 2; */
		position: absolute;
		left: 0%;
		right: 0%;
		text-align: center;
		bottom: 10px;
		opacity: 0;
	}

	h1 {
		z-index: 2;
		opacity: 1;
		position: absolute;
		top: 0%;
		left: 0%;
		right: 0%;
		text-align: center;
		top: 20px;
		font-weight: 400;
	}


	h2 {
		z-index: 2;
		opacity: 1;
		position: absolute;
		left: 0%;
		right: 0%;
		text-align: center;
	}

	h3 {
		z-index: 2;
		opacity: 1;
		position: absolute;
		left: 10%;
		right: 10%;
		top: 30px;
	}

	.asta {
		background-color: #CC141C;
		flex-grow: 1;
		height: 18em;
		position: relative;
		transition: 0.75s;
	}

	.asta:hover {
		background-color: black;
	}

	.astaImg {
		background-image: url('../../assets/bilder/AstaHeader.jpeg');
	}

	.asta h1 {
		color: white;
	}

	.stura h1 {
		color: white;
	}

	.fachschaften {
		background-color: var(--secondary-pastelle);
		flex-grow: 1;
		height: 15em;
		position: relative;
		transition: 0.75s;
	}

	.fachschaften:hover {
		background-color: black;
	}

	.fachschaftenImg {
		background-image: url('../../assets/bilder/Engineering.png');
	}

	.asta:hover {
		background-color: black;
	}

	.fachschaften h1 {
		color: white;
	}

	.btn-stura,
	.btn-asta,
	.btn-fachschaften {
		background-color: white;
		color: black;
		transition: all 0.5s;
		z-index: 3;
		margin-left: auto;
		margin-right: auto;
		cursor: pointer !important;
		border-radius: 40px;
		width: 100px;
	}

	.router-link {
		color: black;
	}

	.arrow {
		margin-left: 50%;
		margin-right: auto;
	}

	.arrow span {
		display: block;
		width: 30px;
		height: 30px;
		border-bottom: 5px solid #2c3e50;
		border-right: 5px solid #2c3e50;
		transform: rotate(45deg);
		margin: -10px;
		animation: animate 2s infinite;
	}

	.arrow span:nth-child(2) {
		animation-delay: -0.2s;
	}

	.arrow span:nth-child(3) {
		animation-delay: -0.4s;
	}

	@keyframes animate {
		0% {
			opacity: 0;
			transform: rotate(45deg) translate(-20px, -20px);
		}

		50% {
			opacity: 1;
		}

		100% {
			opacity: 0;
			transform: rotate(45deg) translate(20px, 20px);
		}
	}
</style>