<template>
	<div class="infoAsta" >
		<div id="info1"></div>
		<infoAsta1 class="infoAsta1"></infoAsta1>
		<infoAsta2 class="infoAsta2"></infoAsta2>
		<infoAsta3 class="infoAsta3"></infoAsta3>
	</div>
</template>

<script>
	import $ from "jquery";
		import infoAsta1 from '@/views/asta/InfoAsta1.vue'
	import infoAsta2 from '@/views/asta/InfoAsta2.vue'
	import infoAsta3 from '@/views/asta/InfoAsta3.vue'

	export default {
		name: 'InfoAsta',
		components: {
			infoAsta1,
			infoAsta2,
			infoAsta3
		},
		
	}
</script>

<style scoped>
	.infoAsta {
		/* background-image: url('../../assets/backgrounds/InfoBG.png');
		background-repeat: no-repeat;
		background-position-y: 0px; */
		margin-top: 100px;
	}


		@media only screen and (max-width: 1200px) {
		.infoAsta1 {
			display: none;
		}
	}

	@media only screen and (min-width: 1201px) {
		.infoAsta2 {
			display: none;
		}
	}

	@media only screen and (max-width: 900px) {
		.infoAsta2 {
			display: none;
		}
	}

	@media only screen and (min-width: 901px) {
		.infoAsta3 {
			display: none;
		}
	}

</style>