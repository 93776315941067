<template>
  <div class="asta">
    <ueberAsta id="asta1" data-aos="fade-in" data-aos-duration="1500" data-aos-delay="200"></ueberAsta>
    <Referate id="referate1"></Referate>
    <Info id="info1"></Info>
    <Veranstaltungen id="veranstaltungen1"></Veranstaltungen>
    <Service id="service1"></Service>
    <Protokolle id="protokolleAsta1"></Protokolle>
    <div  id="kontakt1"></div>
    <Kontakt></Kontakt>
    <div class="sidenav" data-aos="fade-right" data-aos-duration="1500" data-aos-delay="700">
      <router-link id="nav-home-asta" class="router-link" to="/"><span>Home</span></router-link>
      <a id="nav-asta" v-on:click="scrollMeTo('asta1')">AStA</a>
      <a id="nav-referate" v-on:click="scrollMeTo('referate1')">Referate</a>
      <a id="nav-veranstaltungen" v-on:click="scrollMeTo('veranstaltungen1')">Veranstaltungen</a>
      <a id="nav-service" v-on:click="scrollMeTo('service1')">Service</a>
      <a id="nav-informationen" v-on:click="scrollMeTo('info1')">Informationen</a>
      <a id="nav-kontakt" v-on:click="scrollMeTo('kontaktAsta')">Kontakt</a>
    </div>
  </div>
</template>

<script>
  import ueberAsta from '@/views/asta/ueberAsta'
  import Veranstaltungen from '@/views/asta/Veranstaltungen'
  import Service from '@/views/asta/Service'
  import Info from '@/views/asta/InfoAsta'
  import Protokolle from '@/views/asta/ProtokolleAsta'
  import Kontakt from '@/views/asta/KontaktAsta'
  import Referate from '@/views/asta/Referate'
  export default {
    name: 'Asta',
    components: {
      ueberAsta,
      Referate,
      Veranstaltungen,
      Service,
      Info,
      Kontakt,
      Protokolle,
      Referate
    },
    mounted() {
      const mount = document.querySelector('#asta1');
      const asta = document.querySelector('#asta1');
      const navAsta = document.getElementById('nav-asta');
      const Referate = document.querySelector('#referate1');
      const navReferate = document.getElementById('nav-referate');
      const Veranstaltungen = document.querySelector('#veranstaltungen1');
      const navVeranstaltungen = document.getElementById('nav-veranstaltungen');
      const Service = document.querySelector('#service1');
      const navService = document.getElementById('nav-service');
      const Info = document.querySelector('#info1');
      const navInfo = document.getElementById('nav-informationen');
      const Kontakt = document.querySelector('#kontakt1');
      const navKontakt = document.getElementById('nav-kontakt');
      const ref = this

      const views = [asta, Referate, Veranstaltungen, Service, Info, Kontakt]
      const navs = [navAsta, navReferate, navVeranstaltungen, navService, navInfo, navKontakt]

      if (ref.isInViewport(mount)) {
        navAsta.style.color = "var(--asta-1)"
        navAsta.style.textDecoration = "underline"
      }

      document.addEventListener('scroll', function () {
        for (var i = 0; i < views.length; i++) {

          if (ref.isInViewport(views[i])) {

            navs[i].style.color = "var(--asta-1)"
            navs[i].style.textDecoration = "underline"

            let cloneArray = navs.except(navs[i])
            for (var i = 0; i < cloneArray.length; i++) {
              cloneArray[i].style.color = "gray"
              cloneArray[i].style.textDecoration = "none"
              cloneArray[i].style
            }
          }
        }
      });

      Array.prototype.except = function (val) {
        return this.filter(function (x) {
          return x !== val
        })
      }

    },
    methods: {
      scrollMeTo(refName) {

      const el = document.getElementById(refName);
      el.scrollIntoView({behavior: "smooth"});
    },
      isInViewport(el) {
        const rect = el.getBoundingClientRect()
        return (
          rect.top >= 0 &&
          rect.left >= 0 &&
          rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
          rect.right <= (window.innerWidth || document.documentElement.clientWidth)

        )
      }
    },
    data() {
      return {}
    }
  }
</script>

<style scoped>
  /* The sidebar menu */
  .sidenav {
    height: 100%;
    /* Full-height: remove this if you want "auto" height */
    position: fixed;
    /* Fixed Sidebar (stay in place on scroll) */
    z-index: 1;
    /* Stay on top */
    top: 20px;
    /* Stay at the top */
    left: 0;
    /* Disable horizontal scroll */
    cursor: pointer;
  }

  /* The navigation menu links */
  .sidenav a {
    padding: 6px 8px 6px 16px;
    text-decoration: none;
    font-size: 17.5px;
    color: gray;
    display: block;
    writing-mode: vertical-rl;
    text-orientation: mixed;
    font-family: montserrat, sans-serif;
    font-weight: 200;
    font-style: normal;
    cursor: pointer;
  }

  /* When you mouse over the navigation links, change their color */
  .sidenav a:hover {
    color: var(--asta-3);
  }

  

  /* Style page content */
  .main {
    margin-left: 160px;
    /* Same as the width of the sidebar */
    padding: 0px 10px;
  }

  /* On smaller screens, where height is less than 450px, change the style of the sidebar (less padding and a smaller font size) */
  @media screen and (max-height: 450px) {
    .sidenav {
      padding-top: 15px;
    }

    .sidenav a {
      font-size: 18px;
    }
  }
</style>