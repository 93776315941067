<template>
  <div class="Gestaltung">
	  
	  <Gestaltung1 class="Gestaltung1"></Gestaltung1>
	  <Gestaltung2 class="Gestaltung2"></Gestaltung2>
	  <Gestaltung3 class="Gestaltung3"></Gestaltung3>
	
  </div>
</template>

<script>
	import Gestaltung1 from '@/views/fachschaften/Gestaltung1.vue'
	import Gestaltung2 from '@/views/fachschaften/Gestaltung2.vue'
	import Gestaltung3 from '@/views/fachschaften/Gestaltung3.vue'
export default {
	 name: 'wirtschaft',
	 components:{
		 Gestaltung1,
		 Gestaltung3,
		 Gestaltung2
	 },
    mounted () {},
    data () {
      return {}
    }
  }
</script>

<style scoped>
		@media only screen and (max-width: 1650px) {
    .Gestaltung1 {
      display: none;
    }
  }

  @media only screen and (min-width: 1651px) {
    .Gestaltung2 {
      display: none;
    }
  }

  @media only screen and (max-width: 1100px) {
    .Gestaltung2 {
      display: none;
    }
  }

  @media only screen and (min-width: 1101px) {
    .Gestaltung3 {
      display: none;
    }
  }

</style>