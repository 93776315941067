<template>
	<div id="aufgabenVSContainer">
		<div class="textContainer" data-aos="fade-right" data-aos-duration="300">
			<p class="bolder">
				<strong>
					Welche Aufgaben hat die Studierendenschaft?
				</strong>
			</p>
			<p>
				Nach dem Gesetz zur Einführung einer Verfassten Studierendenschaft obliegen ihr folgende Aufgaben:
			</p>
			<ul>
				<li>
					Wahrnehmung der hochschulpolitischen, fachlichen und fachübergreifenden sowie der sozialen,
					wirtschaftlichen
					und kulturellen Belange der Studierenden.
				</li>
				<li>
					Mitwirkung an den Aufgaben der Hochschule nach dem LHG
				</li>
				<li>
					Förderung der politischen Bildung und des staatsbürgerlichen Verantwortungsbewusstseins der
					Studierenden
				</li>
				<li>
					Förderung der Gleichstellung und den Abbau von Benachteiligungen innerhalb der Studierendenschaft
				</li>
				<li>
					Förderung der sportlichen Aktivitäten der Studierenden
				</li>
				<li>
					Pflege der überregionalen und internationalen Studierendenbeziehungen.
				</li>
			</ul>
		</div>
		<img src="@/assets/bilder/Organisationsschema.jpeg" data-aos="flip-right" data-aos-duration="800"/>
	</div>
</template>

<script>
	export default {

	}
</script>

<style scoped>
	#aufgabenVSContainer {
		background-image: url('../../assets/backgrounds/AufgabenVSBG.png');
		background-size: 60vw 90vw;
		background-position-x: right;
		padding-bottom: 225px;
	}

	.textContainer {
		padding-top: 200px;
		margin-left: 200px;
		text-align: left;
		font-size: 20px;
		color: black;
		width: 30vw;
	}

	ul {
		margin-left: 25px;
	}

	img {
		margin-top: 200px;
		width: 60vw;
		-webkit-box-shadow: 5px 5px 39px 15px rgba(0, 0, 0, 0.33);
		box-shadow: 5px 5px 39px 15px rgba(0, 0, 0, 0.33);
	}

	@media only screen and (max-width: 1300px) {
		.textContainer {
			margin-left: 100px;
			width: 75vw;
		}

		#aufgabenVSContainer {
			background-size: cover;
		}

		img {
			width: 80vw;
		}
	}


	@media only screen and (max-width: 580px) {
		img {
			margin-top: 50px;
		}

		
	}
	@media only screen and (max-width: 500px) {
		.textContainer {
			margin-left: 50px;
		}
	}

</style>