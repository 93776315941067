<template>
  <div class="grid">
    <div class="overlay" id="overlay"></div>
    <div class="spanner" id="spanner">
      <div class="loader"></div>
    </div>
    <div
      class="modal fade"
      id="popup"
      tabindex="1"
      style=""
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <table>
              <tr>
                <td>
                  <h3 class="modal-title" id="popupTitle"></h3>
                </td>
              </tr>
              <tr>
                <td>
                  <h5 class="text-muted" id="popupDate"></h5>
                </td>
              </tr>
            </table>

            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div id="contactFieldAushangHome">
              <form onsubmit="return false">
                <div class="form-row">
                  <div class="form-group col" id="popupName">
                    <label for="name">Name</label>
                    <input
                      type="name"
                      class="form-control"
                      id="name"
                      aria-describedby="nameHelp"
                      placeholder="Gib deinen Namen ein"
                    />
                    <small id="emailHelp" class="form-text text-muted"></small>
                  </div>
                  <div class="form-group col" id="popupTitel">
                    <label for="titel">Titel</label>
                    <input
                      type="titel"
                      class="form-control"
                      id="titel"
                      aria-describedby="nameHelp"
                      placeholder="Gib den Titel ein"
                    />
                    <small id="emailHelp" class="form-text text-muted"></small>
                  </div>
                  <div class="form-group col" id="popupCreatorEmail">
                    <label for="email">E-Mail</label>
                    <input
                      type="email"
                      class="form-control"
                      id="email"
                      aria-describedby="emailHelp"
                      placeholder="Gib deine E-Mail ein"
                    />
                    <small id="emailHelp" class="form-text text-muted"></small>
                  </div>
                </div>
                <div class="form-group">
                  <label for="Message">Deine Nachricht</label>
                  <textarea
                    type="text-area"
                    class="form-control"
                    id="Message"
                    aria-describedby="MessageHelp"
                    placeholder="Gib deine Nachricht ein"
                  ></textarea>
                  <small id="MessageHelp" class="form-text text-muted"></small>
                </div>

                <div class="form-group col" id="imageCol">
                  <div class="container" id="uploadApp">
                    <div class="panel panel-default">
                      <div class="panel-heading"></div>
                      <div class="panel-body">
                        <div class="row">
                          <p style="margin: auto">
                            Falls vorhanden, Wähle ein Bild
                          </p>
                        </div>
                        <div class="row">
                          <input
                            style="margin: auto; margin-bottom: 30px"
                            type="file"
                            ref="file"
                          />
                        </div>
                        <div class="row">
                          <button
                            type="button"
                            @click="uploadImage"
                            class="btn"
                          >
                            Upload
                          </button>
                        </div>
                        <div class="row">
                          <svg
                            id="thumb"
                            xmlns="http://www.w3.org/2000/svg"
                            width="50"
                            height="50"
                            fill="green"
                            class="bi bi-hand-thumbs-up"
                            viewBox="0 0 16 16"
                          >
                            <path
                              d="M8.864.046C7.908-.193 7.02.53 6.956 1.466c-.072 1.051-.23 2.016-.428 2.59-.125.36-.479 1.013-1.04 1.639-.557.623-1.282 1.178-2.131 1.41C2.685 7.288 2 7.87 2 8.72v4.001c0 .845.682 1.464 1.448 1.545 1.07.114 1.564.415 2.068.723l.048.03c.272.165.578.348.97.484.397.136.861.217 1.466.217h3.5c.937 0 1.599-.477 1.934-1.064a1.86 1.86 0 0 0 .254-.912c0-.152-.023-.312-.077-.464.201-.263.38-.578.488-.901.11-.33.172-.762.004-1.149.069-.13.12-.269.159-.403.077-.27.113-.568.113-.857 0-.288-.036-.585-.113-.856a2.144 2.144 0 0 0-.138-.362 1.9 1.9 0 0 0 .234-1.734c-.206-.592-.682-1.1-1.2-1.272-.847-.282-1.803-.276-2.516-.211a9.84 9.84 0 0 0-.443.05 9.365 9.365 0 0 0-.062-4.509A1.38 1.38 0 0 0 9.125.111L8.864.046zM11.5 14.721H8c-.51 0-.863-.069-1.14-.164-.281-.097-.506-.228-.776-.393l-.04-.024c-.555-.339-1.198-.731-2.49-.868-.333-.036-.554-.29-.554-.55V8.72c0-.254.226-.543.62-.65 1.095-.3 1.977-.996 2.614-1.708.635-.71 1.064-1.475 1.238-1.978.243-.7.407-1.768.482-2.85.025-.362.36-.594.667-.518l.262.066c.16.04.258.143.288.255a8.34 8.34 0 0 1-.145 4.725.5.5 0 0 0 .595.644l.003-.001.014-.003.058-.014a8.908 8.908 0 0 1 1.036-.157c.663-.06 1.457-.054 2.11.164.175.058.45.3.57.65.107.308.087.67-.266 1.022l-.353.353.353.354c.043.043.105.141.154.315.048.167.075.37.075.581 0 .212-.027.414-.075.582-.05.174-.111.272-.154.315l-.353.353.353.354c.047.047.109.177.005.488a2.224 2.224 0 0 1-.505.805l-.353.353.353.354c.006.005.041.05.041.17a.866.866 0 0 1-.121.416c-.165.288-.503.56-1.066.56z"
                            />
                          </svg>
                        </div>
                        <br />
                        <br />
                        <div id="uploadedImage" align="center"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="text-center">
                  <p class="text-muted">
                    Mit dem Absenden bestätige ich die Weitergabe meiner
                    Email-Adresse für den Kontakt.
                  </p>
                  <button
                    v-on:click="submit"
                    type="submit"
                    class="btn "
                    id="submitBtnPopup"
                  >
                    Absenden
                  </button>
                </div>
              </form>
            </div>
            <div class="row">
              <p id="popupText"></p>
              <p id="popupEmail"></p>
              <img id="popupImg" src="" />
            </div>
            <p id="popupId" style="display: none"></p>
            <p id="popupType" style="display: none"></p>
            <a @click="startContact()" type="button" class="btn" id="popupBtn"
              >Jetzt kontaktieren!</a
            >
          </div>
          <div class="modal-footer" id="popupFooter">
            <a type="button" id="popupButton" class="btn" data-dismiss="modal"
              >Okay</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import Datepicker from "vuejs-datepicker";
import { de } from "vuejs-datepicker/dist/locale";
export default {
  components: {
    Datepicker,
  },
  data() {
    return {
      de: de,
      file: "",
      errorAlert: false,
      uploadedImage: "",
      Image: "",
      DB: "",
      mail: "",
      receiver: "",
      message: "",
      subject: "",
      name: "",
      request: "",
    };
  },
  mounted() {
    this.file = "";
    this.errorAlert = false;
    this.uploadedImage = "";
    this.Image = "";
    this.DB = "";
    this.mail = "";
    this.receiver = "";
    this.message = "";
    this.subject = "";
    this.request = "";
    this.name = "";
  },
  methods: {
    submit: function() {
      let popupType = document.getElementById("popupType").innerText;
      if (popupType == "contact") {
        this.sendEmail();
      }
      if (popupType == "upload") {
        this.startUpload();
      }
    },
    async startUpload() {
      await this.uploadData();
    },
    uploadData() {
      let DB = "AushangHome";
      let text = document.getElementById("Message").value;
      let email = document.getElementById("email").value;
      let date = document.getElementById("popupDate").innerText;
      let title = document.getElementById("titel").value;

      this.receiver = email;
      this.subject = title;
      // this.mail = "julian.zoller@mail-zoller.de"
      this.mail = "asta@hs-pforzheim.de";
      this.message = "";
      var ref = this;
      var request = "upload_data";
      var axios = require("axios");
      var data = JSON.stringify({
        username: "web163_2",
        pw: "Pf0rzelona",
        request: request,
        menu: "",
        name: "",
        text: text,
        image: this.Image,
        video: "",
        link_text: "",
        link: "",
        type: "waiting",
        title: title,
        email_creator: "",
        date: date,
        short_text: "",
        long_text: "",
        mail: email,
        upload_type: DB,
        id: "",
      });

      var config = {
        method: "post",
        url: "https://vs-pforzheim.de/UploadHandler.php",
        headers: {
          "Content-Type": "this/json",
        },
        data: data,
      };
      return axios(config)
        .then(function(response) {
          document.getElementById("contactFieldAushangHome").style.display =
            "none";
          document.getElementById("popupDate").innerText = "";
          document.getElementById("popupFooter").style.display = "block";
          document.getElementById("popupButton").style.display = "block";
          document.getElementById("popupButton").style.backgroundColor =
            "var(--primary-orange)";
          document.getElementById("popupButton").style.color = "white";
          if (response.data == "Message has been sent successfully") {
            document.getElementById("popupTitle").innerText =
              "Anfrage versendet!";
            document.getElementById("popupText").innerText =
              "Wir bearbeiten deine Anfrage und antworten dir so schnell wie möglich! Unter Umständen können E-Mails auch in deinem Spam landen!";
            ref.request = "createAushang";
            ref.sendMessage();
          } else {
            document.getElementById("popupTitle").innerText =
              "Ups, hier ist etwas schief gelaufen!";
            document.getElementById("popupText").innerText =
              "Auch unsere Technik hat den einen oder anderen Fehler! Bitte sende uns eine Mail an: test.XXX@xxx.de!";
          }
        })
        .catch(function(error) {});
    },
    async sendEmail() {
      await this.getEmailData();
      this.sendMessage();
    },
    getEmailData() {
      var ref = this;
      this.mail = document.getElementById("email").value;
      this.message = document.getElementById("Message").value;
      this.name = document.getElementById("name").value;
      this.subject = document.getElementById("popupTitle").innerHTML;
      if (document.getElementById("popupEmail").innerText != "") {
        this.request = "kontaktformular";
        document.getElementById("popupButton").style.backgroundColor =
          "var(--asta-1)";

        this.receiver = document.getElementById("popupEmail").innerText;
      } else {
        this.request = "aushang";
        document.getElementById("popupButton").style.backgroundColor =
          "var(--primary-orange)";

        let id = document.getElementById("popupId").innerHTML;
        var axios = require("axios");
        var data = JSON.stringify({
          username: "web163_2",
          pw: "Pf0rzelona",
          request: "get_aushang_home",
        });

        var config = {
          method: "post",
          url: "https://vs-pforzheim.de/getHandler.php",
          headers: {
            "Content-Type": "this/json",
          },
          data: data,
        };

        return axios(config)
          .then(function(response) {
            response.data.forEach((element, index) => {
              if (element["id"] == id) {
                ref.receiver = element["email"];
              }
            });
          })
          .catch(function(error) {});
      }
    },
    async sendMessage() {
      var axios = require("axios");

      var data = JSON.stringify({
        request: this.request,
        name: this.name,
        mail: this.mail,
        receiver: this.receiver,
        message: this.message,
        subject: this.subject,
      });
      var config = {
        method: "post",
        url: "https://vs-pforzheim.de/mailer.php",
        headers: {
          "Content-Type": "this/json",
        },
        data: data,
      };
      return axios(config)
        .then(function(response) {
          document.getElementById("contactFieldAushangHome").style.display =
            "none";
          document.getElementById("popupDate").innerText = "";
          document.getElementById("popupFooter").style.display = "block";
          document.getElementById("popupButton").style.display = "block";
          document.getElementById("popupButton").style.color = "white";
          if (response.data == "Message has been sent successfully") {
            document.getElementById("popupTitle").innerText =
              "Nachricht versendet!";

            document.getElementById("popupText").innerText =
              "Wir bearbeiten deine Anfrage und antworten dir so schnell wie möglich! Unter Umständen können E-Mails auch in deinem Spam landen!";
          } else {
            document.getElementById("popupTitle").innerText =
              "Ups, hier ist etwas schief gelaufen!";
            document.getElementById("popupText").innerText =
              "Auch unsere Technik hat den einen oder anderen Fehler! Bitte sende uns eine Mail an: test.XXX@xxx.de!";
          }
        })
        .catch(function(error) {});
    },
    startContact() {
      document.getElementById("popupText").innerHTML = "";
      document.getElementById("popupImg").src = "";
      document.getElementById("popupBtn").style.display = "none";
      document.getElementById("popupTitel").style.display = "none";
      document.getElementById("popupName").style.display = "block";

      document.getElementById("popupType").innerHTML = "";
      document.getElementById("popupType").innerText = "contact";

      document.getElementById("contactFieldAushangHome").style.display =
        "block";
    },
    uploadImage: function() {
      this.file = this.$refs.file.files[0];
      const filesize = this.file.size / 1000000;
      var maxFileSize = 1.5;
      const fileType = this.file.type;

      if (
        fileType == "image/jpeg" ||
        fileType == "image/png" ||
        fileType == "application/pdf"
      ) {
        if (fileType == "image/jpeg" || fileType == "image/png") {
          maxFileSize = 0.5;
        }
        if (filesize >= maxFileSize) {
          alert(
            "Datei ist zu groß! Bitte wähle eine Datei mit einer Größe von maximal 1,5 MB für PDF und 0,5 MB für Bilder!"
          );
        } else {
          $("div.spanner").addClass("show");
          $("div.overlay").addClass("show");
          var ref = this;
          var formData = new FormData();

          formData.append("file", this.file);

          var axios = require("axios");

          axios
            .post("https://vs-pforzheim.de/upload.php", formData, {
              header: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then(function(response) {
              if (response.data.image == "") {
                ref.errorAlert = true;
                ref.errorMessage = response.data.message;
                ref.successMessage = "";
                ref.uploadedImage = "";
                $("div.spanner").removeClass("show");
                $("div.overlay").removeClass("show");
              } else {
                ref.errorAlert = false;
                ref.errorMessage = "";
                ref.successMessage = response.data.message;
                ref.Image = response.data.image;
                if (ref.successMessage != "PDF Uploaded") {
                  document.getElementById("uploadedImage").innerHTML = "";
                  var image_html = document.createElement("img");
                  image_html.id = "uploadImage";
                  image_html.style.width = "300px";
                  image_html.src =
                    "https://vs-pforzheim.de/" + response.data.image;
                  document
                    .getElementById("uploadedImage")
                    .appendChild(image_html);
                }
                document.getElementById("thumb").style.display = "block";
                document.getElementById("YT").value = "";

                $("div.spanner").removeClass("show");
                $("div.overlay").removeClass("show");
                ref.$refs.file.value = "";
              }
            });
        }
      } else {
        alert(
          "Falsches Dateiformat! Zulässige Formate sind: JPG, JPEG, PNG oder PDF!"
        );
      }
    },
  },
};
</script>

<style scoped>
#contactFieldAushangHome {
  display: none;
}

#popupEmail {
  display: none;
}

#thumb {
  display: none;
}

td {
  text-align: left;
}

h3 {
  color: var(--primary-orange);
}

p {
  font-size: 20px;
  padding: 20px;
}

.modal-dialog {
  max-width: 90vw;
}

img {
  max-width: 30vw;
  margin: 20px;
  margin-left: auto;
  margin-right: auto;
}

.btn {
  background-color: var(--primary-orange);
  color: white;
  width: 300px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 20px;
}

.text-muted {
  margin-left: auto !important;
  margin-right: auto !important;
  font-size: 15px;
}

.text-center {
  text-align: center;
  margin-left: auto !important;
  margin-right: auto !important;
}

#popupText {
  text-align: justify;
}

.spanner {
  position: fixed;
  top: 50%;
  left: 0;
  width: 100%;
  display: block;
  text-align: center;
  height: 300px;
  color: #fff;
  transform: translateY(-50%);
  z-index: 1000;
  visibility: hidden;
  text-align: center;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  visibility: hidden;
}

.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}

.loader {
  color: #ffffff;
  font-size: 10px;
  margin: 80px auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

.loader:before,
.loader:after {
  content: "";
  position: absolute;
  top: 0;
}

.loader:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.loader:after {
  left: 3.5em;
}

@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }

  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }

  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

.show {
  visibility: visible;
}

.spanner,
.overlay {
  opacity: 0;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

.spanner.show,
.overlay.show {
  opacity: 1;
  z-index: 2000;
}

input,
label {
  display: block;
  margin: auto;
}

input {
  min-width: 200px;
}
</style>
